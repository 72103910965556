import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NavItemWithoutIcon, UIAlert, UIAlertDisplayValues} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() message!: string;
  @Input() param!: string;
  @Input() alertType!: UIAlert;
  @Input() alertButton!: NavItemWithoutIcon;

  get alertDisplayValue() {
    return UIAlertDisplayValues[this.alertType];
  }

  get buttonClass() {
    return this.alertType === UIAlert.WARNING ? 'warning' : 'tertiary';
  }

}
