import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AddressApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadAddresses, loadAddressesError, loadAddressesSuccess} from "@app-web-central/web/address/data-access";
import {catchError, map, switchMap} from "rxjs";

@Injectable({ providedIn: 'root' })
export class AddressesEffects {
  loadAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAddresses),
      switchMap(() => this._addressApi.list()
        .pipe(
          map((response) => loadAddressesSuccess({ addresses: response.payload })),
          catchError((error) => [loadAddressesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _addressApi: AddressApi
  ) { }
}
