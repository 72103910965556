import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {NavItemMixin} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {NavBarSecondaryStore} from "./nav-bar-secondary.store";

interface MenuState {
  items: NavItemMixin[]
}

@Injectable({ providedIn: 'root' })
export class HeaderLinkStore extends ComponentStore<MenuState> {
  headerLinkItems$ = this.select(({ items }) => items);

  reset$ = this.updater((state) => ({
    ...state,
    items: []
  }));

  headerProjectLinks$ = this.updater((state, projectKey: string) => ({
    ...state,
    items: [
      new NavItemMixin('constants.todo', RouteUtil.getEventsRouteUrl(projectKey), false, [],
        this.todoToggle.bind(this)),
      new NavItemMixin('constants.calendar', RouteUtil.getCalendarRouteUrl(projectKey), false, [],
        this.calendarToggle.bind(this)),
      new NavItemMixin('constants.project', RouteUtil.getWorkOrderDetailRouteUrl(projectKey), false, [],
        this.projectSettingsToggle.bind(this)),
      // new NavItemMixin('constants.project', '', true, [
      //   new NavItemMixin('constants.details', RouteUtil.getProjectDetailRouteUrl(projectKey)),
      //   new NavItemMixin('constants.access', RouteUtil.getProjectAccessRouteUrl(projectKey))
      // ], this.projectSettingsToggle.bind(this)),
    ]
  }));

  headerFolderLinks$ = this.updater((state) => ({
    ...state,
    items: [
      new NavItemMixin('constants.folder', RouteUtil.getFoldersMyRouteUrl(), false, [],
        this.folderToggle.bind(this)
      ),
      new NavItemMixin('components.folder.shared', RouteUtil.getFolderForSharedRouteUrl(), false, [],
        this.folderSharedToggle.bind(this)
      ),
    ]
  }));

  calendarToggle() {
    this.navBarSecondaryStore.setCalendar$();
  }

  projectSettingsToggle() {
    this.navBarSecondaryStore.setProjectSettings$();
  }

  todoToggle() {
    this.navBarSecondaryStore.secondaryNavBarProject$();
  }

  kanbanToggle() {
    this.navBarSecondaryStore.secondaryNavBarProject$();
  }

  folderToggle() {
    this.navBarSecondaryStore.setFolderMy$();
  }

  folderSharedToggle() {
    this.navBarSecondaryStore.setFolderShared$();
  }

  constructor(private navBarSecondaryStore: NavBarSecondaryStore) {
    super(<MenuState>{})
  }
}
