<div class="w-full pl-8 pr-4 flex-shrink-0 flex items-center space-x-10">
  <ng-container *ngIf="hasBreadcrumbs">
    <ng-container
      *ngIf="breadcrumbStore.breadcrumbs$ | async then breadcrumbTpl"
      [ngTemplateOutlet]="breadcrumbTpl"
      [ngTemplateOutletContext]="{ breadcrumbs: breadcrumbStore.breadcrumbs$ | async }">
    </ng-container>
    <ng-template #breadcrumbTpl let-breadcrumbs>
      <as-breadcrumbs [breadcrumbs]="breadcrumbs"></as-breadcrumbs>
    </ng-template>
  </ng-container>

  <nav class="flex items-center space-x-6" *ngIf="menuLinks && menuLinks.length">
    <ng-container *ngFor="let link of menuLinks">
      <ng-container *ngIf="!link.isDropdown && link.handler">
        <a
          [routerLink]="link.url"
          routerLinkActive="active"
          (click)="link.handler(null)"
          class="menu-item">
          {{ link.label | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="link.isDropdown">
        <div class="relative text-left">
<!--          <as-dropdown [item]="link"></as-dropdown>-->
        </div>
      </ng-container>
    </ng-container>
  </nav>

  <div class="flex-auto"></div>

  <div class="flex items-center space-x-3">
    <span class="inline-flex">
      <ng-container *ngFor="let item of quickItems; let idx = index">
        <a *ngIf="item.handler"
          [ngClass]="idx !== quickItems.length-1 ? 'mr-2' : ''"
          class="-mx-1 quick-item"
          nz-tooltip
          nzTooltipTitle="{{ item.label | translate }}"
          nzTooltipPlacement="bottom"
          [class.active]="item.checked"
          (click)="item.handler(); checkQuickItem(quickItems, item)">
          <span class="sr-only">{{ item.label | translate }}</span>
          <svg-icon [key]="item.icon"></svg-icon>
          <kbd *ngIf="idx === 0" class="ml-auto text-2xs text-slate-700 dark:text-slate-400">
            <kbd class="font-sans">{{modifierKey}}</kbd>
            <kbd class="font-sans">K</kbd>
          </kbd>
        </a>
        <a *ngIf="!item.handler"
           (click)="checkQuickItem(quickItems, item)"
           [ngClass]="idx !== quickItems.length-1 ? 'mr-2' : ''"
           class="-mx-1 quick-item"
           nz-tooltip
           nzTooltipTitle="{{ item.label | translate }}"
           nzTooltipPlacement="bottom"
           nz-dropdown
           (nzVisibleChange)="onResetCheckedQuickItems($event)"
           [class.active]="item.checked"
           nzTrigger="click"
           [nzDropdownMenu]="notifications">
          <span class="sr-only">{{ item.label | translate }}</span>
          <span class="toot relative h-6 w-6">
            <svg-icon [key]="item.icon"></svg-icon>
            <ng-container *ngIf="idx === 3">
              <as-top-notification
                *ngIf="notificationsFacade.hasUnreadNotifications$ | async">
              </as-top-notification>
            </ng-container>
          </span>
        </a>
      </ng-container>
    </span>

    <as-button
      *asHasPermission="['task:create']"
      size="sm"
      [iconOnly]="true"
      icon="mini/plus"
      variant="twotone"
      overrideClass="!px-2"
      (click)="handleOpenAddEvent()">
      <span class="sr-only">{{ 'buttons.create' | translate }}</span>
    </as-button>

    <div
      *ngIf="session$ | async as session"
      class="relative inline-block text-left"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="userMenu"
    >
      <div nz-tooltip
           class="itemIcon"
           nzTooltipPlacement="right"
           [nzTooltipTitle]="session.fullName"
      >
        <as-avatar
          class="flex"
          [rounded]="false"
          [size]="36"
          [avatarUrl]="session.avatarUrl"
          [status]="session.accountStatus"
          text="{{ session.fullName | avatarLetters }}">
        </as-avatar>
      </div>
    </div>
  </div>
</div>

<nz-dropdown-menu #notifications="nzDropdownMenu">
  <as-notification-list></as-notification-list>
</nz-dropdown-menu>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
  <div class="user-session-popover" *ngIf="session$ | async as session">
    <div class="py-1" role="none" *ngIf="organization$ | async as organization">
      <div class="flex px-1 pb-2">
        <as-avatar
          class="flex"
          [size]="45"
          [rounded]="false"
          [avatarUrl]="session.avatarUrl"
          text="{{ session.fullName | avatarLetters }}"
        >
        </as-avatar>
        <div class="pl-2">
          <p class="text-sm"><strong>{{ session.fullName }}</strong></p>
          <p class="dark:text-gray-300">{{ session.email }}</p>
        </div>
      </div>
    </div>
    <div class="py-1" role="none">
      <a
        role="button"
        class="submenu-item-fix"
      >
        <div class="flex flex-1 items-center">
          <span>{{ 'drawers.quick_settings.themes' | translate }}</span>
          <nz-select
            class="ml-2 w-full"
            [formControl]="themeControl"
            (ngModelChange)="handleChangeTheme(session)"
          >
            <nz-option
              *ngFor="let theme of themesForSelect"
              nzLabel="{{ theme.label | translate }}"
              [nzValue]="theme.value"
            >
              {{ theme }}
            </nz-option>
          </nz-select>
        </div>
      </a>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="handleOpenUserStatus(session)"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button"
      >
        <span class="mr-1" *ngIf="getCurrentStatus(session)" [innerHTML]="getCurrentStatus(session)?.emoji"></span>
        {{ 'dropdowns.user_session.update_status' | translate }}
      </a>
      <a
        (click)="setUserSessionAsAway(session)"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button"
      >
        {{ 'dropdowns.user_session.set_as_away' | translate }}
      </a>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="handleGoToProfile()"
        routerLinkActive="active"
        class="submenu-item allowed"
        role="button"
      >
        <div class="flex items-center">
          <svg-icon key="outline/user" class="mr-4" size="sm"></svg-icon>
          {{ 'sidebar.left.profile' | translate }}
        </div>
      </a>
    </div>
    <div class="py-1" role="none">
      <a
        (click)="handleLogOut(session)"
        class="submenu-item allowed"
        role="button"
      >
        <div class="flex items-center">
          <svg-icon key="outline/log-out" class="mr-4" size="sm"></svg-icon>
          {{ 'sidebar.left.sign_out' | translate }}
        </div>
      </a>
    </div>
  </div>
</nz-dropdown-menu>
