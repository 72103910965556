import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {loadProjects, loadProjectsError, loadProjectsSuccess} from "./projects.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {ProjectApi} from "@app-web-central/web/shared/data-access/stouds-api";

@Injectable({ providedIn: 'root' })
export class ProjectsEffects {

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjects),
      switchMap(() => this._projectApi.list()
        .pipe(
          map((response) => loadProjectsSuccess({ projects: response.payload })),
          catchError((error) => of(loadProjectsError(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _projectApi: ProjectApi
  ) { }
}
