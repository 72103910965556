import {Injectable} from "@angular/core";
import {
  AddressesState,
  getAddresses,
  getAddressesLoading,
  loadAddresses
} from "@app-web-central/web/address/data-access";
import {select, Store} from "@ngrx/store";
import {map, Observable} from "rxjs";
import {CfAddress} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class AddressesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getAddressesLoading));
  public addresses$: Observable<CfAddress[] | null>
    = this.store.pipe(select(getAddresses));
  public addressesEnabled$: Observable<CfAddress[]> = this.addresses$.pipe(
    map((addresses: CfAddress[] | null) => addresses
      ? addresses.filter((address: CfAddress) => address.enabled)
      : []
    )
  );

  constructor(
    private store: Store<AddressesState>
  ) { }

  public loadAddresses(): void {
    this.store.dispatch(loadAddresses());
  }

}
