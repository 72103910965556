export class NavItem {
  constructor(public label: string, public icon: string, public handler?: Handler, public checked?: boolean) { }
}

export class NavItemWithUrl {
  constructor(public label: string, public icon: string, public handler: HandlerWithParams, public url?: string, public external?: boolean) { }
}

export class NavItemWithIconAndUrl {
  constructor(public label: string, public icon: string, public url: string, public scope: string, public external?: boolean) { }
}

export class NavItemMixin {
  constructor(public label: string, public url?: string, public isDropdown?: boolean, public items?: NavItemMixin[], public handler?: HandlerWithParams, public value?: any, public icon?: string) { }
}

export class NavItemWithGroup {
  constructor(public label: string, public icon: string, public handler: Handler, public group?: boolean) { }
}

export class NavItemWithoutIcon {
  constructor(public label: string, public handler: Handler) { }
}

type Handler = () => void;
export type HandlerWithParams = (params: any | null) => void;
