import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";
import {CfLicense} from "../../../models/src/lib/api/license";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class LicenseApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public get(licenseId: string): Observable<ResponseState<CfLicense>> {
    return this.httpClient.get<ResponseState<CfLicense>>(
      `${this._appConfig.licenseV1Url}manage/licenses/${licenseId}`
    );
  }

  public list(): Observable<ResponseState<CfLicense[]>> {
    return this.httpClient.get<ResponseState<CfLicense[]>>(
      `${this._appConfig.licenseV1Url}manage/licenses`
    );
  }

}
