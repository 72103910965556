import {createAction, props} from "@ngrx/store";
import {CfAbility} from "@app-web-central/web/shared/data-access/models";

export const loadAbilities = createAction('[Ability List] Load Abilities');

export const loadAbilitiesSuccess = createAction(
  '[Ability List] Load Abilities Success',
  props<{
    abilities: CfAbility[];
  }>()
);

export const loadAbilitiesError = createAction(
  '[Ability List] Load Abilities Error',
  props<{
    error: string;
  }>()
);

export const createAbility = createAction(
  '[Ability Object] Create Ability',
  props<{
    ability: CfAbility
  }>()
);

export const createAbilitySuccess = createAction(
  '[Ability Object] Create Ability Success',
  props<{
    abilities: CfAbility[]
  }>()
);

export const createAbilityError = createAction(
  '[Ability Object] Create Ability Error',
  props<{
    error: string
  }>()
);

export const updateAbility = createAction(
  '[Ability Object] Update Ability',
  props<{
    ability: CfAbility
  }>()
);

export const updateAbilitySuccess = createAction(
  '[Ability Object] Update Ability Success',
  props<{
    abilities: CfAbility[]
  }>()
);

export const updateAbilityError = createAction(
  '[Ability Object] Update Ability Error',
  props<{
    error: string
  }>()
);

export const deleteAbility = createAction(
  '[Ability Object] Delete Ability',
  props<{
    abilityId: string
  }>()
);

export const deleteAbilitySuccess = createAction(
  '[Ability Object] Delete Ability Success',
  props<{
    abilities: CfAbility[]
  }>()
);

export const deleteAbilityError = createAction(
  '[Ability Object] Delete Ability Error',
  props<{
    error: string
  }>()
);
