import {HandlerWithParams} from "./nav-item";
import {TemplateRef} from "@angular/core";

export class DropdownItem {
  constructor(
    public label: string,
    public handler: HandlerWithParams,
    public icon: string | null,
    public dropdowns: DropdownItem[] | null,
    public scope: string,
    public tooltip?: string,
    public tooltipTpl?: TemplateRef<any>,
    public value?: any,
  ) { }
}
