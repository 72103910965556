import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BadgeComponent} from './badge.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    IconModule,
    CommonModule
  ],
  declarations: [
    BadgeComponent
  ],
  exports: [
    BadgeComponent
  ],
})
export class BadgeModule {
}
