import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {CategoryApi} from "../../../../../shared/data-access/stouds-api/src/lib/category-api";
import {catchError, map, switchMap} from "rxjs";
import {loadCategories, loadCategoriesError, loadCategoriesSuccess} from "./categories.actions";

@Injectable({ providedIn: 'root' })
export class CategoriesEffects {

  public loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategories),
      switchMap(() => this.categoryApi.list()
        .pipe(
          map(response => loadCategoriesSuccess({ categories: response.payload })),
          catchError(error => [loadCategoriesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private categoryApi: CategoryApi
  ) { }

}
