<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'recurring.edit.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form
    class="w-full max-w-lg"
    [formGroup]="recurringEditForm"
    (ngSubmit)="submit()">
    <nz-radio-group
      [formControl]="endChoiceControl"
      (ngModelChange)="onChangeRadioEnds($event)">

      <label nz-radio nzValue="all">
        {{ 'recurring.edit.all' | translate }}
      </label>

      <label nz-radio nzValue="after" class="mt-1">
        {{ 'recurring.edit.after' | translate }}
      </label>

      <label nz-radio nzValue="one" class="mt-1">
        {{ 'recurring.edit.one' | translate }}
      </label>
    </nz-radio-group>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="recurringEditForm.invalid"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>
  </form>
</div>

