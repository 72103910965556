import {Injectable} from "@angular/core";
import {CfUser, GenericState} from "@app-web-central/web/shared/data-access/models";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {getUsers, loadUsersSuccess, UsersState} from "../users";
import {select, Store} from "@ngrx/store";
import {CfPassword, UserApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {SelectorUtil, UserUtil} from "@app-web-central/web/shared/utils";
import {ActivatedRoute} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";
import {AuthFacade} from "@app-web-central/web/auth/data-access";

interface UserState extends GenericState<CfUser> {
  userId: string;
}

@Injectable({ providedIn: 'root' })
export class UserStore extends ComponentStore<UserState> {
  users$ = this._store.pipe(select(getUsers));
  session$ = this.authFacade.user$;
  isCurrentUserLoading$ = this.select(SelectorUtil.isLoading);

  userIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["userId"]),
    filter((userId: string) => !!userId)
  );

  userById$ = (userId: string): Observable<CfUser | undefined> => this.users$.pipe(
    map((users) => users?.find((u) => u.id === userId))
  );

  userByEmail$ = (userEmail: string): Observable<CfUser | undefined> => this.users$.pipe(
    map((users) => users?.find((u) => u.email === userEmail))
  );

  user$ = this.userIdParams$.pipe(
    tap((userId) => {
      this.patchState({
        userId
      });
      this.loadUser({ userId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadUser = this.effect<{ userId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ userId }) =>
        this._userApi.get(userId).pipe(
          tapResponse(
            (response) => {
              const user = { ...response.payload };
              this.patchState({
                data: user,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  create = this.effect<CfUser>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([user, users]) =>
          this._userApi.create(user)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'create');
                  const newUsers = users !== null ? [...users] : [];
                  newUsers.push(response.payload);
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this.notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  update = this.effect<CfUser>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$, this.session$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([user, users, session]) =>
          this._userApi.update(user.id, user)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'update');
                  const newUsers = users !== null ? [...users] : [];
                  const index = newUsers.findIndex(item => item.id === response.payload.id);
                  newUsers[index] = {...response.payload};
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  if (session && session.id === response.payload.id) {
                    this.authFacade.updateUser(response.payload);
                  }
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.notify('error', 'update');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  updateMe = this.effect<CfUser>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$, this.session$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([user, users, session]) =>
          this._userApi.updateMe(user.id, user)
            .pipe(
              tapResponse(
                (response) => {
                  const newUsers = users !== null ? [...users] : [];
                  const index = newUsers.findIndex(item => item.id === response.payload.id);
                  newUsers[index] = {...response.payload};
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  if (session && session.id === response.payload.id) {
                    this.authFacade.updateUser(response.payload);
                  }
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.notify('error', 'update');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  updateStatus = this.effect<CfUser>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([user, users]) =>
          this._userApi.updateStatus(user.id, user)
            .pipe(
              tapResponse(
                (response) => {
                  const newUsers = users !== null ? [...users] : [];
                  const index = newUsers.findIndex(item => item.id === response.payload.id);
                  newUsers[index] = {...response.payload};
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.authFacade.updateUser(response.payload);
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  updateAsAway = this.effect<string>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([userId, users]) =>
          this._userApi.updateAsAway(userId)
            .pipe(
              tapResponse(
                (response) => {
                  const newUsers = users !== null ? [...users] : [];
                  const index = newUsers.findIndex(item => item.id === response.payload.id);
                  newUsers[index] = {...response.payload};
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.authFacade.updateUser(response.payload);
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  updatePassword = this.effect<{ user: CfUser, password: CfPassword }>((params$) => (
      params$.pipe(
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(({ user, password }) =>
          this._userApi.updatePassword(password)
            .pipe(
              tapResponse(
                () => {
                  this.notify('success', 'password');
                  this.patchState({
                    data: user,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.notify('error', 'password');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ user: CfUser }>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ user }, users]) =>
          this._userApi.delete(user.id)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'delete');
                  const newUsers = UserUtil.removeUserFromUsers(users, user);
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this.notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ user: CfUser }>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ user }, users]) =>
          this._userApi.enable(user.id)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'enable');
                  const newUsers = UserUtil.replaceUserAndGetNewUsers(users, response.payload);
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this.notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ user: CfUser }>((params$) => (
      params$.pipe(
        withLatestFrom(this.users$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ user }, users]) =>
          this._userApi.disable(user.id)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'disable');
                  const newUsers = UserUtil.replaceUserAndGetNewUsers(users, response.payload);
                  this._store.dispatch(
                    loadUsersSuccess({
                      users: newUsers
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this.notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private notify(state: string, action: string): void {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}`),
      this._translate.instant(`notifications.user.${action}.${state}_message`)
    );
  }

  constructor(
    private _userApi: UserApi,
    private _route: ActivatedRoute,
    private authFacade: AuthFacade,
    private _store: Store<UsersState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<UserState>{});
  }
}
