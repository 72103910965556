import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {createTask, getTasks, getTasksLoading, loadTasks, TasksState} from "@app-web-central/web/task/data-access";
import {CfTask, CfTaskAssignee, CfUser} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class TasksFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getTasksLoading));
  public tasks$: Observable<CfTask[] | null>
    = this.store.pipe(select(getTasks));

  constructor(
    private store: Store<TasksState>,
  ) { }

  public loadTasks(): void {
    this.store.dispatch(loadTasks());
  }

  public async createTask(task: CfTask): Promise<boolean> {
    return new Promise((resolve) => {
      this.store.dispatch(createTask({ task }));
      resolve(true);
    });
  }

  public isInAssignees(user: CfUser, assignees: CfTaskAssignee[]): boolean {
    if (assignees) {
      return assignees.some((y) => y.assigneeId === user.id);
    }
    return false;
  }

}
