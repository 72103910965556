import {AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'as-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {
  @ViewChild("textElement") textElement!: ElementRef;
  @ViewChild("blinkElement") blinkElement!: ElementRef;
  @Input() wordArray: string[] = [
    "👉 What Can You Do With Quantum?",
    "✅ Manage Your Daily Tasks...",
    "Reduce Unplanned downtime 📉👀",
    "🆙 Increase productivity! 🏹--->🎯"
  ];
  @Input() blinkWidth = "2px";
  @Input() typingSpeedMilliseconds = 100;
  @Input() deleteSpeedMilliseconds = 80;
  private i = 0;

  constructor(
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    this.initVariables();
    this.typingEffect();
  }

  private initVariables(): void {
    this.renderer.setStyle(
      this.textElement.nativeElement,
      "padding",
      "0.1em"
    );
    this.renderer.setStyle(
      this.blinkElement.nativeElement,
      "border-right-width",
      this.blinkWidth
    );
  }

  private getRandomTypingSpeed(): number {
    return Math.floor(Math.random() * (150 - 90 + 1)) + 90;
  }

  private typingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopTyping = () => {
      if (word.length > 0) {
        this.textElement.nativeElement.innerHTML += word.shift();
      } else {
        setTimeout(() => this.deletingEffect(), 2000); // 2 seconds pause
        return;
      }
      setTimeout(loopTyping, this.getRandomTypingSpeed());
    };
    loopTyping();
  }

  private deletingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopDeleting = () => {
      if (word.length > 0) {
        word.pop();
        this.textElement.nativeElement.innerHTML = word.join("");
      } else {
        if (this.wordArray.length > this.i + 1) {
          this.i++;
        } else {
          this.i = 0;
        }
        this.typingEffect();
        return;
      }
      setTimeout(loopDeleting, this.deleteSpeedMilliseconds);
    };
    loopDeleting();
  }
}
