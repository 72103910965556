import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfAbility, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class AbilityApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private apiConfig: ApiConfig,
  ) { }

  public get(abilityId: string): Observable<ResponseState<CfAbility>> {
    return this.httpClient.get<ResponseState<CfAbility>>(
      `${this.apiConfig.interventionV1Url}manage/abilities/${abilityId}`
    );
  }

  public list(): Observable<ResponseState<CfAbility[]>> {
    return this.httpClient.get<ResponseState<CfAbility[]>>(
      `${this.apiConfig.interventionV1Url}manage/abilities`
    );
  }

  public create(ability: CfAbility): Observable<ResponseState<CfAbility>> {
    return this.httpClient.post<ResponseState<CfAbility>>(
      `${this.apiConfig.interventionV1Url}manage/abilities`, ability
    );
  }

  public update(abilityId: string, ability: CfAbility): Observable<ResponseState<CfAbility>> {
    return this.httpClient.put<ResponseState<CfAbility>>(
      `${this.apiConfig.interventionV1Url}manage/abilities/${abilityId}`, ability
    );
  }

  public delete(abilityId: string): Observable<ResponseState<CfAbility>> {
    return this.httpClient.delete<ResponseState<CfAbility>>(
      `${this.apiConfig.interventionV1Url}manage/abilities/${abilityId}`
    );
  }

}
