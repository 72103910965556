import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  CfProject,
  TaskStatus,
  CfTaskMetadata, CfUser, CfAddress,
} from "@app-web-central/web/shared/data-access/models";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {TasksFacade} from "@app-web-central/web/task/data-access";
import {ProjectStore} from "@app-web-central/web/project/data-access";
import {Observable} from "rxjs";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {TaskUtil} from "@app-web-central/web/shared/utils";
import {AuthFacade} from "@app-web-central/web/auth/data-access";

@UntilDestroy()
@Component({
  selector: 'as-add-task-modal',
  templateUrl: './add-task-modal.component.html',
  styleUrls: ['./add-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTaskModalComponent implements OnInit {
  @Input() project$!: Observable<CfProject | null>;
  public taskForm!: FormGroup;
  public submitted =  false;
  public session$: Observable<CfUser | null>
    = this.authFacade.user$;
  public reporters$: Observable<CfUser[]>
    = this.usersFacade.usersEnabled$;
  public assignees$: Observable<CfUser[]>
    = this.usersFacade.usersEnabledAndAgent$;
  public addresses$: Observable<CfAddress[]>
    = this.addressesFacade.addressesEnabled$;
  public projects$: Observable<CfProject[]>
    = this.projectStore.projectsFilteredByUserAccess$();
  public isWorking$: Observable<boolean>
    = this.tasksFacade.isLoading$;
  public openMetadataForm = false;
  public editLastMeta!: CfTaskMetadata | null;

  constructor(
    private modalRef: NzModalRef,
    private authFacade: AuthFacade,
    private tasksFacade: TasksFacade,
    private formBuilder: FormBuilder,
    private usersFacade: UsersFacade,
    private projectStore: ProjectStore,
    private addressesFacade: AddressesFacade,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm();
    this.setSessionIds();
  }

  public controls(field: string): FormControl {
    return this.taskForm.get(field) as FormControl;
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public cancel(): void {
    this.taskForm.reset();
    this.closeModal();
  }

  public onChangeProject(project: CfProject): void {
    this.reporters$ = this.projectStore.usersEnabledByProject$(project.id);
    this.assignees$ = this.projectStore.usersEnabledByProject$(project.id);
  }

  public onOpenMetadataForm(): void {
    this.openMetadataForm = !this.openMetadataForm;
  }

  public onCancelMetadataForm(): void {
    this.resetMetadata();
  }

  public onAddMetadata(formData: CfTaskMetadata): void {
    if (formData) {
      const formValue = this.taskForm.getRawValue();
      const metadata = formValue.metadata && formValue.metadata.length
        ? [ ...formValue.metadata ]
        : [];
      if (this.editLastMeta && metadata.some((y) =>
        y.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase())
      ) {
        const index = metadata.findIndex((x) =>
          x.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase());
        metadata[index] = { ...formData };
      } else {
        metadata.push(formData)
      }
      this.taskForm.controls['metadata'].setValue(metadata);
    }
    this.resetMetadata();
  }

  public onRemoveMetadata(meta: CfTaskMetadata): void {
    const formValue = this.taskForm.getRawValue();
    let metadata = [ ...formValue.metadata ];
    const index = metadata.findIndex((x) =>
      x.key.trim().toLowerCase() === meta.key.trim().toLowerCase());
    if (index) {
      metadata.splice(index, 1);
      this.taskForm.controls['metadata'].setValue(metadata);
    } else if (index === 0) {
      if (metadata.length > 1) {
        metadata.splice(index, 1);
      } else {
        metadata = [];
        this.taskForm.controls['metadata'].setValue(metadata);
      }
    }
  }

  public onEditMetadata(meta: CfTaskMetadata): void {
    this.editLastMeta = meta;
    this.openMetadataForm = !this.openMetadataForm;
    this.taskForm.controls['metadataKey'].setValue(meta.key);
    this.taskForm.controls['metadataValue'].setValue(meta.value);
  }

  public submit(): void {
    this.submitted = true;

    if (this.taskForm.invalid) {
      return;
    }

    const formValue = this.taskForm.getRawValue();
    formValue["projectId"] = formValue.project.id;
    if (formValue.deliveryDate) {
      formValue["dates"] = { startDate: formValue.deliveryDate.getTime() };
      delete formValue["deliveryDate"];
    }
    formValue.assignees = TaskUtil.mapAssignees(formValue);
    delete formValue["users"];
    delete formValue["project"];
    delete formValue["metadataKey"];
    delete formValue["metadataValue"];
    this.tasksFacade.createTask(formValue)
      .then((resolved: boolean) => {
        if (resolved) {
          this.closeModal();
        }
      });
  }

  private resetMetadata(): void {
    this.editLastMeta = null;
    this.taskForm.controls['metadataKey'].setValue(null);
    this.taskForm.controls['metadataValue'].setValue(null);
    this.openMetadataForm = !this.openMetadataForm;
  }

  private setSessionIds(): void {
    this.session$.pipe(untilDestroyed(this)).subscribe(session => {
      if (session) {
        this.taskForm.controls['createdBy'].setValue(session);
        this.taskForm.controls['modifiedBy'].setValue(session);
      }
    });
  }

  private initForm(): void {
    this.taskForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(6)]],
      description: [null, [Validators.maxLength(2048)]],
      project: [null, [Validators.required]],
      recipient: [null],
      status: [TaskStatus.UNASSIGNED, [Validators.required]],
      assignee: [null],
      assignees: [[]],
      users: [[]],
      deliveryDate: [null],
      createdBy: [null, [Validators.required]],
      modifiedBy: [null, [Validators.required]],
      address: [null, [Validators.required]],
      suite: [null],
      enabled: [true],
      metadata: [null],
      metadataKey: [null],
      metadataValue: [null],
    });
  }

  private updateForm(): void {
    try {
      this.project$
        .pipe(untilDestroyed(this))
        .subscribe((project: CfProject | null) => {
          if (project) {
            this.taskForm.patchValue(({
              project
            }));
          }
        });
    } catch (e) {
      this.taskForm.controls['project'].setValue(null);
    }
  }

}
