import {Inject, Injectable} from "@angular/core";
import {CfOrganization, CfUIFolder, CfUser, DropdownItem} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {ModalFolderAddComponent} from "@app-web-central/web/shell/ui/modal-folder-add";
import {ModalFolderShareComponent} from "@app-web-central/web/shell/ui/modal-folder-share";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NzModalService} from "ng-zorro-antd/modal";
import {FolderStore} from "../store/folder";
import {OrganizationStore} from "@app-web-central/web/organization/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NzContextMenuService} from "ng-zorro-antd/dropdown";
import {AuthFacade} from "@app-web-central/web/auth/data-access";
import {NzNotificationService} from "ng-zorro-antd/notification";


@UntilDestroy()
@Injectable()
export class ContextService {
  session!: CfUser;
  organization!: CfOrganization;

  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _router: Router,
    private _modal: NzModalService,
    private authFacade: AuthFacade,
    private _folderStore: FolderStore,
    private _translate: TranslateService,
    private translateService: TranslateService,
    private _organizationStore: OrganizationStore,
    private notificationService: NzNotificationService,
    private _nzContextMenuService: NzContextMenuService
  ) {
    this._initSession();
    this._initOrganization();
  }

  getContextMenu() {
    return [
      new DropdownItem('files.context.open', this.redirectTo.bind(this), 'solid/arrow-pointing-out', null, 'storage:display'),
      new DropdownItem('files.context.link', this.getLink.bind(this), 'solid/link', null, 'storage:display'),
      new DropdownItem('files.context.rename', this.openRenameFolder.bind(this), 'outline/pencil', null, 'storage:edit'),
      new DropdownItem('files.context.share', this.openShareFolder.bind(this), 'solid/share', null, 'storage:edit'),
      new DropdownItem('files.context.starred', this.starredFolder.bind(this), 'outline/star', null, 'storage:display'),
      new DropdownItem('files.context.trash', this.moveToBin.bind(this), 'outline/trash', null, 'storage:delete')
    ];
  }

  getTrashContextMenu() {
    return [
      new DropdownItem('files.context.restore', this.restoreItem.bind(this), 'outline/clock', null, 'storage:edit'),
      new DropdownItem('files.context.remove', this.removeItem.bind(this), 'outline/trash', null, 'storage:delete')
    ];
  }

  private _initSession() {
    this.authFacade.user$
      .pipe(untilDestroyed(this))
      .subscribe((session) => {
        if (session) {
          this.session = session;
        }
      });
  }

  private _initOrganization() {
    this._organizationStore.organization$
      .pipe(untilDestroyed(this))
      .subscribe((organization) => {
        this.organization = organization;
      });
  }

  restoreItem(folder: CfUIFolder) {
    if (folder.name.isFile) {
      this._folderStore.fileRestore(folder);
    } else {
      this._folderStore.folderRestore(folder);
    }
  }

  public removeItem(folder: CfUIFolder): void {
    if (folder.name.isFile) {
      this._folderStore.fileRemoveForever(folder);
    } else {
      this._folderStore.folderRemoveForever(folder);
    }
  }

  public redirectTo(folder: CfUIFolder): void {
    this._router.navigateByUrl(RouteUtil.getFolderRouteUrl(folder.name.key));
    this._nzContextMenuService.close();
  }

  public getLink(folder: CfUIFolder): void {
    navigator.clipboard.writeText(`${this._appConfig.appUrl}${RouteUtil.getFolderRouteUrl(folder.name.key)}`)
      .then(() => this.notificationService.success(
        this.translateService.instant('notifications.permalink.success'),
        this.translateService.instant('files.message.copied'),
        { nzPlacement: "bottomLeft" }
      ));
    this._nzContextMenuService.close();
  }

  openRenameFolder(folder: CfUIFolder) {
    this._nzContextMenuService.close();
    this._modal.create({
      nzContent: ModalFolderAddComponent,
      nzComponentParams: { folder },
      nzClosable: false,
      nzWidth: 400
    });
  }

  openShareFolder(folder: CfUIFolder) {
    this._nzContextMenuService.close();
    this._modal.create({
      nzContent: ModalFolderShareComponent,
      nzComponentParams: {
        folder,
        session: this.session,
        organization: this.organization
      },
      nzClosable: false,
      nzWidth: 500,
      nzFooter: null
    });
  }

  starredFolder(folder: CfUIFolder) {
    const newFolder = { ...folder };
    newFolder.name.starred = !newFolder.name.starred;
    if (newFolder.name.starred) {
      this._folderStore.addStarredFolder(newFolder);
    } else {
      this._folderStore.removeStarredFolder(newFolder);
    }
    this._nzContextMenuService.close();
  }

  moveToBin(folder: CfUIFolder) {
    this._nzContextMenuService.close();
    this._modal.warning({
      nzTitle: this._translate.instant(`modals.folders.delete.title`),
      nzContent: this._translate.instant(`modals.folders.delete.content`, { param: folder.name.value }),
      nzOkText: this._translate.instant('buttons.ok'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        if (folder.name.isFile) {
          this._folderStore.fileMoveToTrash(folder);
        } else {
          this._folderStore.folderMoveToTrash(folder);
        }
      },
      nzCancelText: this._translate.instant('buttons.no'),
      nzOnCancel: () => ({})
    });
  }

}
