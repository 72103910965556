import {ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CfChannel,
  CfComment,
  CfProject,
  CfTask,
  CfUser,
  NavItem,
  Priority,
  TaskStatus,
  CfTaskMetadata,
  TaskStatusesValues, CfAddress,
} from "@app-web-central/web/shared/data-access/models";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NzModalService} from "ng-zorro-antd/modal";
import {TranslateService} from "@ngx-translate/core";
import {createTask, TasksService, TasksState, TaskStore} from "@app-web-central/web/task/data-access";
import {ChannelStore} from "@app-web-central/web/channel/data-access";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RecipientsFacade} from "@app-web-central/web/recipient/data-access";
import {InterventionsFacade} from "@app-web-central/web/intervention/data-access";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {Store} from "@ngrx/store";
import {ERROR} from "@app-web-central/web/shared/services/local-notification";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ProjectStore} from "@app-web-central/web/project/data-access";
import {Observable} from "rxjs";
import {TaskUtil} from "@app-web-central/web/shared/utils";
import {AuthFacade} from "@app-web-central/web/auth/data-access";
import {UsersFacade} from "@app-web-central/web/user/data-access";

@UntilDestroy()
@Component({
  selector: 'as-task-drawer',
  templateUrl: './task-drawer.component.html',
  styleUrls: ['./task-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskStore]
})
export class TaskDrawerComponent implements OnInit {
  @Input() project!: CfProject;
  @ViewChild('cancelNotificationTpl') cancelNotificationTpl!: TemplateRef<any>;
  public assignees$: Observable<CfUser[]>
    = this.usersFacade.usersEnabled$;

  public addresses$: Observable<CfAddress[]>
    = this._addressesFacade.addressesEnabled$;

  session$ = this.authFacade.user$;
  recipients$ = this._recipientsFacade.recipientsEnabled$;
  interventions$ = this._interventionFacade.interventions$;
  task$ = this._taskStore.task$;
  isWorking$ = this._taskStore.isCurrentTaskLoading$;
  task!: CfTask;
  editLastMeta!: CfTaskMetadata | null;

  taskForm!: FormGroup;
  submitted = false;
  statuses = TaskStatusesValues;
  calendarDate!: Date;
  selectedMenu!: NavItem;
  filesLoaded!: File[];
  countComments = 0;
  openMetadataForm = false;

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.assigneeId === o2.assigneeId : o1 === o2);

  constructor(
    private usersFacade: UsersFacade,
    public projectStore: ProjectStore,

    private _modal: NzModalService,
    private _taskStore: TaskStore,
    private authFacade: AuthFacade,
    private _nzDrawer: NzDrawerRef,
    private store: Store<TasksState>,
    private _formBuilder: FormBuilder,
    private _channelStore: ChannelStore,
    private _tasksService: TasksService,
    public translate: TranslateService,
    private _addressesFacade: AddressesFacade,
    private _recipientsFacade: RecipientsFacade,
    private _notification: NzNotificationService,
    private _interventionFacade: InterventionsFacade,
  ) { }

  f(field: string) {
    return this.taskForm.get(field) as FormControl;
  }

  ngOnInit(): void {
    this._initForm();
    this.task$
      .pipe(untilDestroyed(this))
      .subscribe((task) => {
      if (task) {
        this.task = task;
        this._fetchCountComments(task.comments);
        this._updateForm();
      }
    });
  }

  cancel() {
    if (this.cancelNotificationTpl) {
      this._notification.template(this.cancelNotificationTpl);
    } else {
      return;
    }
  }

  successCancel() {
    this._nzDrawer.close();
  }

  onChangeSelectedMenu(activeMenu: NavItem) {
    this.selectedMenu = activeMenu;
  }

  onFilesSelected(files: File[]): void {
    if (files) {
      this.filesLoaded = files;
    }
  }

  onSelectPriority(priority: Priority | null) {
    if (!priority) {
      this.taskForm.controls["priority"].setValue(null);
    } else {
      this.taskForm.controls["priority"].setValue(priority);
    }
  }

  subscribers(channel: CfChannel) {
    if (channel !== null) {
      return channel.subscribers
        .filter((x) => !x.unsubscribed).length;
    }
    return 0;
  }

  onOpenMetadataForm() {
    this.openMetadataForm = !this.openMetadataForm;
  }

  onAddMetadata(formData: any) {
    if (formData) {
      const formValue = this.taskForm.getRawValue();
      const metadata = formValue.metadata && formValue.metadata.length
        ? [ ...formValue.metadata ]
        : [];
      if (this.editLastMeta && metadata.some((y) =>
        y.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase())
      ) {
        const index = metadata.findIndex((x) =>
          x.key.trim().toLowerCase() === this.editLastMeta?.key.trim().toLowerCase());
        metadata[index] = { ...formData };
      } else {
        metadata.push(formData)
      }
      this.taskForm.controls['metadata'].setValue(metadata);
    }
    this._resetMetadata();
  }

  onRemoveMetadata(meta: CfTaskMetadata) {
    const formValue = this.taskForm.getRawValue();
    let metadata = [ ...formValue.metadata ];
    const index = metadata.findIndex((x) =>
      x.key.trim().toLowerCase() === meta.key.trim().toLowerCase());
    if (index) {
      metadata.splice(index, 1);
      this.taskForm.controls['metadata'].setValue(metadata);
    } else if (index === 0) {
      if (metadata.length > 1) {
        metadata.splice(index, 1);
      } else {
        metadata = [];
        this.taskForm.controls['metadata'].setValue(metadata);
      }
    }
  }

  onEditMetadata(meta: CfTaskMetadata) {
    this.editLastMeta = meta;
    this.openMetadataForm = !this.openMetadataForm;
    this.taskForm.controls['metadataKey'].setValue(meta.key);
    this.taskForm.controls['metadataValue'].setValue(meta.value);
  }

  onClone(task: CfTask) {
    this._modal.warning({
      nzTitle: this.translate.instant('modals.clone.title'),
      nzContent: this.translate.instant('modals.clone.content',
        { param: this.translate.instant('modals.clone.components.task.additional_information') }),
      nzOkText: this.translate.instant('buttons.ok'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        const formValue = this.taskForm.getRawValue();
        formValue["projectId"] = this.project.id;
        formValue["createdBy"] = task.createdBy;
        formValue["modifiedBy"] = task.modifiedBy;
        formValue["dates"] = { startDate: task.dates.startDate };
        formValue["name"] = `${formValue["name"]} copy-of-${new Date()}`;
        delete formValue["id"];
        delete formValue["comments"];
        delete formValue["attachments"];
        delete formValue["steps"];
        delete formValue["recurrence"];
        delete formValue["isRecurring"];
        delete formValue["reminder"];
        delete formValue["dateAdd"];
        delete formValue["dateUpd"];
        delete formValue["metadata"];
        this.store.dispatch(createTask({ task: formValue }));
      },
      nzCancelText: this.translate.instant('buttons.no')
    });
  }

  onDelete(task: CfTask) {
    this._modal.warning({
      nzTitle: this.translate.instant('modals.delete.title'),
      nzContent: this.translate.instant('modals.delete.content',
        { param: this.translate.instant('modals.delete.components.task.additional_information') }),
      nzOkText: this.translate.instant('buttons.ok'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        if (task.isRecurring && task.recurrence) {
          const editRecurrence = this._tasksService.confirmEditRecurringTask();
          editRecurrence.afterClose.pipe(untilDestroyed(this))
            .subscribe((choice) => {
              if (choice) {
                this._taskStore.removeTasks({ choice, task });
              }
            });
        } else {
          this._taskStore.removeTask(task.id)
        }
        this.cancel();
      },
      nzCancelText: this.translate.instant('buttons.no')
    });
  }

  onStart(task: CfTask) {
    if (task.dates?.validationDate) {
      return;
    }
    this._modal.info({
      nzTitle: this.translate.instant('modals.start.title'),
      nzContent: this.translate.instant('modals.start.content'),
      nzOnOk: () => this._taskStore.startTask(task)
    });
  }

  onStop(task: CfTask) {
    if (task.dates?.validationDate) {
      return;
    }
    this._modal.info({
      nzTitle: this.translate.instant('modals.stop.title'),
      nzContent: this.translate.instant('modals.stop.content'),
      nzOnOk: () => this._taskStore.stopTask(task)
    });
  }

  onValidate(task: CfTask) {
    if (task.dates?.validationDate) {
      return;
    }
    this._modal.success({
      nzTitle: this.translate.instant('modals.validate.title'),
      nzContent: this.translate.instant('modals.validate.content'),
      nzOnOk: () => this._taskStore.validateTask(task)
    });
  }

  onStartWatching(task: CfTask, userId: string) {
    if (!task.channel.id) {
      return;
    }
    this._channelStore.startWatchingChannel$({ channelId: task.channel.id, userId });
    this._taskStore.updateTask(task);
  }

  onStopWatching(task: CfTask, userId: string) {
    if (!task.channel.id) {
      return;
    }
    this._channelStore.stopWatchingChannel$({ channelId: task.channel.id, userId });
    this._taskStore.updateTask(task);
  }

  onChangeStatus(task: CfTask, index: number): void {
    if (task.status === TaskStatus.COMPLETED
      && task.dates?.validationDate) {
      return;
    }
  }

  submit() {
    this.submitted = true;
    if (this.taskForm.invalid) {
      return;
    }

    const formValue = this.taskForm.getRawValue();
    formValue["projectId"] = this.project.id;
    if (!this._checkDeliveryDates(formValue)) {
      this._notification.error(ERROR,
        this.translate.instant('task_detail.messages.dates.error'));
      return;
    }
    this._taskStore.updateTask(formValue);
  }

  private _checkDeliveryDates(formValue: CfTask) {
    const dates = { ...formValue.dates };
    return dates.startDate <= dates.endDate;
  }

  private _resetMetadata() {
    this.editLastMeta = null;
    this.taskForm.controls['metadataKey'].setValue(null);
    this.taskForm.controls['metadataValue'].setValue(null);
    this.openMetadataForm = !this.openMetadataForm;
  }

  private _initForm() {
    this.taskForm = this._formBuilder.group({
      id: [null, [Validators.required]],
      name: [null, [Validators.required, Validators.maxLength(255)]],
      description: [null, [Validators.maxLength(4096)]],
      dates: [null, [Validators.required]],
      address: [null, [Validators.required]],
      suite: [null],
      reminder: [null],
      assignee: [null],
      assignees: [[]],
      status: [null, [Validators.required]],
      enabled: [null, [Validators.required]],
      priority: [null],
      tags: [null],
      recipient: [null],
      interventions: [null],
      attachments: [null],
      metadata: [null],
      steps: [null],
      isRecurring: [null],
      recurrence: [null],
      metadataKey: [null],
      metadataValue: [null],
    });
  }

  private _updateForm() {
    const assignees = (!this.task.assignees || !this.task.assignees.length) && this.task.assignee
      ? [TaskUtil.doAssignee(this.task.assignee)]
      : this.task.assignees;
    this.taskForm.patchValue(({
      ...this.task,
      assignees
    }));
  }

  private _fetchCountComments(comments: CfComment[]) {
    let i = 0;
    this.countComments = 0;
    while (i < comments.length) {
      this.countComments = this.countComments +1;
      if (comments[i].children && comments[i].children.length) {
        this._fetchCountComments(comments[i].children);
      }
      i = i+1;
    }
  }
}
