import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfAttachment, CfFolder, CfUser, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class FileApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  public upload(formData: FormData) {
    return this.httpClient.post<ResponseState<CfAttachment>>(`${this._appConfig.uploadV1Url}manage/upload-file`, formData, {
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
    });
  }

  public download(params: any) {
    return this.httpClient.get<ResponseState<any>>(
      `${this._appConfig.uploadV1Url}manage/download-file?keyName=${params.keyName}&folder=${params.folder}&tenant=${params.tenant}`
    );
  }

  public updateFile(file: CfAttachment) {
    return this.httpClient.put<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/update-file/${file.id}`, file
    );
  }

  public delete(filename: string) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/delete-file?filename=${filename}`
    );
  }

  public fileDeleteById(fileId: string) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}`
    );
  }

  public fileMoveToTrash(fileId: string) {
    return this.httpClient.get<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}/move-to-trash`
    );
  }

  public fileRestore(fileId: string) {
    return this.httpClient.get<ResponseState<CfAttachment>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/${fileId}/restore`
    );
  }

  public deleteAll() {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/delete-all`
    );
  }

  public getByKey(folderKey: string) {
    return this.httpClient.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/key/${folderKey}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfFolder[]>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders`
    );
  }

  public listParents() {
    return this.httpClient.get<ResponseState<CfFolder[]>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/parents`
    );
  }

  public create(folder: CfFolder) {
    return this.httpClient.post<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders`, folder,
    );
  }

  public update(folderId: string, folder: CfFolder) {
    return this.httpClient.put<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}`, folder,
    );
  }

  public invite(user: CfUser | any, folderId: string) {
    return this.httpClient.post<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/invite`, user,
    );
  }

  public accept(folderId: string, validationCode: string) {
    return this.httpClient.get<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/join-folder/${validationCode}`,
    );
  }

  public folderRestore(folderId: string) {
    return this.httpClient.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/restore`,
    );
  }

  public folderTrash(folderId: string) {
    return this.httpClient.get<ResponseState<CfFolder>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}/move-to-trash`,
    );
  }

  public folderDelete(folderId: string) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/folders/${folderId}`,
    );
  }

  public storageSize(userId: string | undefined) {
    return this.httpClient.get<ResponseState<string>>(
      `${this._appConfig.uploadV1Url}manage/storage/files/size/${userId}`,
    );
  }

}
