import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfChannel, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ChannelApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public get(channelId: string) {
    return this.httpClient.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfChannel[]>>(
      `${this._appConfig.channelV1Url}manage/channels`
    );
  }

  public startWatchingChannel(channelId: string, userId: string) {
    return this.httpClient.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}/subscribe/${userId}`);
  }

  public stopWatchingChannel(channelId: string, userId: string) {
    return this.httpClient.get<ResponseState<CfChannel>>(
      `${this._appConfig.channelV1Url}manage/channels/${channelId}/unsubscribe/${userId}`);
  }

}
