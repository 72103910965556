import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {PinnedItemStore} from "./pinned-item.store";

export interface SegmentOption {
  label: string;
  value: string;
  useTemplate: boolean;
  index: number;
  visible: boolean;
  iconKey: string | null;
  pinned: boolean;
  url: string;
}

export interface Segment {
  label: string;
  options: SegmentOption[];
}

interface SegmentState {
  segments: Segment[];
}

const initialState = [
  {
    label: 'project',
    options: [
      { label: 'segments.project.table', value: 'table', useTemplate: true, index: 0, visible: true, iconKey: 'outline/table', pinned: false, url: RouteUtil.getWorkOrderRouteUrl() },
      { label: 'segments.project.calendar', value: 'calendar', useTemplate: true, index: 1, visible: true, iconKey: 'outline/calendar-days', pinned: false, url: RouteUtil.getWorkOrderRouteUrl() },
      { label: 'segments.project.analytics', value: 'analytics', useTemplate: true, index: 2, visible: true, iconKey: 'outline/chart-average', pinned: false, url: RouteUtil.getWorkOrderRouteUrl() },
      { label: 'segments.project.scrum', value: 'scrum', useTemplate: true, index: 3, visible: false, iconKey: 'outline/view-columns', pinned: false, url: RouteUtil.getWorkOrderRouteUrl() },
      { label: 'segments.project.gantt', value: 'gantt', useTemplate: true, index: 4, visible: false, iconKey: 'outline/table', pinned: false, url: RouteUtil.getWorkOrderRouteUrl() },
    ]
  }
];

@Injectable({ providedIn: 'root' })
export class SegmentStore extends ComponentStore<SegmentState> {
  segments$ = this.select(({ segments }) => segments);

  optionsVisibleBySegmentName$ = (segmentName: string): Observable<SegmentOption[] | undefined> => this.segments$.pipe(
    map((segments) => segments.find((x) => x.label === segmentName)?.options.filter((o) => o.visible))
  );

  /**
   * ACTION QUERIES
   *
   */

  toggleVisibilitySegment$ = this.updater((state, input: { segmentName: string, option: SegmentOption }) => ({
    ...state,
    segments: this.toggleSegmentVisibility(state.segments, input.segmentName, input.option)
  }));

  togglePinSegment$ = this.updater((state, input: { segmentName: string, object: any, option: SegmentOption }) => ({
    ...state,
    segments: this.togglePinOptionView(state.segments, input.segmentName, input.object, input.option)
  }));

  private toggleSegmentVisibility(segments: Segment[], segmentName: string, option: SegmentOption) {
    const index = segments.findIndex((s) => s.label === segmentName);
    const options = [ ...segments[index].options ];
    const newOptions = options.map((o) => ({
      ...o,
      visible: o.value === option.value
        ? !o.visible
        : o.visible
    }));
    segments[index].options = [ ...newOptions ];
    return segments;
  }

  private togglePinOptionView(segments: Segment[], segmentName: string, object: any, option: SegmentOption) {
    const index = segments.findIndex((s) => s.label === segmentName);
    const options = [ ...segments[index].options ];
    const newOptions = options.map((o) => {
      const segmentOption = ({
        ...o,
        pinned: o.value === option.value
          ? !o.pinned
          : o.pinned
      });
      if (o.value === option.value) {
        this.pinnedItemStore.upsertPinnedItem$({ object: ({
            label: o.label,
            iconKey: o.iconKey,
            url: `${o.url}/${object.key}/${o.value}`,
            labelFor: `(${object.name})`
          })
        });
      }
      return segmentOption;
    });
    segments[index].options = [ ...newOptions ];
    return segments;
  }

  constructor(
    private pinnedItemStore: PinnedItemStore
  ) {
    super(<SegmentState>{ segments: initialState });
  }
}
