import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldComponent} from './field.component';
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {IconModule} from "@app-web-central/web/shared/ui/icon";

@NgModule({
  imports: [
    IconModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  declarations: [
    FieldComponent
  ],
  exports: [
    FieldComponent
  ],
})
export class FieldModule {
}
