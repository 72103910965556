import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfRecipient, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class RecipientApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  public get(recipientId: string) {
    return this.httpClient.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients`
    );
  }

  public create(recipient: CfRecipient) {
    return this.httpClient.post<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients`, recipient
    );
  }

  public update(recipientId: string, recipient: CfRecipient) {
    return this.httpClient.put<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`, recipient
    );
  }

  public delete(recipientId: string) {
    return this.httpClient.delete<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}`
    );
  }

  public deleteBulk(recipientIds: string[]) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/${recipientIds}`
    );
  }

  public enable(recipientId: string) {
    return this.httpClient.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}/enable`
    );
  }

  public enableBulk(recipientIds: string[]) {
    return this.httpClient.post<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/enable`, recipientIds
    );
  }

  public disable(recipientId: string) {
    return this.httpClient.get<ResponseState<CfRecipient>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/${recipientId}/disable`
    );
  }

  public disableBulk(recipientIds: string[]) {
    return this.httpClient.post<ResponseState<CfRecipient[]>>(
      `${this._appConfig.generalConfigV1Url}manage/recipients/bulk/disable`, recipientIds
    );
  }

}
