import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map, Observable} from "rxjs";
import {getSuites, getSuitesLoading} from "./suites.selectors";
import {SuitesState} from "./suites.reducer";
import {loadSuites} from "./suites.actions";
import {CfSuite} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class SuitesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getSuitesLoading));
  public suites$: Observable<CfSuite[] | null>
    = this.store.pipe(select(getSuites));
  public suitesEnabled$: Observable<CfSuite[]> = this.suites$.pipe(
    map((suites: CfSuite[] | null) => suites
      ? suites.filter((suite: CfSuite) => suite.enabled)
      : []
    )
  );

  constructor(
    private store: Store<SuitesState>
  ) { }

  public loadUnits(): void {
    this.store.dispatch(loadSuites());
  }
}
