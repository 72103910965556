import {CfAbility, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {
  createAbility,
  createAbilityError,
  createAbilitySuccess,
  deleteAbility,
  deleteAbilityError,
  deleteAbilitySuccess,
  loadAbilities,
  loadAbilitiesError,
  loadAbilitiesSuccess,
  updateAbility,
  updateAbilityError,
  updateAbilitySuccess
} from "./abilities.actions";

export interface AbilitiesState extends GenericState<CfAbility[]> { }

const initialState: AbilitiesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const abilitiesFeatureKey = 'abilities';

export const abilitiesReducer = createReducer(
  initialState,
  on(loadAbilities, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadAbilitiesSuccess, (state, { abilities }) => ({
    ...state,
    data: abilities,
    status: 'success',
    error: null
  })),
  on(loadAbilitiesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(createAbility, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(createAbilitySuccess, (state, { abilities }) => ({
    ...state,
    data: abilities,
    status: 'success',
    error: null
  })),
  on(createAbilityError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(updateAbility, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(updateAbilitySuccess, (state, { abilities }) => ({
    ...state,
    data: abilities,
    status: 'success',
    error: null
  })),
  on(updateAbilityError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(deleteAbility, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(deleteAbilitySuccess, (state, { abilities }) => ({
    ...state,
    data: abilities,
    status: 'success',
    error: null
  })),
  on(deleteAbilityError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
