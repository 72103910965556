import {Component, Inject, OnInit} from '@angular/core';
import {CookieAgreementService} from "@app-web-central/web/shared/services/cookie-agreement";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {CookiePolicyUrl} from "@app-web-central/web/shared/data-access/models";
import {OcfCookieAgreement, OcfCookieService} from "@app-web-central/web/shared/services/cookie-service";

@Component({
  selector: 'as-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  hasAgree = false;
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    public cookieAgreement: CookieAgreementService,
    private _ocfCookieService: OcfCookieService,
  ) { }

  ngOnInit(): void {
    this.cookieAgreement.hasAgree$.pipe().subscribe((hasAgree) => {
      if (hasAgree) {
        this.hasAgree = hasAgree;
      }
    });
  }

  redirectTo() {
    window.location.replace(`${this._appConfig.legalUrl}/${CookiePolicyUrl}`);
  }

  accept() {
    this.hasAgree = !this.hasAgree;
    this._ocfCookieService.setItem(OcfCookieAgreement, "true");
  }
}
