<div class="border border-gray-200 dark:border-gray-600 rounded-md divide-y divide-gray-200 dark:divide-gray-600"
     *ngIf="control.value && control.value.length">
  <ng-container *ngFor="let data of control.value">
    <div class="grid grid-cols-1 lg:grid-cols-12 gap-3 p-2">
      <div class="col-span-4 px-2 flex items-center">
        <p>{{ data.key }}</p>
      </div>
      <div class="col-span-5 px-2 flex items-center">
        <p>{{ data.value }}</p>
      </div>
      <div class="col-span-3 flex items-center justify-end">
        <as-button
          size="sm"
          class="ml-1.5"
          (click)="onEditMetadata(data)"
          variant="default"
          [iconOnly]="true"
          icon="outline/pencil"
        >
        </as-button>
        <as-button
          size="sm"
          class="ml-1.5"
          (click)="onRemoveMetadata(data)"
          variant="twotone"
          color="danger"
          [iconOnly]="true"
          icon="outline/trash"
        >
        </as-button>
      </div>
    </div>
  </ng-container>
</div>
