import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {TasksEffects, tasksFeatureKey, tasksReducer} from "@app-web-central/web/task/data-access";
import {
  OrganizationsEffects,
  organizationsFeatureKey,
  organizationsReducer
} from "@app-web-central/web/organization/data-access";
import {ProjectsEffects, projectsFeatureKey, projectsReducer} from "@app-web-central/web/project/data-access";
import {FoldersEffects, foldersFeatureKey, foldersReducer} from "@app-web-central/web/folder/data-access";
import {SuitesEffects, suitesFeatureKey, suitesReducer} from "@app-web-central/web/suite/data-access";
import {TemplatesEffects, templatesFeatureKey, templatesReducer} from "@app-web-central/web/dashboard/data-access";
import {RolesEffects, rolesFeatureKey, rolesReducer} from "@app-web-central/web/role/data-access";
import {
  UsersConfigEffects,
  usersConfigFeatureKey,
  usersConfigReducer, UsersEffects,
  usersFeatureKey, usersReducer
} from "@app-web-central/web/user/data-access";
import {GroupsEffects, groupsFeatureKey, groupsReducer} from "@app-web-central/web/group/data-access";
import {HubsEffects, hubsFeatureKey, hubsReducer} from "@app-web-central/web/hub/data-access";
import {ScopesEffects, scopesFeatureKey, scopesReducer} from "@app-web-central/web/role/data-access";
import {LicensesEffects, licensesFeatureKey, licensesReducer} from "@app-web-central/web/license/data-access";
import {
  AbilitiesEffects,
  abilitiesFeatureKey, abilitiesReducer,
  CategoriesEffects,
  categoriesFeatureKey,
  categoriesReducer,
  InterventionsEffects,
  interventionsFeatureKey,
  interventionsReducer
} from "@app-web-central/web/intervention/data-access";
import {ChannelEffects, channelsFeatureKey, channelsReducer} from "@app-web-central/web/channel/data-access";
import {
  NotificationsEffects,
  notificationsFeatureKey,
  notificationsReducer
} from "@app-web-central/web/notification/data-access";
import {AddressesEffects, addressesFeatureKey, addressesReducer} from "@app-web-central/web/address/data-access";
import {RecipientsEffects, recipientsFeatureKey, recipientsReducer} from "@app-web-central/web/recipient/data-access";

const rootReducers = {
  [hubsFeatureKey]: hubsReducer,
  [tasksFeatureKey]: tasksReducer,
  [usersFeatureKey]: usersReducer,
  [rolesFeatureKey]: rolesReducer,
  [groupsFeatureKey]: groupsReducer,
  [scopesFeatureKey]: scopesReducer,
  [suitesFeatureKey]: suitesReducer,
  [foldersFeatureKey]: foldersReducer,
  [projectsFeatureKey]: projectsReducer,
  [licensesFeatureKey]: licensesReducer,
  [channelsFeatureKey]: channelsReducer,
  [templatesFeatureKey]: templatesReducer,
  [addressesFeatureKey]: addressesReducer,
  [abilitiesFeatureKey]: abilitiesReducer,
  [recipientsFeatureKey]: recipientsReducer,
  [categoriesFeatureKey]: categoriesReducer,
  [usersConfigFeatureKey]: usersConfigReducer,
  [organizationsFeatureKey]: organizationsReducer,
  [interventionsFeatureKey]: interventionsReducer,
  [notificationsFeatureKey]: notificationsReducer,
};

@NgModule({
  imports: [
    StoreModule.forRoot(rootReducers),
    EffectsModule.forRoot([
      TasksEffects,
      UsersEffects,
      HubsEffects,
      RolesEffects,
      SuitesEffects,
      GroupsEffects,
      ScopesEffects,
      FoldersEffects,
      ChannelEffects,
      ProjectsEffects,
      LicensesEffects,
      TemplatesEffects,
      AddressesEffects,
      AbilitiesEffects,
      CategoriesEffects,
      RecipientsEffects,
      UsersConfigEffects,
      OrganizationsEffects,
      InterventionsEffects,
      NotificationsEffects
    ])
  ],
  exports: [],
  providers: []
})
export class WebSharedModule {

}
