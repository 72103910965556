import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfTeam, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class GroupApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public create(group: CfTeam) {
    return this.httpClient.post<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams`, group
    );
  }

  public update(groupId: string, group: CfTeam) {
    return this.httpClient.put<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`, group
    );
  }

  public get(groupId: string) {
    return this.httpClient.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams`
    );
  }

  public delete(groupId: string) {
    return this.httpClient.delete<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}`
    );
  }

  public deleteBulk(groupIds: string[]) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/${groupIds}`
    );
  }

  public enable(groupId: string) {
    return this.httpClient.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}/enable`
    );
  }

  public enableBulk(groupIds: string[]) {
    return this.httpClient.post<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/enable`, groupIds
    );
  }

  public disable(groupId: string) {
    return this.httpClient.get<ResponseState<CfTeam>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/${groupId}/disable`
    );
  }

  public disableBulk(groupIds: string[]) {
    return this.httpClient.post<ResponseState<CfTeam[]>>(
      `${this._appConfig.generalConfigV1Url}manage/teams/bulk/disable`, groupIds
    );
  }

}
