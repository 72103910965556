import {NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder} from "ng-zorro-antd/table";
import {CfTask} from "@app-web-central/web/shared/data-access/models";

export interface ColumnItem<T> {
  title: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<T> | null; // DataItem
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<T> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
  key: any;
  subKey?: string;
}

export class TableUtil {
  public static Configuration = {
    Events: 'EVENT_LIST',
    Recent: 'RECENT_LIST',
    Projects: 'PROJECT_LIST',
    ProjectsAccess: 'PROJECT_ACCESS',
    ProjectsPendingInvitation: 'PROJECT_PENDING_INVITATION',
    Files: 'FILES_LIST',
    Folders: 'FOLDERS_LIST',
  };

  public static doListOfFilter(displayedDataKeys: ColumnItem<any>[], displayedDataSet: any[]): void {
    displayedDataKeys.forEach((column: ColumnItem<CfTask>) => {
      const duplicatedFilters: Set<string> = new Set<string>();

      column.listOfFilter = displayedDataSet.flatMap((task) => {
        let item;
        if (task[column.key]) {
          if (typeof task[column.key] === 'object' && column.subKey) {
            item = ({
              text: task[column.key][column.subKey],
              value: task[column.key][column.subKey],
            });
          } else {
            item = ({
              text: task[column.key],
              value: task[column.key],
            });
          }
          // Use JSON.stringify to handle potential object values and check for duplicates
          const itemString: string = JSON.stringify(item);
          if (!duplicatedFilters.has(itemString)) {
            duplicatedFilters.add(itemString);
            return item;
          }
        }
        return [];
      });
    });
  }

  public static getFilterData(
    key: string,
    subKey: string | undefined,
    tempDataSet: any[],
    list: string[]
  ): any[] {
    let filteredData: any[] = [];
    if (key && subKey) {
      filteredData = tempDataSet
        .filter((item: any) => list.includes(item[key][subKey]));
    } else {
      filteredData = tempDataSet
        .filter((item: any) => list.includes(item[key]));
    }
    return filteredData;
  }
}

export const ListOfColumns: ColumnItem<CfTask>[] = [
  {
    title: 'table.task.status',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.status.localeCompare(b.status),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((status: string) => item.status === status),
    key: 'status'
  },
  {
    title: 'table.task.identifier',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.identifier.localeCompare(b.identifier),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((identifier: string) => item.identifier === identifier),
    key: 'identifier'
  },
  {
    title: 'table.task.address',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.address?.id || '').localeCompare((b.address?.id || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((alias: string) => item.address?.alias.indexOf(alias) !== -1),
    key: 'address',
    subKey: 'alias'
  },
  {
    title: 'table.task.suite',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.suite?.id || '').localeCompare((b.suite?.id || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((room: string) => item.suite?.suiteNumber.indexOf(room) !== -1),
    key: 'suite',
    subKey: 'suiteNumber'
  },
  {
    title: 'table.task.priority',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.priority || '').localeCompare((b.priority || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((priority: string) => item.priority === priority),
    key: 'priority',
  },
  {
    title: 'table.task.assignees',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.assignees?.length || 0) - (b.assignees?.length || 0),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((firstName: string) => item.assignee?.firstName === firstName),
    key: 'assignees',
    subKey: 'firstName',
  },
  {
    title: 'table.task.tags',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.tags?.length || 0) - (b.tags?.length || 0),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((firstName: string) => item.assignee?.firstName === firstName),
    key: 'tags',
  },
  {
    title: 'table.task.dateAdd',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.dateAdd - b.dateAdd,
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: number[], item: CfTask) => list.some((dateAdd: number) => item.dateAdd === dateAdd),
    key: 'dateAdd'
  },
  {
    title: 'table.task.deliveryDate',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.dates.deliveryDate - b.dates.deliveryDate,
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: number[], item: CfTask) => list.some((dueDate: number) => item.dates.deliveryDate === dueDate),
    key: 'dates',
    subKey: 'deliveryDate',
  }
];

export const ListOfPreventiveColumns: ColumnItem<CfTask>[] = [
  {
    title: 'table.task.schedule',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.recurrence?.id.localeCompare(b.recurrence?.id),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((recurrence: string) => item.recurrence?.id === recurrence),
    key: 'recurrence'
  },
  {
    title: 'table.task.lastOrder',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.dateAdd - b.dateAdd,
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: number[], item: CfTask) => list.some((dateAdd: number) => item.dateAdd === dateAdd),
    key: 'dateAdd'
  },
  {
    title: 'table.task.nextOrder',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.dates.deliveryDate - b.dates.deliveryDate,
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: number[], item: CfTask) => list.some((dueDate: number) => item.dates.deliveryDate === dueDate),
    key: 'dates',
    subKey: 'deliveryDate',
  },
  {
    title: 'table.task.identifier',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => a.identifier.localeCompare(b.identifier),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((identifier: string) => item.identifier === identifier),
    key: 'identifier'
  },
  {
    title: 'table.task.address',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.address?.id || '').localeCompare((b.address?.id || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((alias: string) => item.address?.alias.indexOf(alias) !== -1),
    key: 'address',
    subKey: 'alias'
  },
  {
    title: 'table.task.suite',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.suite?.id || '').localeCompare((b.suite?.id || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((room: string) => item.suite?.suiteNumber.indexOf(room) !== -1),
    key: 'suite',
    subKey: 'suiteNumber'
  },
  {
    title: 'table.task.priority',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.priority || '').localeCompare((b.priority || '')),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((priority: string) => item.priority === priority),
    key: 'priority',
  },
  {
    title: 'table.task.assignees',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.assignees?.length || 0) - (b.assignees?.length || 0),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((firstName: string) => item.assignee?.firstName === firstName),
    key: 'assignees',
    subKey: 'firstName',
  },
  {
    title: 'table.task.tags',
    sortOrder: null,
    sortFn: (a: CfTask, b: CfTask) => (a.tags?.length || 0) - (b.tags?.length || 0),
    sortDirections: ['ascend', 'descend', null],
    filterMultiple: true,
    listOfFilter: [],
    filterFn: (list: string[], item: CfTask) => list.some((firstName: string) => item.assignee?.firstName === firstName),
    key: 'tags',
  }
];
