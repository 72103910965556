import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {
  CfRole,
  ResponseState,
  CfDeviceInformation,
  CfUserConfiguration,
} from "@app-web-central/web/shared/data-access/models";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

export interface CfPassword {
  accountId: string;
  password: string;
  confirmPassword: string;
}

@Injectable({ providedIn: 'root' })
export class UserApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  get(userId: string) {
    return this.httpClient.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`
    );
  }

  getByUsername(username: string) {
    return this.httpClient.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/read-by?email=${username}`
    );
  }

  list() {
    return this.httpClient.get<ResponseState<CfUser[]>>(
      `${this._appConfig.usersV1Url}manage/users`
    );
  }

  listRoles() {
    return this.httpClient.get<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles`
    );
  }

  listUsersConfig() {
    return this.httpClient.get<ResponseState<CfUserConfiguration[]>>(
      `${this._appConfig.localDataUrl}/user-config.json`
    );
  }

  bulk(userIds: string[]) {
    return this.httpClient.post<ResponseState<CfUser[]>>(
      `${this._appConfig.usersV1Url}manage/users/batch`, userIds
    );
  }

  create(user: CfUser) {
    return this.httpClient.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users`, user
    );
  }

  update(userId: string, user: CfUser) {
    return this.httpClient.put<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`, user
    );
  }

  updateMe(userId: string, user: CfUser) {
    return this.httpClient.put<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/me`, user
    );
  }

  updateStatus(userId: string, user: CfUser) {
    return this.httpClient.put<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/status`, user
    );
  }

  updateAsAway(userId: string) {
    return this.httpClient.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/away`
    );
  }

  updatePassword(password: CfPassword) {
    return this.httpClient.put<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/users/change-password`, password
    );
  }

  activate(userId: string, deviceInfo: CfDeviceInformation) {
    return this.httpClient.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/activate`, deviceInfo
    );
  }

  isAlive(userId: string, sessionId: string) {
    return this.httpClient.get<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/is-alive/${sessionId}`
    );
  }

  deactivate(userId: string, deviceInfo: CfDeviceInformation) {
    return this.httpClient.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/deactivate`, deviceInfo
    );
  }

  login(userId: string, deviceInfo: CfDeviceInformation) {
    return this.httpClient.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/login`, deviceInfo
    );
  }
  logout(userId: string, deviceInfo: CfDeviceInformation) {
    return this.httpClient.post<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/logout`, deviceInfo
    );
  }

  enable(userId: string) {
    return this.httpClient.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/enable`
    );
  }

  disable(userId: string) {
    return this.httpClient.get<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}/disable`
    );
  }

  delete(userId: string) {
    return this.httpClient.delete<ResponseState<CfUser>>(
      `${this._appConfig.usersV1Url}manage/users/${userId}`
    );
  }

}
