<div class="loader-wrapper relative">
<!--  <div class="loader-logo">-->
<!--    <img class="h-28 w-auto shadow-xl" src="./assets/images/logos/logo-purple-1024x1024.svg" alt="App Logo">-->
<!--  </div>-->
  <div class="loader-inner">
    <div class="text-center">
      <div>
        <h3>Quantum CMMS. -</h3>
      </div>
      <span #textElement class="animate-text"></span>
      <span #blinkElement class="animate-text cursor"></span>
    </div>
  </div>
</div>
