<div>
  <h2 class="pb-2 text-sm font-sans text-gray-900">
    {{ 'task_detail.suite' | translate }}
  </h2>

  <div class="w-full">
    <nz-select
      *ngIf="address.suites && address.suites.length > 0"
      [formControl]="control"
      nzPlaceHolder="{{ 'task_detail.add_suite' | translate }}"
      nzShowSearch
      [compareWith]="compareFn"
      [nzDisabled]="!!task.dates?.validationDate"
    >
      <nz-option
        nzCustomContent
        *ngFor="let suite of address.suites"
        nzLabel="{{suite.suiteNumber}} - {{suite.type}}"
        [nzValue]="suite"
      >
        {{suite.suiteNumber}} - {{suite.type}}
      </nz-option>
    </nz-select>
  </div>

</div>
