import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {LicenseApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadLicenses, loadLicensesError, loadLicensesSuccess} from "./licenses.actions";

@Injectable({ providedIn: 'root' })
export class LicensesEffects {

  public loadLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLicenses),
      switchMap(() => this.licenseApi.list()
        .pipe(
          map((response) => loadLicensesSuccess({ licenses: response.payload })),
          catchError((error) => [loadLicensesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private licenseApi: LicenseApi
  ) { }
}
