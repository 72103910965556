import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  assigneesManyTask,
  assigneesManyTaskError,
  assigneesManyTaskSuccess,
  assigneesTask,
  assigneesTaskError,
  assigneesTaskSuccess,
  createTask,
  createTaskError,
  createTaskSuccess,
  disableBulkTask,
  disableBulkTaskError,
  disableBulkTaskSuccess,
  disableTask,
  disableTaskError,
  disableTaskSuccess,
  enableBulkTask,
  enableBulkTaskError,
  enableBulkTaskSuccess,
  enableTask,
  enableTaskError,
  enableTaskSuccess,
  loadTasks,
  loadTasksError,
  loadTasksSuccess,
  removeBulkTask,
  removeBulkTaskError,
  removeBulkTaskSuccess,
  updateTask,
  updateTaskError,
  updateTaskSuccess
} from "./tasks.actions";
import {catchError, map, of, switchMap, withLatestFrom} from "rxjs";
import {TaskApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {select, Store} from "@ngrx/store";
import {RecipientsFacade} from "@app-web-central/web/recipient/data-access";
import {getUsers} from "@app-web-central/web/user/data-access";
import {mergeMap} from "rxjs/operators";
import {getTasks} from "./tasks.selectors";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";
import {ERROR, SUCCESS} from "@app-web-central/web/shared/services/local-notification";
import {SuitesFacade} from "@app-web-central/web/suite/data-access";

@Injectable({ providedIn: 'root' })
export class TasksEffects {
  users$ = this._store.pipe(select(getUsers));
  suites$ = this._suitesFacade.suites$;
  addresses$ = this._addressesFacade.addresses$;
  recipients$ = this._recipientsFacade.recipients$;

  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTasks),
      switchMap(() =>
        this._taskApi.list()
          .pipe(
            map((response) => loadTasksSuccess({ tasks: response.payload })),
            catchError((error) => of(loadTasksError(error)))
          )
        )
      )
  );

  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{task}, tasks]) => this._taskApi.create(task)
        .pipe(
          map((response) => {
            let newTasks = tasks;
            if (tasks) {
              newTasks = [...tasks, ...[response.payload]];
            }
            this._notification.create(
              SUCCESS,
              this._translate.instant('notifications.creation.success'),
              this._translate.instant('task_detail.notifications.creation.success')
            );
            return createTaskSuccess({ tasks: newTasks } );
          }),
          catchError((error) => {
            this._notification.create(
              ERROR,
              this._translate.instant('notifications.creation.error'),
              this._translate.instant('task_detail.notifications.creation.error')
            );
            return [createTaskError(error)]
          })
        )
      )
    ), { dispatch: true }
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTask),
      mergeMap(({ task }) => this._store.select(getTasks)
        .pipe(
          map((tasks) => {
            const newTasks = tasks !== null ? [...tasks] : [];
            const index = newTasks.findIndex(item => item.id === task.id);
            newTasks[index] = { ...task };
            return updateTaskSuccess({ tasks: newTasks });
          }),
          catchError((error) => {
            this._notification.create(
              ERROR,
              this._translate.instant('notifications.update.error'),
              this._translate.instant('task_detail.notifications.update.error')
            );
            return [updateTaskError(error)]
          })
        )
      )
    ), { dispatch: false }
  );

  assigneesTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assigneesTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([assignees, tasks]) => {
        return this._taskApi.assignees(assignees)
          .pipe(
            map((response) => {
              let newTasks = tasks;
              if (tasks) {
                newTasks = [...tasks];
              }
              response.payload.forEach(newTask => {
                const index = tasks?.findIndex(item => item.id === newTask.id);
                if (index && newTasks) {
                  newTasks[index] = newTask;
                }
              })
              this._notification.create(
                SUCCESS,
                this._translate.instant('notifications.assignees.success'),
                this._translate.instant('task_detail.notifications.assignees.success')
              );
              return assigneesTaskSuccess({ tasks: newTasks });
            }),
            catchError((error) => {
              this._notification.create(
                ERROR,
                this._translate.instant('notifications.assignees.error'),
                this._translate.instant('task_detail.notifications.assignees.error')
              );
              return [assigneesTaskError(error)]
            })
          )
        }
      )
    ), { dispatch: true }
  );

  assigneesManyTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assigneesManyTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{ assignees }, tasks]) => {
          return this._taskApi.assigneeMany(assignees)
            .pipe(
              map((response) => {
                let newTasks = tasks;
                if (tasks) {
                  newTasks = [...tasks];
                }
                response.payload.forEach(newTask => {
                  const index = tasks?.findIndex(item => item.id === newTask.id);
                  if (index && newTasks) {
                    newTasks[index] = newTask;
                  }
                });
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('notifications.assignees.success'),
                  this._translate.instant('task_detail.notifications.assignees.success')
                );
                return assigneesManyTaskSuccess({ tasks: newTasks });
              }),
              catchError((error) => {
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.assignees.error'),
                  this._translate.instant('task_detail.notifications.assignees.error')
                );
                return [assigneesManyTaskError(error)]
              })
            )
        }
      )
    ), { dispatch: true }
  );

  enableTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enableTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{taskId}, tasks]) => {
          return this._taskApi.enable(taskId)
            .pipe(
              map((response) => {
                let newTasks = tasks;
                if (tasks) {
                  newTasks = [...tasks];
                  const index = tasks?.findIndex(item => item.id === taskId);
                  newTasks[index] = response.payload;
                }
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('alerts.enabled'),
                  ''
                );
                return enableTaskSuccess({ tasks: newTasks } );
              }),
              catchError((error) => {
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.enabled.error'),
                  error.error.error.messages[0]
                );
                return [enableTaskError(error)]
              })
            )
        }
      )
    ), { dispatch: true }
  );

  enableBulkTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enableBulkTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{ taskIds }, tasks]) => {
          return this._taskApi.enableBulk(taskIds)
            .pipe(
              map((response) => {
                let newTasks = tasks;
                if (tasks) {
                  newTasks = [...tasks];
                  response.payload.forEach(task => {
                    const index = newTasks?.findIndex(item => item.id === task.id);
                    if (newTasks && index) {
                      newTasks[index] = task;
                    }
                  });
                }
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('alerts.enabled'),
                  ''
                );
                return enableBulkTaskSuccess({ tasks: newTasks } );
              }),
              catchError((error) => {
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.enabled.error'),
                  error.error.error.messages[0]
                );
                return [enableBulkTaskError(error)]
              })
            )
        }
      )
    ), { dispatch: true }
  );

  disableTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{taskId}, tasks]) => {
          return this._taskApi.disable(taskId)
            .pipe(
              map((response) => {
                let newTasks = tasks;
                if (tasks) {
                  newTasks = [...tasks];
                  const index = tasks?.findIndex(item => item.id === taskId);
                  newTasks[index] = response.payload;
                }
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('alerts.disabled'),
                  ''
                );
                return disableTaskSuccess({ tasks: newTasks } );
              }),
              catchError((error) => {
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.disabled.error'),
                  error.error.error.messages[0]
                );
                return [disableTaskError(error)]
              })
            )
        }
      )
    ), { dispatch: true }
  );

  disableBulkTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableBulkTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{ taskIds }, tasks]) => {
          return this._taskApi.disableBulk(taskIds)
            .pipe(
              map((response) => {
                let newTasks = tasks;
                if (tasks) {
                  newTasks = [...tasks];
                  response.payload.forEach(task => {
                    const index = newTasks?.findIndex(item => item.id === task.id);
                    if (newTasks && index) {
                      newTasks[index] = task;
                    }
                  });
                }
                this._notification.create(
                  SUCCESS,
                  this._translate.instant('alerts.disabled'),
                  ''
                );
                return disableBulkTaskSuccess({ tasks: newTasks } );
              }),
              catchError((error) => {
                this._notification.create(
                  ERROR,
                  this._translate.instant('notifications.disabled.error'),
                  error.error.error.messages[0]
                );
                return [disableBulkTaskError(error)]
              })
            )
        }
      )
    ), { dispatch: true }
  );

  removeBulkTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeBulkTask),
      withLatestFrom(this._store.select(getTasks)),
      switchMap(([{ taskIds }, tasks]) => this._taskApi.deleteBulk(taskIds)
        .pipe(
          map(response => {
            let newTasks = tasks;
            if (tasks) {
              newTasks = [...tasks];
              taskIds.forEach(taskId => {
                const index = newTasks?.findIndex(item => item.id === taskId);
                if (index) {
                  newTasks?.splice(index, 1);
                }
              });
            }
            this._notification.create(
              SUCCESS,
              this._translate.instant('notifications.delete.success'),
              response.payload
            );
            return removeBulkTaskSuccess({ tasks: newTasks });
          }),
          catchError((error) => {
            this._notification.create(
              ERROR,
              this._translate.instant('notifications.delete.error'),
              this._translate.instant('notifications.delete.message_error')
            );
            return [removeBulkTaskError(error)];
          })
        )
      )
    )
  );

  constructor(
    private _store: Store,
    private actions$: Actions,
    private _taskApi: TaskApi,
    private _suitesFacade: SuitesFacade,
    private _translate: TranslateService,
    private _addressesFacade: AddressesFacade,
    private _recipientsFacade: RecipientsFacade,
    private _notification: NzNotificationService
  ) { }
}
