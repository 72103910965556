import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {map, Observable} from "rxjs";
import {getUsers, getUsersLoading} from "./users.selectors";
import {UsersState} from "./users.reducer";
import {UserStore} from "../user";
import {CfUser} from "@app-web-central/web/shared/data-access/models";
import {loadUsers} from "./users.actions";

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getUsersLoading));
  public readonly users$: Observable<CfUser[] | null>
    = this.store.pipe(select(getUsers));

  public usersTenant$ = this.users$.pipe(
    map(users => users ? users.filter(user => !user.isBuiltIn) : [])
  );

  public usersEnabled$: Observable<CfUser[]> = this.users$.pipe(
    map((users: CfUser[] | null) => users
      ? users.filter((user: CfUser) => user.enabled && !user.isBuiltIn)
      : []
    )
  );

  public usersEnabledForSelect$ = this.users$.pipe(
    map(users => users?.filter(user => user.enabled && !user.isBuiltIn).map((user) => ({
      label: user.fullName,
      value: user,
    })))
  );

  public readonly usersEnabledAndAgent$ = this.users$.pipe(
    map((users) => users !== null
      ? users.filter(user => user.enabled && user.enabledAgent && !user.isBuiltIn)
      : [])
  );

  constructor(
    private userStore: UserStore,
    private store: Store<UsersState>
  ) { }

  public loadUsers(): void {
    this.store.dispatch(loadUsers());
  }

  public setAsAway(user: CfUser): void {
    this.userStore.updateAsAway(user.id);
  }
}
