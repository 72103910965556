import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationItemComponent} from './notification-item.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {DateAgoPipeModule} from "@app-web-central/web/shared/pipes/date-ago";
import {TranslateModule} from "@ngx-translate/core";
import {BadgeModule} from "@app-web-central/web/shared/ui/badge";

@NgModule({
  imports: [
    IconModule,
    BadgeModule,
    CommonModule,
    TranslateModule,
    DateAgoPipeModule,
  ],
  declarations: [
    NotificationItemComponent
  ],
  exports: [
    NotificationItemComponent
  ],
})
export class NotificationItemModule {
}
