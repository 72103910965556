<div class="mt-3">
  <h2 class="pb-2 text-sm font-sans text-gray-900">
    {{ 'task_detail.reminder' | translate }}
  </h2>

  <div class="flex items-center">
    <label
      nz-checkbox
      class="ml-1"
      [nzDisabled]="!!task.dates?.validationDate"
      [formControl]="control"
      (ngModelChange)="handleOnAddReminder($event)">
      {{ 'task_detail.reminder' | translate }}
    </label>

    <div
      *ngIf="task && task.isRecurring"
      class="reminder uppercase text-slate-400 text-sm">
      <span class="reminder-rrule">Notification le 12/09/2022 à 12h00</span>
    </div>
  </div>

</div>

