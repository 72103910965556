import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NavItemWithIconAndUrl} from "@app-web-central/web/shared/data-access/models";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {BehaviorSubject} from "rxjs";
import {AuthFacade} from "@app-web-central/web/auth/data-access";


@Component({
  selector: 'as-nav-bar-aside-secondary',
  templateUrl: './nav-bar-aside-secondary.component.html',
  styleUrls: ['./nav-bar-aside-secondary.component.scss'],
})
export class NavBarAsideSecondaryComponent {
  @Input() items!: NavItemWithIconAndUrl[];
  @Input() expanded$!: BehaviorSubject<boolean>;
  @Output() manualToggle: EventEmitter<void> = new EventEmitter();

  get sidebarWidth(): number {
    return this.expanded$.getValue() ? 16 : 4;
  }
  constructor(
    public authFacade: AuthFacade,
    public organizationFacade: OrganizationsFacade
  ) { }

  public toggle(): void {
    this.manualToggle.emit();
  }

  public handleRouteToOrganization(): string {
    return RouteUtil.getGeneralSettingsRouteUrl();
  }

  public handleRouteToSubscription(): string {
    return RouteUtil.getSubscriptionRouteUrl();
  }

  public handleRouteToCompliance(): string {
    return RouteUtil.getComplianceRouteUrl();
  }

}
