import {ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  CfProject,
  CfTask,
  CfUserNotification,
  NotificationReferenceEnum,
  NotificationsDisplayIcons
} from "@app-web-central/web/shared/data-access/models";
import {TaskDrawerComponent} from "@app-web-central/web/task/ui/task-drawer";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Router} from "@angular/router";
import {TaskStore} from "@app-web-central/web/task/data-access";
import {ProjectStore} from "@app-web-central/web/project/data-access";
import {NotificationsFacade} from "@app-web-central/web/notification/data-access";

@UntilDestroy()
@Component({
  selector: 'as-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskStore]
})
export class NotificationItemComponent {
  @Input() notification!: CfUserNotification;
  @Input() index!: number;
  @Input() length!: number;
  @ViewChild("TaskTitleTmpl") TaskTitleTmpl!: TemplateRef<any>;
  task!: CfTask;
  project!: CfProject;

  constructor(
    private _router: Router,
    private _taskStore: TaskStore,
    private _projectStore: ProjectStore,
    private _drawerService: NzDrawerService,
    private notificationsFacade: NotificationsFacade
  ) { }

  get icon() {
    const referenceKey = this.notification.referenceKey as NotificationReferenceEnum;
    return NotificationsDisplayIcons[referenceKey];
  }

  readNotification(notificationId: string) {
    this.notificationsFacade.readOneNotification(notificationId);
  }

  onOpenTaskDrawer(taskId: string, notificationId: string) {
    this._taskStore.taskById$(taskId)
      .pipe(untilDestroyed(this))
      .subscribe((task) => {
        if (task) {
          this.task = task;
          this._router.navigate([], {
              queryParams: { taskId }, queryParamsHandling: 'merge'
            }
          );
          this._projectStore.projectById$(task.projectId)
            .pipe(untilDestroyed(this))
            .subscribe((project) => {
              if (project) {
                this.readNotification(notificationId);
                this.project = project;
                const drawer = this._drawerService.create({
                  nzContent: TaskDrawerComponent,
                  nzContentParams: {
                    project: this.project
                  },
                  nzTitle: this.TaskTitleTmpl,
                  nzWidth: '100%',
                  nzClosable: true,
                  nzWrapClassName: 'nz-task-drawer',
                  nzBodyStyle: { padding: 0 }
                });
                drawer.afterClose
                  .pipe(untilDestroyed(this))
                  .subscribe(() => {
                    this._router.navigate([], {
                      queryParams: {taskId: null},
                      queryParamsHandling: 'merge'
                    });
                  });
              } else {
                console.error("Error. No project found for this task.");
              }
            });
        }
      });
  }
}


