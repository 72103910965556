import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfReminder, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class ReminderApi {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  get(reminderId: string) {
    return this._http.get<ResponseState<CfReminder>>(
      `${this._appConfig.reminderV1Url}manage/routines/reminders/${reminderId}`
    );
  }

  list() {
    return this._http.get<ResponseState<CfReminder[]>>(
      `${this._appConfig.reminderV1Url}manage/routines/reminders`
    );
  }

  create(reminder: CfReminder, type: string) {
    return this._http.post<ResponseState<CfReminder>>(
      `${this._appConfig.reminderV1Url}manage/routines/reminders`, {routine: reminder, routineType: type}
    );
  }

  update(reminderId: string, reminder: CfReminder, type: string) {
    return this._http.put<ResponseState<CfReminder>>(
      `${this._appConfig.reminderV1Url}manage/routines/reminders/${reminderId}`, {
        routine: reminder,
        routineType: type
      });
  }

  delete(reminderId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.reminderV1Url}manage/routines/reminders/${reminderId}`
    );
  }

}
