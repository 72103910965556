<div class="flex h-full">
  <as-task-drawer-sidebar
    [countComments]="countComments"
    (selectedMenu)="onChangeSelectedMenu($event)">
  </as-task-drawer-sidebar>

  <as-dropzone
    (selectedFiles)="onFilesSelected($event)">
  </as-dropzone>

  <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
    <main class="flex flex-1 overflow-hidden">
      <section class="border-l border-gray-200 flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
        <form [formGroup]="taskForm" (ngSubmit)="submit()">
          <div>
            <div class="px-4 pt-6 sm:px-6 lg:max-w-screen-2xl lg:px-8">

              <ng-container
                *ngIf="task$ | async then contentRef else skeletonRef"
                [ngTemplateOutlet]="contentRef" [ngTemplateOutletContext]="{ task: task$ | async }">
              </ng-container>

              <ng-template #contentRef let-task>
                <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                  <!-- Left Side -->
                  <div class="pb-8">

                    <ng-container *ngIf="selectedMenu.label === 'constants.details'">
                      <as-task-drawer-name
                        [task]="task"
                        [control]="f('name')">
                      </as-task-drawer-name>

                      <div class="flex flex-col mt-3">
                        <div class="grid gap-2 grid-cols-2 grid-rows-1 w-full">
                          <ng-container *ngIf="projectStore.usersEnabledAsAssigneeByProject$(project.id) | async as assignees">
                            <div>
                              <h2 class="pb-2 text-sm font-sans text-gray-900">
                                {{ 'task_detail.assignee' | translate }}
                              </h2>

                              <nz-select
                                class="multi-selection assignees"
                                nzAllowClear
                                nzMode="multiple"
                                [nzMaxTagCount]="3"
                                [nzMaxTagPlaceholder]="tagPlaceHolder"
                                [nzNotFoundContent]="noUserFoundTmpl"
                                [nzCustomTemplate]="assigneesTpl"
                                [nzDisabled]="!!task.dates?.validationDate"
                                [compareWith]="compareFn"
                                nzPlaceHolder="{{ 'task_detail.add_assignee' | translate }}"
                                formControlName="assignees"
                              >
                                <nz-option
                                  *ngFor="let item of assignees"
                                  [nzLabel]="item.assigneeFullName"
                                  [nzValue]="item">
                                </nz-option>
                              </nz-select>
                              <small class="mt-2 flex items-center">
                                <svg-icon key="mini/question-circle" class="mr-1" size="xs"></svg-icon>
                                {{ 'add_task.task_assignees_optional' | translate }}
                              </small>
                            </div>
                          </ng-container>

                          <div>
                            <h2 class="pb-2 text-sm font-sans text-gray-900">
                              {{ 'task_detail.priority' | translate }}
                            </h2>

                            <as-task-drawer-priority
                              class="col-span-1"
                              [task]="task"
                              [control]="f('priority')">
                            </as-task-drawer-priority>
                          </div>

                        </div>
                      </div>

                      <as-task-drawer-dates
                        *ngIf="session$ | async as session"
                        [task]="task"
                        [session]="session"
                        [control]="f('dates')"
                        [isRruleControl]="f('isRecurring')"
                        [rruleControl]="f('recurrence')">
                      </as-task-drawer-dates>

                      <div class="grid gap-2 grid-cols-2 grid-rows-1 w-full mt-3">
                        <as-task-drawer-address
                          *ngIf="addresses$ | async as addresses"
                          [task]="task"
                          [control]="f('address')"
                          [addresses]="addresses">
                        </as-task-drawer-address>

                        <ng-container *ngIf="f('address').value">
                          <ng-container *ngIf="f('address').value.suites">
                            <as-task-drawer-suite
                              [task]="task"
                              [control]="f('suite')"
                              [address]="f('address').value">
                            </as-task-drawer-suite>
                          </ng-container>
                        </ng-container>

                      </div>

                      <as-task-drawer-description
                        [task]="task"
                        [control]="f('description')">
                      </as-task-drawer-description>

                      <as-task-drawer-tags
                        [task]="task"
                        [control]="f('tags')">
                      </as-task-drawer-tags>
                    </ng-container>

                    <ng-container *ngIf="selectedMenu.label === 'constants.comments'">
                      <as-task-drawer-comments
                        *ngIf="session$ | async as session"
                        [task]="task"
                        [session]="session">
                      </as-task-drawer-comments>
                    </ng-container>

                  </div>

                  <!-- Right side -->
                  <div class="mt-10 lg:mt-0">
                    <div class="flex items-center mt-2 mb-6">
                      <as-button
                        size="sm"
                        type="submit"
                        variant="twotone"
                        [isWorking]="isWorking$ | async"
                        [disabled]="taskForm.invalid"
                      >
                        {{ 'buttons.save' | translate }}
                      </as-button>
                      <as-button
                        size="sm"
                        class="ml-2"
                        variant="default"
                        (click)="cancel()"
                      >
                        {{ 'buttons.cancel' | translate }}
                      </as-button>

                      <div class="flex-auto"></div>

                      <as-button
                        size="sm"
                        variant="default"
                        nz-dropdown
                        nzTrigger="click"
                        [nzDropdownMenu]="status"
                        [nzDisabled]="!!task.dates?.validationDate"
                        class="uppercase text-gray-500 text-sm"
                      >
                        {{ 'task_detail.status.' + task?.status?.toLowerCase() | translate }}
                      </as-button>

                      <as-button
                        class="ml-2"
                        size="sm"
                        icon="outline/eye"
                        nz-dropdown
                        nz-tooltip
                        variant="default"
                        [iconOnly]="true"
                        nzTooltipTitle="{{ 'tooltips.watch' | translate }}"
                        nzTooltipPlacement="bottom"
                        [nzTrigger]="'click'"
                        nzPlacement="bottomRight"
                        [nzDropdownMenu]="watch"
                      >
                      </as-button>

<!--                      [hasBadge]="true"-->
<!--                      [countValue]="subscribers(task.channel)"-->

                      <as-button
                        size="sm"
                        class="ml-2"
                        *asHasPermission="['task:create', 'task:edit']"
                        icon="outline/square-2-stack"
                        variant="default"
                        [iconOnly]="true"
                        [disabled]="!!task.dates?.validationDate"
                        nz-tooltip
                        nzTooltipTitle="{{ 'tooltips.clone' | translate }}"
                        nzTooltipPlacement="bottom"
                        (click)="onClone(task)">
                      </as-button>

                      <as-button
                        class="ml-2"
                        nz-dropdown
                        size="sm"
                        variant="default"
                        icon="solid/dots-horizontal"
                        [iconOnly]="true"
                        [nzTrigger]="'click'"
                        [nzDropdownMenu]="menu">
                      </as-button>
                    </div>

                    <div class="mt-24">
                      <nz-tabset class="pt-4 pb-2">
                        <nz-tab nzTitle="{{ 'task_detail.interventions' | translate }}">
                          <div class="p-1">
                            <as-task-drawer-interventions
                              [task]="task"
                              [control]="f('interventions')"
                              [interventions]="interventions$ | async">
                            </as-task-drawer-interventions>
                          </div>
                        </nz-tab>
                        <nz-tab nzTitle="{{ 'task_detail.metadata' | translate }}">
                          <div class="p-1">
                            <as-task-drawer-metadata
                              (removeMetadata)="onRemoveMetadata($event)"
                              (editMetadata)="onEditMetadata($event)"
                              [control]="f('metadata')">
                            </as-task-drawer-metadata>
                            <div *ngIf="openMetadataForm">
                              <as-task-drawer-metadata-add
                                [controlKey]="f('metadataKey')"
                                [controlValue]="f('metadataValue')"
                                (metadataChanged)="onAddMetadata($event)">
                              </as-task-drawer-metadata-add>
                            </div>
                            <div class="mt-3 flex">
                              <as-button
                                icon="mini/plus"
                                variant="default"
                                (click)="onOpenMetadataForm()"
                                [disabled]="!!task.dates?.validationDate"
                              >
                                {{ 'task_detail.add_metadata' | translate }}
                              </as-button>
                            </div>
                          </div>
                        </nz-tab>
                        <nz-tab nzTitle="{{ 'task_detail.timeline' | translate }}">
                          <as-task-drawer-steps
                            [task]="task"
                            [steps]="task.steps">
                          </as-task-drawer-steps>
                        </nz-tab>
                        <nz-tab nzTitle="{{ 'task_detail.documents' | translate }} ({{!!f('attachments')?.value.length ? f('attachments')?.value.length : 0}})">
                          <as-task-drawer-documents
                            *ngIf="session$ | async as session"
                            [task]="task"
                            [filesDropped]="filesLoaded"
                            [folder]="'task'"
                            [session]="session"
                            [control]="f('attachments')">
                          </as-task-drawer-documents>
                        </nz-tab>

                        <nz-tab nzTitle="Updates">
                          <div class="flex flex-col">
                            <small
                              class="text-sm text-gray-500"
                              nz-tooltip
                              nzTooltipTitle="{{ task.dateAdd | date: 'medium' : '' : translate.currentLang }}"
                              nzTooltipPlacement="topLeft">
                              {{ 'task_detail.created' | translate }} {{ task.dateAdd | dateAgo }} par {{ task.createdBy?.fullName }}
                            </small>
                            <small
                              class="text-sm text-gray-500"
                              nz-tooltip
                              nzTooltipTitle="{{ task.dateUpd | date: 'medium' : '' : translate.currentLang }}"
                              nzTooltipPlacement="topLeft">
                              {{ 'task_detail.updated' | translate }} {{ task.dateUpd | dateAgo }} par {{ task.modifiedBy?.fullName }}
                            </small>
                          </div>
                        </nz-tab>
                      </nz-tabset>
                    </div>

                  </div>
                </div>

                <nz-dropdown-menu #status="nzDropdownMenu">
                  <ul nz-menu class="dropdown-menu">
                    <ng-container *ngFor="let state of statuses; let i = index">
                      <li nz-menu-item class="dropdown-item" (click)="onChangeStatus(task, i)">
                        <div class="flex items-center">
                          <span class="ml-3 font-sans text-gray-800 text-sm">
                            {{ 'task_detail.status.' + state.value.toLowerCase() | translate }}
                          </span>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </nz-dropdown-menu>

                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ng-container nz-menu>
                    <ul class="dropdown-menu" nz-menu>
                      <li class="dropdown-item" nz-menu-item (click)="onStart(task)"
                          *asHasPermission="['task:edit']"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.start' | translate }}
                      </li>
                      <li class="dropdown-item" nz-menu-item (click)="onStop(task)"
                          *asHasPermission="['task:edit']"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.stop' | translate }}
                      </li>
                      <li class="dropdown-item" nz-menu-item (click)="onValidate(task)"
                          *asHasPermission="['task:edit']"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.validate' | translate }}
                      </li>
                      <li class="dropdown-item" nz-menu-item (click)="onDelete(task)"
                          *asHasPermission="['task:delete']"
                          [ngClass]="{'disabled': task.dates?.validationDate}">
                        {{ 'buttons.delete' | translate }}
                      </li>
                    </ul>
                  </ng-container>
                </nz-dropdown-menu>

                <nz-dropdown-menu #watch="nzDropdownMenu">
                  <ng-container nz-menu>
                    <ul class="dropdown-menu" nz-menu *ngIf="session$ | async as session">
                      <li class="dropdown-item" nz-menu-item
                          (click)="onStartWatching(task, session.id)">
                        <div class="flex items-center">
                          <svg-icon
                            key="outline/eye">
                          </svg-icon>
                          <span class="ml-1-5 mr-1-5">{{ 'task_detail.start_watching' | translate }}</span>
                        </div>
                      </li>
                      <li class="dropdown-item" nz-menu-item
                          (click)="onStopWatching(task, session.id)">
                        <div class="flex items-center">
                          <svg-icon
                            key="outline/eye-off">
                          </svg-icon>
                          <span class="ml-1-5 mr-1-5">{{ 'task_detail.stop_watching' | translate }}</span>
                        </div>
                      </li>
                      <li nz-menu-divider></li>
                      <li nz-menu-item>
                        <span class="text-sm text-slate-400 uppercase">{{ 'task_detail.who_watching' | translate }}</span>
                      </li>
                      <ng-container *ngIf="task.channel && task.channel.subscribers">
                        <li class="dropdown-item" nz-menu-item *ngFor="let subscriber of task.channel.subscribers">
                          <div class="flex items-center" *ngIf="!subscriber.unsubscribed">
                            <as-user [user]="subscriber"></as-user>
                            <svg-icon
                              *ngIf="session.id === subscriber.subscriberId"
                              (click)="onStopWatching(task, subscriber.subscriberId)"
                              key="outline/x-mark">
                            </svg-icon>
                          </div>
                        </li>
                        <li class="dropdown-item" nz-menu-item *ngIf="task.channel.subscribers.length < 1">
                          <div class="flex items-center">
                            <svg-icon
                              key="mini/magnifying-glass">
                            </svg-icon>
                            <span class="ml-1-5 mr-1-5 text-sm text-gray-500">{{ 'task_detail.no_watchers' | translate }}</span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </nz-dropdown-menu>

              </ng-template>

              <ng-template #skeletonRef>
                <div class="flex w-full flex-wrap pb-16">
                  <div class="sm:w-full md:w-7/12 lg:w-4/6 pr-10">
                    <nz-skeleton class="my-4"></nz-skeleton>
                    <nz-skeleton class="mt-4"
                                 [nzAvatar]="true"
                                 [nzParagraph]="{ rows: 2 }">
                    </nz-skeleton>
                  </div>
                  <div class="sm:w-full md:w-5/12 lg:w-2/6 pt-1">
                    <nz-skeleton class="my-4"></nz-skeleton>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </form>
      </section>
    </main>
  </div>
</div>

<ng-template #tagPlaceHolder let-selectedList>
  {{ 'constants.and_more' | translate: { param: selectedList.length } }}
</ng-template>

<ng-template #assigneesTpl let-selected>
  <div class="ant-select-selection-item-content">
    <as-user
      [user]="selected.nzValue"
      fullNameKey="assigneeFullName"
      imageUrlKey="assigneeImageUrl"
      [imageOnly]="true">
    </as-user>
  </div>
</ng-template>

<ng-template #noUserFoundTmpl>
  {{ 'add_task.assignee_not_found' | translate }}
</ng-template>

<ng-template #cancelNotificationTpl let-notification>
  <div class="ant-notification-notice-content">
    <div>
      <div class="ant-notification-notice-message">
        {{ 'task_detail.messages.cancel.title' | translate }}
      </div>
      <div class="ant-notification-notice-description">
        {{ 'task_detail.messages.cancel.message' | translate }}
      </div>
      <span class="ant-notification-notice-btn flex items-center">
        <as-button
          variant="twotone"
          (click)="successCancel(); notification.close()">
          {{ 'buttons.yes' | translate }}
        </as-button>
        <as-button
          class="ml-2"
          variant="default"
          (click)="notification.close()">
          {{ 'buttons.no' | translate }}
        </as-button>
      </span>
    </div>
  </div>
</ng-template>
