export class RouterUtil {
  static Configuration = {
    Home: 'home',
    Dashboard: 'dashboard',
    Todo: 'todo',
    Tasks: 'list',
    TaskId: 'taskId',
    Storage: 'storage',
    Folders: 'folders',
    Folder: 'folder',
    FolderId: 'folderId',
    FolderKey: 'folderKey',
    FoldersMy: 'my-folders',
    FolderShared: 'shared-folders',
    FolderRecent: 'recent',
    FolderStarred: 'starred',
    FolderTrashed: 'trashed',
    Projects: 'teamspaces',
    ProjectKey: 'projectKey',
    ProjectDetails: 'project-details',
    ProjectAccess: 'project-access',
    ProjectSettings: 'project-settings',
    ProjectAccessInvite: 'invite',
    Profile: 'profile',
    ProfileId: 'profileId',
    Calendar: 'calendar',
    Categories: 'categories',
    Category: 'category',
    CategoryId: 'categoryId',
    Checklists: 'checklists',
    Checklist: 'checklist',
    ChecklistId: 'checklistId',
    Interventions: 'interventions',
    Intervention: 'intervention',
    InterventionId: 'interventionId',
    Map: 'map',
    Settings: 's',
    GeneralSettings: 'general',
    Users: 'users',
    UserId: 'userId',
    Groups: 'groups',
    GroupId: 'groupId',
    Roles: 'roles',
    RoleId: 'roleId',
    Hubs: 'hubs',
    HubId: 'hubId',
    Residences: 'residences',
    ResidenceId: 'residenceId',
    Addresses: 'addresses',
    AddressId: 'addressId',
    Suites: 'suites',
    SuiteId: 'suiteId',
    Recurring: 'recurring',
    Recipients: 'recipients',
    RecipientId: 'recipientId',
    AppSettings: 'app-settings',
    Subscription: 'subscription',
    SubscriptionChange: 'change',
    Compliance: 'compliance',
    WorkOrders: 'work-orders'
  };
  static ExternalUrls = {
    Administration: '/settings',
    Profile: '/a/profile',
    Privacy: '/p/legal/privacy-policy',
    TermsOfUser: '/p/legal/terms-of-use',
    ServiceStatus: '/s/services/health-check',
    Api: '/s/services/apis',
    Documentation: 'https://confluence.external-share.com/content/45522/cloudfleeter_guide_de_l_utilisateur_fr'
  };
}
