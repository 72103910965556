import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmptyComponent} from './empty.component';
import {EmptySimpleComponent} from './empty-simple/empty-simple.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    EmptyComponent,
    EmptySimpleComponent
  ],
  exports: [
    EmptyComponent
  ],
})
export class EmptyModule {
}
