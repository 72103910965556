import {Component, Input} from '@angular/core';

export type EmptyType = 'simple' | 'folder' | 'panel';

@Component({
  selector: 'as-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent {
  @Input() type: EmptyType = 'simple';
  @Input() title!: string;
}
