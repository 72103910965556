<div class="form-group vertical">
  <div class="pb-1">
    <label *ngIf="label" class="form-label mb-2">
      {{ label | translate }}
    </label>
  </div>
  <div class="rich-text-editor">
    <quill-editor
      [disabled]="disabled"
      [readOnly]="readonly"
      [modules]="editorOptions"
      [formControl]="control"
      placeholder="{{ placeholder | translate }}">
    </quill-editor>
  </div>
</div>

