import {RouterUtil} from "./router-util";

export class RouteUtil {
  static getProjectsRouteUrl() {
    return `/${RouterUtil.Configuration.Projects}`;
  }
  static getEventsRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.Todo}`;
  }
  static getCalendarRouteUrl(projectKey: string) {
    return `/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.Calendar}`;
  }








  static getHomeRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}`;
  }
  static getDashboardRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Dashboard}`;
  }
  static getWorkOrdersRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}`;
  }
  static getRecurringWorkOrdersRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recurring}`;
  }
  static getWorkOrderRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/p`;
  }
  static getWorkOrderByKeyRouteUrl(projectKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/p/${projectKey}`;
  }
  static getWorkOrderSettingsByKeyRouteUrl(projectKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/p/${projectKey}/${RouterUtil.Configuration.ProjectSettings}`;
  }
  static getWorkOrderDetailRouteUrl(projectKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectDetails}`;
  }
  static getWorkOrderAccessRouteUrl(projectKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectAccess}`;
  }
  static getWorkOrderInviteMemberRouteUrl(projectKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Projects}/${projectKey}/${RouterUtil.Configuration.ProjectAccess}/${RouterUtil.Configuration.ProjectAccessInvite}`;
  }
  static getStorageRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}`;
  }
  static getFolderRouteUrl(folderKey: string): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.Folder}/${folderKey}`;
  }
  static getFoldersRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.Folders}`;
  }
  static getFoldersMyRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FoldersMy}`;
  }
  static getFolderForSharedRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderShared}`;
  }
  static getFoldersRecentRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderRecent}`;
  }
  static getFoldersStarredRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderStarred}`;
  }
  static getFoldersTrashedRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Storage}/${RouterUtil.Configuration.FolderTrashed}`;
  }
  static getChecklistRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Checklists}`;
  }
  static getInterventionsRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Interventions}`;
  }
  static getMyProfileRouteUrl(): string {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Profile}`;
  }







  static getAdminRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}`;
  }
  static getAdminGreetingRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Home}`;
  }
  static getGeneralSettingsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.GeneralSettings}`;
  }
  static getUsersRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}`;
  }
  static getUserRouteUrl(userId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}/${userId}`;
  }
  static getUserAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Users}/add`;
  }
  static getGroupsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}`;
  }
  static getGroupRouteUrl(groupId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}/${groupId}`;
  }
  static getGroupAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Groups}/add`;
  }
  static getRolesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}`;
  }
  static getRoleRouteUrl(roleId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}/${roleId}`;
  }
  static getRoleAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Roles}/add`;
  }
  static getResidencesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}`;
  }
  static getResidenceRouteUrl(residenceId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}/${residenceId}`;
  }
  static getResidenceAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Addresses}/add`;
  }
  static getSuitesRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Suites}`;
  }
  static getSuiteRouteUrl(suiteId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Suites}/${suiteId}`;
  }
  static getHubsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}`;
  }
  static getHubRouteUrl(hubId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}/${hubId}`;
  }
  static getHubAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Hubs}/add`;
  }
  static getRecipientsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}`;
  }
  static getRecipientRouteUrl(recipientId: string) {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}/${recipientId}`;
  }
  static getRecipientAddRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Recipients}/add`;
  }
  static getAppSettingsRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.AppSettings}`;
  }
  static getSubscriptionRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Subscription}`;
  }
  static getSubscriptionChangeRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Subscription}/${RouterUtil.Configuration.SubscriptionChange}`;
  }
  static getComplianceRouteUrl() {
    return `/${RouterUtil.Configuration.Settings}/${RouterUtil.Configuration.Compliance}`;
  }
}
