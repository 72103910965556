import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {UserApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadRoles, loadRolesError, loadRolesSuccess} from "./roles.actions";

@Injectable({ providedIn: 'root' })
export class RolesEffects {
  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRoles),
      switchMap(() => this._userApi.listRoles()
        .pipe(
          map(response => loadRolesSuccess({ roles: response.payload })),
          catchError((error) => [loadRolesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _userApi: UserApi
  ) { }
}
