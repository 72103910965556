import {Injectable} from "@angular/core";
import {NotificationApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {CfContactUs, CfFeedback} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private _notificationApi: NotificationApi
  ) { }

  loadNotifications() {
    return this._notificationApi.findAll();
  }

  /**
   * Load All notifications for the current user session.
   *
   */
  loadNotificationsByUserId(userId: string) {
    return this._notificationApi.findAllByUserId(userId);
  }

  /**
   * Load the last 10 notifications for the current user session.
   *
   */
  loadLast10notifications(userId: string) {
    return this._notificationApi.findLast10ByUserId(userId);
  }

  /**
   * Read one notification by notification id.
   *
   * @param notificationId uuid as { string }.
   */
  readOneNotification(notificationId: string) {
    return this._notificationApi.read(notificationId);
  }

  /**
   * Read all notifications for user id.
   *
   * @param userId the user id <uuid> as { string }.
   */
  readAllNotifications(userId: string) {
    return this._notificationApi.readAll(userId);
  }

  /**
   * Send feedback to Quantum CMMS support.
   *
   * @param feedback uuid as { CfFeedback }.
   */
  feedback(feedback: CfFeedback) {
    return this._notificationApi.feedback(feedback);
  }

  /**
   * Send contact us to Quantum CMMS support.
   *
   * @param contactUs uuid as { CfContactUs }.
   */
  contactUs(contactUs: CfContactUs) {
    return this._notificationApi.contactUs(contactUs);
  }

}
