import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {
  getTemplates,
  getTemplatesLoading,
  loadTemplates,
  TemplatesState
} from "@app-web-central/web/dashboard/data-access";
import {Observable} from "rxjs";
import {CfDashboardTemplate} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class TemplatesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getTemplatesLoading));
  public templates$: Observable<CfDashboardTemplate[] | null>
    = this.store.pipe(select(getTemplates));

  constructor(
    private store: Store<TemplatesState>
  ) { }

  public loadTemplates(): void {
    this.store.dispatch(loadTemplates());
  }

}
