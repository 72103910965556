<as-stacked-list
  [title]="title"
  [limit]="4"
  [data]="users"
  [templateRef]="memberTpl">
</as-stacked-list>

<ng-template #memberTpl let-data>
  <ng-container>
    <li class="py-2">
      <div class="item">
        <div *ngIf="data.avatarUrl" class="avatar">
          <as-avatar
            [size]="32"
            [rounded]="false"
            [avatarUrl]="data.avatarUrl">
          </as-avatar>
        </div>
        <div class="content" [class.ml-0]="!data.avatarUrl">
          <p class="content-title">{{ data.fullName }}</p>
          <p class="content-subtitle">{{ data.username }}</p>
        </div>
        <div>
          <as-button
            size="xs"
            variant="plain"
            (click)="invite(data)"
          >
            {{ 'buttons.share' | translate }}
          </as-button>
        </div>
      </div>
    </li>
  </ng-container>
</ng-template>
