import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {CfSuite, GenericState} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {getSuites, loadSuitesSuccess, SuitesState} from "@app-web-central/web/suite/data-access";
import {select, Store} from "@ngrx/store";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {getAddresses} from "@app-web-central/web/address/data-access";
import {SuiteApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UnitUtil} from "@app-web-central/web/shared/utils";

interface SuiteState extends GenericState<CfSuite> {
  suiteId: string;
}

@Injectable({ providedIn: 'root' })
export class SuiteStore extends ComponentStore<SuiteState> {
  suites$ = this._store.pipe(select(getSuites));
  addresses$ = this._store.pipe(select(getAddresses));
  isSuiteLoading$ = this.select(SelectorUtil.isLoading);

  suitesByAddressId$ = (addressId: string): Observable<CfSuite[]> => this.addresses$.pipe(
    map((addresses) => {
      const newAddresses = addresses !== null ? addresses : [];
      for (const address of newAddresses) {
        if (address.id === addressId) {
          return address.suites;
        }
      }
      return [];
    })
  )

  suiteById$ = (suiteId: string) => this.suites$.pipe(
    tap(() => {
      this.patchState({
        suiteId
      });
      this.loadSuite({ suiteId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadSuite = this.effect<{ suiteId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ suiteId }) =>
        this._suiteApi.get(suiteId).pipe(
          tapResponse(
            (response) => {
              const suite = { ...response.payload };
              this.patchState({
                data: suite,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  update = this.effect<CfSuite>((params$) => (
      params$.pipe(
        withLatestFrom(this.suites$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([suite, suites]) =>
          this._suiteApi.update(suite.id, suite)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'update');
                  const newSuites = suites !== null ? [...suites] : [];
                  const index = newSuites.findIndex((item) => item.id === response.payload.id);
                  newSuites[index] = { ...response.payload };
                  this._store.dispatch(
                    loadSuitesSuccess({
                      suites: newSuites
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'update');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  create = this.effect<CfSuite>((params$) => (
      params$.pipe(
        withLatestFrom(this.suites$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([suite, suites]) =>
          this._suiteApi.create(suite)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newSuites = suites !== null ? [...suites] : [];
                  newSuites.push(response.payload);
                  this._store.dispatch(
                    loadSuitesSuccess({
                      suites: newSuites
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ suite: CfSuite }>((params$) => (
      params$.pipe(
        withLatestFrom(this.suites$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ suite }, suites]) =>
          this._suiteApi.delete(suite.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newSuites = UnitUtil.removeSuiteFromSuites(suites, suite);
                  this._store.dispatch(
                    loadSuitesSuccess({
                      suites: newSuites
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ suite: CfSuite }>((params$) => (
      params$.pipe(
        withLatestFrom(this.suites$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ suite }, suites]) =>
          this._suiteApi.enable(suite.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newSuites = UnitUtil.replaceSuiteAndGetNewSuites(suites, response.payload);
                  this._store.dispatch(
                    loadSuitesSuccess({
                      suites: newSuites
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ suite: CfSuite }>((params$) => (
      params$.pipe(
        withLatestFrom(this.suites$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ suite }, suites]) =>
          this._suiteApi.disable(suite.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newSuites = UnitUtil.replaceSuiteAndGetNewSuites(suites, response.payload);
                  this._store.dispatch(
                    loadSuitesSuccess({
                      suites: newSuites
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}`),
      this._translate.instant(`notifications.suite.${action}.${state}_message`)
    );
  }

  constructor(
    private _suiteApi: SuiteApi,
    private _store: Store<SuitesState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<SuiteState>{});
  }
}
