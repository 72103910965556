import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {InterventionApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadInterventions, loadInterventionsError, loadInterventionsSuccess} from "./interventions.actions";
import {catchError, map, switchMap} from "rxjs";

@Injectable({ providedIn: 'root' })
export class InterventionsEffects {

  loadInterventions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInterventions),
      switchMap(() => this.interventionApi.list()
        .pipe(
          map(response => loadInterventionsSuccess({ interventions: response.payload })),
          catchError(error => [loadInterventionsError(error)])
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private interventionApi: InterventionApi
  ) { }
}
