import {Actions, createEffect, ofType} from "@ngrx/effects";
import {NotificationService} from "../services/notification.service";
import {Injectable} from "@angular/core";
import {
  createReminder,
  createReminderError,
  createReminderSuccess,
  loadMore10Notifications,
  loadMore10NotificationsError,
  loadMore10NotificationsSuccess,
  loadNotifications,
  loadNotificationsError,
  loadNotificationsSuccess,
  readAllNotification,
  readAllNotificationSuccess,
  readAllNotificationError,
  readOneNotification,
  readOneNotificationError,
  readOneNotificationSuccess,
  updateReminder,
  updateReminderError,
  updateReminderSuccess
} from "./notifications.actions";
import {catchError, map, mergeMap, of, switchMap, withLatestFrom} from "rxjs";
import {NotificationsState} from "./notifications.reducer";
import {select, Store} from "@ngrx/store";
import {getNotifications} from "./notifications.selectors";
import {CfUserNotification} from "@app-web-central/web/shared/data-access/models";
import {ReminderApi} from "@app-web-central/web/shared/data-access/stouds-api";


@Injectable({ providedIn: 'root' })
export class NotificationsEffects {

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      switchMap(() => this.notificationService.loadNotifications()
        .pipe(
          map(response =>
            loadNotificationsSuccess( { notifications: response.payload })),
          catchError((error) => [loadNotificationsError(error)])
        )
      )
    )
  );

  // TODO: make it pageable !!
  loadMore10Notifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMore10Notifications),
      withLatestFrom(this.store.pipe(select(getNotifications))),
      mergeMap(([{ userId }]) => this.notificationService.loadLast10notifications(userId)
        .pipe(
          map(response =>
            loadMore10NotificationsSuccess( { notifications: response.payload })),
          catchError(() => of(loadMore10NotificationsError()))
        )
      )
    )
  );

  readOneNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readOneNotification),
      withLatestFrom(this.store.pipe(select(getNotifications))),
      mergeMap(([{ notificationId }, notifications]) =>
        this.notificationService.readOneNotification(notificationId)
        .pipe(
          map(response => {
            const newNotifications = [...notifications];
            const index = newNotifications.findIndex(
              item => item.id === response.payload?.id
            );
            newNotifications[index] = response.payload as CfUserNotification;
            return readOneNotificationSuccess({ notifications: newNotifications });
          }),
          catchError(() => of(readOneNotificationError()))
        )
      )
    )
  );

  readAllNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readAllNotification),
      withLatestFrom(this.store.pipe(select(getNotifications))),
      mergeMap(([{ userId }, notifications ]) =>
        this.notificationService.readAllNotifications(userId)
        .pipe(
          map(() => {
            const newNotifications = [...notifications].map((notification) => ({
              ...notification,
              markedAsRead: true
            }));
            return readAllNotificationSuccess({ notifications: newNotifications });
          }),
          catchError((error) => of(readAllNotificationError(error)))
        )
      )
    )
  );

  createReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReminder),
      switchMap(action => this.reminderApi.create(action.reminder, action.reminderType)
        .pipe(
          map(response => createReminderSuccess({ reminder: response.payload })),
          catchError(error => [createReminderError(error)])
        )
      )
    )
  );

  updateReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReminder),
      switchMap(action => this.reminderApi.update(action.reminder.id, action.reminder, action.reminderType)
        .pipe(
          map(response => updateReminderSuccess({ reminder: response.payload })),
          catchError(error => [updateReminderError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private reminderApi: ReminderApi,
    private store: Store<NotificationsState>,
    private notificationService: NotificationService
  ) { }

}
