<ul class="flex items-center">
  <li *ngFor="let breadcrumb of breadcrumbs; let idx = index">
    <ng-container *ngIf="idx > 0 && breadcrumb.url">
      <div class="flex items-center">
        <a [routerLink]="breadcrumb.url"
           (click)="routeToUrl(breadcrumb)"
           class="mr-4 text-sm text-slate-700 hover:text-slate-900 hover:cursor-pointer">
          {{ breadcrumb.name | translate }}
        </a>
        <svg-icon
          *ngIf="idx !== breadcrumbs.length-1"
          class="text-gray-400 !flex !items-center !flex-shrink-0 mr-4"
          key="solid/chevron-right">
        </svg-icon>
      </div>
    </ng-container>
  </li>
</ul>
