<div class="mt-3 py-2">
  <ul class="mb-3" *ngIf="control.value.length > 0" nz-list [nzDataSource]="control.value" nzBordered nzSize="small">
    <li nz-list-item *ngFor="let intervention of control.value">
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <as-button
            size="sm"
            (click)="removeIntervention(intervention)"
            variant="twotone"
            color="danger"
            [iconOnly]="true"
            icon="outline/trash"
          >
          </as-button>
        </nz-list-item-action>
      </ul>
      {{ intervention.name }}
    </li>
  </ul>

  <div class="w-full">
    <as-combobox
      [props]="['name']"
      [searchProp]="'name'"
      label="task_detail.add_intervention"
      [items]="interventions"
      [removable]="false"
      [disabled]="!!task.dates?.validationDate"
      [selectedItem]="control.value"
      [multipleSelect]="true"
      (activeItem)="onSelectInterventions($event)" >
    </as-combobox>
  </div>

</div>
