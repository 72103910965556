import {ComponentStore} from "@ngrx/component-store";
import {NavItemWithIconAndUrl} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {RouteUtil} from "@app-web-central/web/shared/utils";

interface NavBarLeftState {
  items: NavItemWithIconAndUrl[]
}

const initialState = [
  new NavItemWithIconAndUrl('constants.home', 'outline/home', RouteUtil.getHomeRouteUrl(), 'project:display'),
  new NavItemWithIconAndUrl('constants.projects', 'outline/clipboard-document-check', RouteUtil.getProjectsRouteUrl(), 'project:display'),
  new NavItemWithIconAndUrl('constants.settings', 'outline/cog', RouteUtil.getAdminRouteUrl(), 'project:display', true),
];

@Injectable({ providedIn: 'root' })
export class NavBarLeftStore extends ComponentStore<NavBarLeftState> {
  leftBarMenuItems$ = this.select(({ items }) => items);
  constructor() {
    super(<NavBarLeftState>{ items: initialState });
  }
}
