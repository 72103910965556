<div class="combobox">
  <div class="relative">
    <as-button
      nz-dropdown
      variant="default"
      nzTrigger="click"
      [disabled]="disabled"
      [nzDisabled]="disabled"
      [nzDropdownMenu]="menu"
    >
      {{ label | translate }}
    </as-button>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ng-container
        *ngIf="items && items.length then listRef else emptyRef"
        [ngTemplateOutlet]="listRef">
      </ng-container>

      <ng-template #listRef>
        <ul nz-menu class="dropdown-menu">

          <div class="search-list mb-2">
            <div class="w-full">
              <div class="relative">
                <div class="icon">
                  <svg-icon
                    key="mini/magnifying-glass"
                    class="!flex !items-center">
                  </svg-icon>
                </div>
                <input
                  [disabled]="disabled"
                  [readonly]="disabled"
                  [(ngModel)]="search"
                  placeholder="{{ 'top_bar.search' | translate }}"
                  type="search"
                  (blur)="onBlur()"
                />

              </div>
            </div>
          </div>

          <li nz-menu-item class="dropdown-item"
              *ngFor="let item of items | filter:search:searchProp:searchSubProp">
            <span
              class="block"
              (mousedown)="handleSelectItem(item)"
              *ngIf="item.avatarUrl">
              <as-user [user]="item"></as-user>
            </span>
            <span
              class="block truncate"
              (mousedown)="handleSelectItem(item)"
              *ngIf="!item.avatarUrl">
              <span *ngFor="let prop of props; let idx = index" class="text-sm font-sans">
                <span *ngIf="idx === 0">{{ item[prop] | translate }}</span>
                <span *ngIf="idx === 1">, {{ item[prop] | translate }} </span>
                <span *ngIf="idx > 1">{{ item[prop] | translate }} </span>
              </span>
            </span>
            <span class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                  *ngIf="selectedItem && (item.id === selectedItem.id) && !removable"
                  [class.active]="item.id === selectedItem.id">
              <svg-icon key="mini/check" class="!flex !items-center text-purple-600"></svg-icon>
            </span>
            <span (click)="handleRemoveItem()"
                  class="absolute inset-y-0 right-0 flex items-center pr-1.5"
                  *ngIf="selectedItem && (item.id === selectedItem.id) && removable"
                  [class.active]="item.id === selectedItem.id">
              <svg-icon key="mini/x-circle" class="!flex !items-center text-gray-300"></svg-icon>
            </span>
          </li>
        </ul>
      </ng-template>

      <ng-template #emptyRef>
        <div nz-menu
             class="dropdown absolute right-0 top-0 pb-6">
          <nz-empty nzNotFoundImage="simple"></nz-empty>
        </div>
      </ng-template>

    </nz-dropdown-menu>

  </div>
</div>
