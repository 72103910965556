import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {SuiteApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadSuites, loadSuitesError, loadSuitesSuccess} from "./suites.actions";

@Injectable({ providedIn: 'root' })
export class SuitesEffects {
  loadSuites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSuites),
      switchMap(() => this._suiteApi.list()
        .pipe(
          map((response) => loadSuitesSuccess({ suites: response.payload })),
          catchError((error) => [loadSuitesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _suiteApi: SuiteApi
  ) { }
}
