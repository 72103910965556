import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieComponent} from './cookie.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    CookieComponent
  ],
  exports: [
    CookieComponent
  ],
})
export class CookieModule {
}
