import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Shape, Size, Variant} from "@app-web-central/web/shared/ui/button";
import {Observable} from "rxjs";
import {DropdownItem, HandlerWithParams} from "@app-web-central/web/shared/data-access/models";

export type DropdownPlacement = 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight';

@Component({
  selector: 'as-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {
  @Input() label!: string;
  @Input() variant!: Variant;
  @Input() icon!: string;
  @Input() iconOnly = false;
  @Input() disabled!: boolean;
  @Input() size: Size = 'md';
  @Input() shape: Shape = 'radius-round';
  @Input() placement!: DropdownPlacement;
  @Input() overrideClass!: string;
  @Input() count!: number | undefined;
  @Input() selectedItem!: any;
  @Input() items$!: Observable<any[]>;
  @Input() items!: DropdownItem[];
  @Input() suffixItems!: any[];
  @Input() handler!: HandlerWithParams;
  clicked = false;

  get arrowColor() {
    return this.variant === 'twotone'
      ? 'text-purple-600 dark:text-white'
      : 'text-gray-500';
  }
}
