import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {getAppConfigProvider} from "@app-web-central/web/shared/api-config";
import {authInterceptorProvider} from "@app-web-central/web/auth/util";
import {environment} from "../environments/environment";
import {AppQuantumModule} from "@app-web-central/web/shell/feature";
import {AuthModule} from "@auth0/auth0-angular";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule,
    AppQuantumModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      }
    }),
  ],
  providers: [
    getAppConfigProvider(environment),
    authInterceptorProvider
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
