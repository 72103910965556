<div>
  <h2 class="pb-2 text-sm font-sans text-gray-900">
    {{ 'task_detail.address' | translate }}
  </h2>

  <div class="w-full">
    <nz-select
      class="w-full"
      nzPlaceHolder="{{ 'add_task.task_address_placeholder' | translate }}"
      [formControl]="control"
      [compareWith]="compareFn"
      nzShowSearch
      [nzDisabled]="!!task.dates?.validationDate"
    >
      <nz-option
        nzCustomContent
        *ngFor="let address of addresses"
        [nzLabel]="address.alias"
        [nzValue]="address"
      >
        {{ address.alias }}
      </nz-option>
    </nz-select>
  </div>

</div>
