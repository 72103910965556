import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarDesktopMenuComponent} from './nav-bar-desktop-menu.component';
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {NzModalModule} from "ng-zorro-antd/modal";
import {TranslateModule} from "@ngx-translate/core";
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {AvatarModule} from "@app-web-central/web/shared/ui/avatar";
import {InitialPipeModule} from "@app-web-central/web/shared/pipes/initial-pipe";
import {RouterModule} from "@angular/router";
import {DropdownModule} from "@app-web-central/web/shared/ui/dropdown";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {BreadcrumbsModule} from "@app-web-central/web/shared/ui/breadcrumbs";
import {TopNotificationModule} from "@app-web-central/web/shared/ui/top-notification";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NotificationListModule} from "@app-web-central/web/notification/ui/notification-list";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzSelectModule} from "ng-zorro-antd/select";
import {HasPermissionModule} from "@app-web-central/web/shared/directives/has-permission";

@NgModule({
  imports: [
    IconModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    NzModalModule,
    AvatarModule,
    RouterModule,
    DropdownModule,
    TranslateModule,
    NzToolTipModule,
    NzDrawerModule,
    NzSelectModule,
    BreadcrumbsModule,
    NzDropDownModule,
    InitialPipeModule,
    HasPermissionModule,
    TopNotificationModule,
    NotificationListModule,
    ReactiveFormsModule
  ],
  declarations: [
    NavBarDesktopMenuComponent
  ],
  exports: [
    NavBarDesktopMenuComponent
  ],
})
export class NavBarDesktopMenuModule {
}
