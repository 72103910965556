<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'modals.folder.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="handleCancel()">
    </as-button>
  </div>

  <form [formGroup]="folderForm" (ngSubmit)="submit()">
    <div class="modal-container">
      <as-field
        placeholder="{{ 'modals.project_rename.form.name_placeholder' | translate }}"
        [submitted]="submitted"
        [control]="controls('name')">
      </as-field>
    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        class="mr-2"
        (click)="handleCancel()"
        variant="default"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="folderForm.invalid"
        [isWorking]="isWorking$ | async"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>
</div>


