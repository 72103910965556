import {createAction, props} from "@ngrx/store";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

export const loadUsers = createAction(
  '[User List] Load Users'
);

export const loadUsersSuccess = createAction(
  '[User List] Load Users Success',
  props<{
    users: CfUser[]
  }>()
);

export const loadUsersError = createAction(
  '[User List] Load Users Error',
  props<{
    error: string
  }>()
);
