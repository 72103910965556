import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldSelectComponent} from './field-select.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {NzSelectModule} from "ng-zorro-antd/select";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    IconModule,
    CommonModule,
    NzSelectModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FieldSelectComponent
  ],
  exports: [
    FieldSelectComponent
  ],
})
export class FieldSelectModule {
}
