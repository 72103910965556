<div
  class="flex items-center"
  *ngIf="user && fullName">
  <as-avatar
    [avatarUrl]="imageUrl"
    [text]="fullName"
    [isActive]="isActive"
    [className]="'avatar-bg-rounded shadow-md ring-1 ring-gray-50 dark:ring-gray-700'"
    [size]="24"
    [rounded]="false"
    nz-tooltip
    [nzTooltipMouseEnterDelay]="0.3"
    [nzTooltipTitle]="fullName">
  </as-avatar>
  <span *ngIf="!imageOnly" class="ml-1 mr-1-5 user-name">
    {{ fullName }}
  </span>
</div>
