import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {loadUsersConfig} from "./users-config.actions";
import {UsersConfigState} from "./users-config.reducer";
import {getUsersConfig, getUsersConfigLoading} from "./users-config.selectors";
import {Observable} from "rxjs";
import {CfUserConfiguration} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class UsersConfigFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getUsersConfigLoading));
  public userLocalConfiguration$: Observable<CfUserConfiguration[] | null>
    = this.store.pipe(select(getUsersConfig));

  constructor(
    private store: Store<UsersConfigState>
  ) { }

  public loadUserLocalConfig(): void {
    this.store.dispatch(loadUsersConfig());
  }

}
