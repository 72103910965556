import {Injectable} from '@angular/core';
import {KEY_THEME, LocalStorageService} from "@app-web-central/web/shared/services/local-storage";
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private darkClassName = 'dark';
  public expanded$: BehaviorSubject<any>
    = new BehaviorSubject(true);
  private systemThemePreference: MediaQueryList
    = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  get getUserPreference(): string {
    return this.localStorageService.getItem(KEY_THEME);
  }

  public loadThemeMode(): void {
    this.applyPreferredTheme();
  }

  public toggleTheme(to?: 'light' | 'dark' | 'system'): void {
    if (to === 'system') {
      this.localStorageService.setItem(KEY_THEME, 'system');
      this.applySystemTheme();
    } else {
      const isDarkMode = to ? to === 'dark' : !this.isDarkMode();
      this.setTheme(isDarkMode);
      this.localStorageService.setItem(KEY_THEME, isDarkMode ? 'dark' : 'light');
    }
  }

  public isDarkMode(): boolean {
    return document.body.classList.contains(this.darkClassName);
  }

  private applyPreferredTheme(): void {
    const userPreference = this.localStorageService.getItem(KEY_THEME);

    if (userPreference === 'system') {
      this.applySystemTheme();
    } else if (userPreference) {
      this.setTheme(userPreference === 'dark');
    } else {
      this.applySystemTheme();
    }

    // Listen for system theme changes
    this.systemThemePreference.addEventListener('change', this.handleSystemThemeChange.bind(this));
  }

  // Handle system theme change
  private handleSystemThemeChange(event: MediaQueryListEvent): void {
    const isSystemTheme = this.localStorageService.getItem(KEY_THEME) === 'system';
    if (isSystemTheme) {
      this.setTheme(event.matches);
    }
  }

  // Apply theme based on system preference
  private applySystemTheme(): void {
    this.setTheme(this.systemThemePreference.matches);
  }

  private setTheme(isDarkMode: boolean): void {
    document.body.classList.toggle(this.darkClassName, isDarkMode);
    document.documentElement.setAttribute(
      "data-color-scheme",
      !isDarkMode ? "light" : "dark"
    );
  }
}
