import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {quillConfiguration} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-field-editor',
  templateUrl: './field-editor.component.html',
  styleUrls: ['./field-editor.component.scss'],
})
export class FieldEditorComponent {
  @Input() public label!: string;
  @Input() public placeholder!: string;
  @Input() public control!: FormControl;
  @Input() public disabled = false;
  @Input() public readonly = false;
  public editorOptions = quillConfiguration;
}
