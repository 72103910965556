import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {combineLatest, debounceTime, map, Observable, of, startWith, switchMap} from "rxjs";
import {CfProject, CfTask} from "libs/web/shared/data-access/models/src";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {TaskStore} from "libs/web/task/data-access/src";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RouteUtil, TaskUtil} from "libs/web/shared/utils/src";
import {Router} from "@angular/router";
import {CacheStore} from "libs/web/shared/data-access/store/src";
import {ProjectStore} from "libs/web/project/data-access/src";
import {TaskDrawerComponent} from "libs/web/task/ui/task-drawer/src";
import {NzNotificationService} from "ng-zorro-antd/notification";

@UntilDestroy()
@Component({
  selector: 'as-drawer-search',
  templateUrl: './drawer-search.component.html',
  styleUrls: ['./drawer-search.component.scss'],
  providers: [TaskStore]
})
export class DrawerSearchComponent implements OnInit {
  @ViewChild("TaskTitleTmpl") TaskTitleTmpl!: TemplateRef<any>;
  @ViewChild('cancelNotificationTpl') cancelNotificationTpl!: TemplateRef<any>;
  searchControl: FormControl = new FormControl('');
  results$!: Observable<CfTask[]>;
  recentTasks$ = new Observable<CfTask[]>();
  projects!: CfProject[];
  currentDrawer!: any;

  get hasSearchTermInput(): boolean {
    return !!this.searchControl.value;
  }

  constructor(
    private _router: Router,
    private _store: TaskStore,
    private _cache: CacheStore,
    private _drawer: NzDrawerRef,
    public projectStore: ProjectStore,
    private _drawerService: NzDrawerService,
    private _notification: NzNotificationService,
  ) { }

  ngOnInit(): void {
    const search$ = this.searchControl.valueChanges.pipe(debounceTime(50), startWith(this.searchControl.value));
    this.recentTasks$ = this._cache.searchCache$.pipe(map((tasks) => tasks !== null ? tasks.slice(0, 15) : []));
    this.results$ = combineLatest([search$, this._store.tasks$]).pipe(
      untilDestroyed(this),
      switchMap(([term, tasks]) => {
        const matchTasks = tasks?.filter((task) => {
          const foundInNumber = TaskUtil.searchString(task.identifier, term);
          const foundInName = TaskUtil.searchString(task.name, term);
          const foundInDescription = TaskUtil.searchString(task.description, term);
          const foundInTags = TaskUtil.searchStringInArray(task.tags, term);
          return foundInNumber || foundInName || foundInDescription || foundInTags;
        });
        return of(matchTasks || []);
      })
    );
    this.projectStore.projects$
      .pipe(untilDestroyed(this))
      .subscribe((projects) => {
        if (projects) {
          this.projects = projects;
        }
      });
  }

  closeDrawer() {
    this._drawer.close();
  }

  goToTask(task: CfTask) {
    this._cache.addToLocaleCache$(task);
    if (this.projects) {
      const projectKey = this.projects.find((p) => p.id === task.projectId)?.key
      const routeUrl = RouteUtil.getEventsRouteUrl(projectKey as string);
      this._router.navigateByUrl(`${routeUrl}/${task.id}`);
    }
    this.closeDrawer();
  }

  openTaskDrawer(task: CfTask, project: CfProject) {
    this._cache.addToLocaleCache$(task);
    this._router.navigate([], {
        queryParams: {taskId: task.id}, queryParamsHandling: 'merge'
      }
    );
    this.currentDrawer = this._drawerService.create({
      nzContent: TaskDrawerComponent,
      nzContentParams: { project },
      nzTitle: this.TaskTitleTmpl,
      nzWidth: '100%',
      nzClosable: true,
      nzWrapClassName: 'nz-task-drawer',
      nzBodyStyle: {padding: 0},
      nzOnCancel: () => {
        return new Promise<any>(() => {
          this._notification.template(this.cancelNotificationTpl);
        });
      }
    });
    this.currentDrawer.afterClose
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentDrawer = null;
        this._router.navigate([], {
          queryParams: {taskId: null},
          queryParamsHandling: 'merge'
        });
      });
  }

  successCancel() {
    if (this.currentDrawer) {
      this.currentDrawer.close();
    }
  }

}
