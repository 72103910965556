import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfOrganization, GenericState} from "@app-web-central/web/shared/data-access/models";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {select, Store} from "@ngrx/store";
import {getOrganizations, loadOrganizationsSuccess, OrganizationsState} from "../organizations";
import {map, switchMap, tap, withLatestFrom} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {OrganizationApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {NzNotificationService} from "ng-zorro-antd/notification";

interface OrganizationState extends GenericState<CfOrganization> {
  organizationId: string;
}

@Injectable({ providedIn: 'root' })
export class OrganizationStore extends ComponentStore<OrganizationState> {
  public organizations$ = this._store.pipe(select(getOrganizations));
  public isCurrentOrganizationLoading$ = this.select(SelectorUtil.isLoading);
  public organization$ = this.organizations$.pipe(
    map((organizations) => organizations && organizations.length < 2
      ? organizations[0]
      : {} as CfOrganization
    )
  )

  public update = this.effect<CfOrganization>((params$) => (
      params$.pipe(
        withLatestFrom(this.organizations$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([organization, organizations]) =>
          this._organizationApi.update(organization.id, organization)
            .pipe(
              tapResponse(
                (response) => {
                  this.notify('success', 'save');
                  const newOrganization = response.payload;
                  const newOrganizations = organizations !== null ? [ ...organizations ] : [];
                  const index = newOrganizations.findIndex((x) => x.id === newOrganization.id);
                  newOrganizations[index] = { ...newOrganization };
                  this._store.dispatch(
                    loadOrganizationsSuccess({
                      organizations: newOrganizations
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                error => {
                  this.notify('error', 'save');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private notify(state: string, action: string): void {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}`),
      this._translate.instant(`notifications.organization.${action}.${state}_message`)
    );
  }

  constructor(
    private _translate: TranslateService,
    private _organizationApi: OrganizationApi,
    private _notification: NzNotificationService,
    private _store: Store<OrganizationsState>
  ) {
    super(<OrganizationState>{});
  }
}
