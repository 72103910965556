import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap, withLatestFrom} from "rxjs";
import {Injectable} from "@angular/core";
import {
  loadChannels,
  loadChannelsError,
  loadChannelsSuccess
} from "./channel.actions";
import {select, Store} from "@ngrx/store";
import {getUsers, UsersState} from "@app-web-central/web/user/data-access";
import {ChannelApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {ChannelUtil} from "@app-web-central/web/shared/utils";

@Injectable()
export class ChannelEffects {
  users$ = this._usersStore.pipe(select(getUsers));

  loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadChannels),
      withLatestFrom(this.users$),
      switchMap(([_, users]) => this._channelApi.list()
        .pipe(
          map((response) => {
            const newChannels = [...response.payload];
            for (const channel of newChannels) {
              channel.subscribers = channel.subscribers
                .map((y) => ChannelUtil.setChannelSubscriber(y, users));
            }
            return loadChannelsSuccess({ channels: newChannels })
          }),
          catchError((error) => [loadChannelsError({ error })])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _channelApi: ChannelApi,
    private _usersStore: Store<UsersState>
  ) { }
}
