import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {FolderStore} from "@app-web-central/web/folder/data-access";
import {RouteUtil} from "@app-web-central/web/shared/utils";


@UntilDestroy()
@Component({
  selector: 'as-join-folder',
  templateUrl: './join-folder.component.html',
  styleUrls: ['./join-folder.component.scss'],
})
export class JoinFolderComponent implements OnInit {
  public loading!: boolean;
  public organization!: string;
  public owner!: string;
  private validationCode!: string;
  private folderId!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private folderStore: FolderStore
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        if (params) {
          this.folderId = params["folder"];
          this.validationCode = params["validation_code"];
          this.owner = params["invitor"] || 'nr.';
          this.organization = params["organization"] || 'Quantum CMMS';
        }
      });
  }

  public acceptInvitation(): void {
    this.loading = true;
    this.folderStore.joinFolder({
      folderId: this.folderId,
      validationCode: this.validationCode
    });
    this.router.navigateByUrl(RouteUtil.getHomeRouteUrl());
  }
}
