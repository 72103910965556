import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NotificationsFacade} from "@app-web-central/web/notification/data-access";

@Component({
  selector: 'as-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent {
  constructor(
    public notificationsFacade: NotificationsFacade
  ) { }
  readAllNotifications() {
    this.notificationsFacade.readAllNotifications();
  }
}
