import {ColumnItemBuilder} from "./column-item";

export class TopBarButtons {
  constructor(public icon: string, public i18n: string, public url: string | null, public handler?: Handler) { }
}

const TopBarDefinitions = [
  new TopBarButtons('solid/download', 'buttons.export', null),
  new TopBarButtons('solid/refresh', 'buttons.refresh', null)
];

export const TopBarButtonsItems = TopBarDefinitions;

export type CfTopBarValues = {
  [key: string]: TopBarButtons[];
}

export type CfTablesColumns = {
  [key: string]: ColumnItemBuilder<any>[];
}

type Handler = (params: any | null) => void;
