export const apiOriginUrl = "https://api.quantum-cmms.eu";
export const loginOriginUrl = "https://login.cloudfleeter.com";
export const appOriginUrl = "https://app.quantum-cmms.eu";
export const legalOriginUrl = "https://privacy.quantum-cmms.eu";


export const stripeOriginUrl = "https://api.stripe.com/v1/";
export const secretKey = "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";


export const boardOriginUrl = "https://board.cloudfleeter.com";
export const environment = {
  production: true,
  domain: 'quantum-cmms.eu',
  subdomain: 'board.',
  sSKey: `${secretKey}`,
  auth0: {
    domain: "https://quantum-prod.eu.auth0.com",
    audience: "https://api.quantum-cmms.eu",
    clientId: "TXeWp7VmuRqPKK5VuB4mqv01utXbejgt"
  },
  commonTenantID: "6e118177-8597-4624-bff9-ded074f4393f",
  appUrl: `${appOriginUrl}`,
  legalUrl: `${legalOriginUrl}`,
  boardUrl: `${boardOriginUrl}`,
  loginUrl: `${loginOriginUrl}/common/oauth2/v2.0/sign-in`,
  usersV1Url: `${apiOriginUrl}/v1/account/`,
  rolesV1Url: `${apiOriginUrl}/v1/account/`,
  generalConfigV1Url: `${apiOriginUrl}/v1/core/`,
  interventionV1Url: `${apiOriginUrl}/v1/intervention/`,
  organizationV1Url: `${apiOriginUrl}/v1/organization/`,
  projectV1Url: `${apiOriginUrl}/v1/project/`,
  notificationV1Url: `${apiOriginUrl}/v1/notification/`,
  reminderV1Url: `${apiOriginUrl}/v1/routine/`,
  uploadV1Url: `${apiOriginUrl}/v1/uploader/`,
  directionV1Url: `${apiOriginUrl}/v1/solver/`,
  tasksV1Url: `${apiOriginUrl}/v1/event/`,
  tasksV2Url: `${apiOriginUrl}/v2/event/`,
  eventStatsV1Url: `${apiOriginUrl}/v1/event-stats/`,
  channelV1Url: `${apiOriginUrl}/v1/channel/`,
  licenseV1Url: `${apiOriginUrl}/v1/license/`,
  usageV1Url: `${apiOriginUrl}/v1/usage/`,
  stripeV1Url: `${stripeOriginUrl}`,
  localDataUrl: '/assets/data'
};


