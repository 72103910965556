import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalActionInformationComponent} from './modal-action-information.component';
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {TranslateModule} from "@ngx-translate/core";
import {AlertModule} from "@app-web-central/web/shared/components/indicators/alert";
import {FieldEditorModule} from "@app-web-central/web/shared/components/inputs/field-editor";
import {FieldModule} from "@app-web-central/web/shared/components/inputs/field";
import {FieldSelectModule} from "@app-web-central/web/shared/components/inputs/field-select";
import {ReactiveFormsModule} from "@angular/forms";
import {UserModule} from "@app-web-central/web/shared/ui/user";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    AlertModule,
    FieldEditorModule,
    FieldModule,
    FieldSelectModule,
    ReactiveFormsModule,
    UserModule
  ],
  declarations: [
    ModalActionInformationComponent
  ],
  exports: [
    ModalActionInformationComponent
  ],
})
export class ModalActionInformationModule {
}
