<div class="mt-3">
  <h2 class="pb-2 text-sm font-sans text-gray-900">{{ 'task_detail.tags' | translate }}</h2>

  <div class="flex items-center">
    <nz-tag
      class="tag !bg-blue-100 !text-blue-700"
      *ngFor="let tag of tags"
      [nzColor]="tag === 'recurrent' ? 'purple' : 'blue'"
      [nzMode]="tag === 'recurrent' ? 'default' : 'closeable'"
      (nzOnClose)="handleClose(tag)">
      {{ sliceTagName(tag) }}
    </nz-tag>

    <nz-tag
      class="editable-tag tag"
      *ngIf="!inputVisible"
      (click)="showInput()">
      <svg-icon key="mini/plus" class="!flex !items-center" size="xs"></svg-icon>
      <span class="ml-1-5 mr-1-5">{{ 'task_detail.add_tag' | translate }}</span>
    </nz-tag>

    <input
      #inputElement
      nz-input
      class="textarea-inline-control"
      *ngIf="inputVisible"
      type="text"
      [formControl]="control"
      (blur)="handleInputConfirm()"
      (keydown.enter)="handleInputConfirm()"
    />
  </div>
</div>
