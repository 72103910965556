import {CfTeam} from "./team";
import {CfRole} from "./role";
import {CfUserSettings} from "./user-settings";
import {CfHub} from "./hub";
import {CfAbility} from "./ability";

export interface CfPhone {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}

export interface CfAvailabilities {
  enableAvailabilities: boolean;
  availabilities: CfAvailability[];
}

export interface CfAvailability {
  id: string;
  hub: CfHub;
  dayOfWeek: number;
  startOfDay: string;
  timezone: string;
  endOfDay: string;
}

export interface CfNotification {
  assignee: boolean;
  changer: boolean;
  delayed: boolean;
  enableNotifications: boolean;
  enablePushNotifications: boolean;
  reporter: boolean;
  watching: boolean;
  emailFormat: string;
}


export type DevicePlatformState = 'ios' | 'android' | 'web';
export type OperatingSystemState = 'ios' | 'android' | 'windows' | 'mac' | 'unknown';
export type UserActionState = 'user.login' | 'user.logout' | 'user.login_verified' | 'user.app_leave';

export interface CfDeviceInformation {
  sessionId?: string;
  isActive?: boolean;
  fcmToken?: string;
  action: UserActionState;
  actionDate?: number;
  remoteIpAddress?: string;
  model?: string;
  operatingSystem: string;
  osVersion: string;
  platform: string;
}

export interface CfUserSession {
  sessionId: string;
  isActive: boolean;
  fcmToken: string;
  platform: DevicePlatformState;
}


export interface CfSessionHistory {
  action: UserActionState;
  actionDate: number;
  remoteIpAddress: string;
  model: string;
  operatingSystem: OperatingSystemState;
  osVersion: string;
  platform: DevicePlatformState;
}

export class UserStatusValue {
  value: string;
  emoji: string;
  active: boolean;
  status: UserStatus;
  constructor(userStatus: UserStatus) {
    this.value = UserStatusValues[userStatus].text;
    this.emoji = UserStatusValues[userStatus].emoji;
    this.active = false;
    this.status = userStatus;
  }
}

export enum UserStatus {
  IN_MEETING = 'IN_MEETING',
  IN_VACATIONS = 'IN_VACATIONS',
  IN_COMMUTING = 'IN_COMMUTING',
  IN_REMOTE = 'IN_REMOTE',
  IS_SICK = 'IS_SICK',
  IS_BUSY = 'IS_BUSY',
  IS_AWAY = 'IS_AWAY',
}

export const UserStatusValues = {
  [UserStatus.IN_MEETING]: { text: 'user_detail.statuses.meeting', emoji: '📅' },
  [UserStatus.IN_VACATIONS]: { text: 'user_detail.statuses.vacations', emoji: '🌴' },
  [UserStatus.IN_COMMUTING]: { text: 'user_detail.statuses.commuting', emoji: '🚚' },
  [UserStatus.IN_REMOTE]: { text: 'user_detail.statuses.remote', emoji: '🏡' },
  [UserStatus.IS_SICK]: { text: 'user_detail.statuses.sick', emoji: '🤒' },
  [UserStatus.IS_BUSY]: { text: 'user_detail.statuses.busy', emoji: '🤯' },
  [UserStatus.IS_AWAY]: { text: 'user_detail.statuses.away', emoji: '💤' }
}

export const UserStatusValuesDisplay = [
  new UserStatusValue(UserStatus.IN_MEETING),
  new UserStatusValue(UserStatus.IN_VACATIONS),
  new UserStatusValue(UserStatus.IN_COMMUTING),
  new UserStatusValue(UserStatus.IN_REMOTE),
  new UserStatusValue(UserStatus.IS_SICK),
  new UserStatusValue(UserStatus.IS_BUSY),
  new UserStatusValue(UserStatus.IS_AWAY),
];

export interface CfUserConfiguration {
  notification: CfNotification;
  settings: any;
}


export interface CfUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  email: string;
  phone: CfPhone;
  lastPasswordGeneration: number;
  firstConnectionDate: number;
  lastConnectionDate: number;
  lastIpAddress: string;
  enabled: boolean;
  enabledAgent: boolean;
  enabledAccessKey: boolean;
  enabledConsole: boolean;
  dateAdd: number;
  dateUpd: number;
  avatarUrl: string;
  tenant: string;
  jobTitle: string;
  isBuiltIn: boolean;
  teams: CfTeam[] | null;
  roles: CfRole[];
  abilities: CfAbility[];
  notifications: CfNotification;
  settings: CfUserSettings;
  availabilities: CfAvailabilities;
  sessions: CfUserSession[];
  sessionHistory: CfSessionHistory[];
  accountStatus: UserStatus | null;
  authProviderId: string;
}
