export enum Priority {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST'
}

export const PriorityColors = {
  [Priority.HIGHEST]: '#CD1317',
  [Priority.HIGH]: '#E9494A',
  [Priority.MEDIUM]: '#E97F33',
  [Priority.LOW]: '#57A55A',
  [Priority.LOWEST]: '#57A55A'
};

export const PriorityClassColors = {
  [Priority.HIGHEST]: 'danger',
  [Priority.HIGH]: 'danger',
  [Priority.MEDIUM]: 'warning',
  [Priority.LOW]: 'success',
  [Priority.LOWEST]: 'success'
};

export const PriorityDisplayValues = {
  [Priority.HIGHEST]: 'task_detail.priorities.highest',
  [Priority.HIGH]: 'task_detail.priorities.high',
  [Priority.MEDIUM]: 'task_detail.priorities.medium',
  [Priority.LOW]: 'task_detail.priorities.low',
  [Priority.LOWEST]: 'task_detail.priorities.lowest',
};

export const PriorityIconValues = {
  [Priority.HIGHEST]: 'solid/chevron-double-up',
  [Priority.HIGH]: 'solid/chevron-up',
  [Priority.MEDIUM]: 'solid/chevron-up',
  [Priority.LOW]: 'solid/chevron-down',
  [Priority.LOWEST]: 'solid/chevron-double-down',
};

export const PriorityRawIconValues = {
  [Priority.HIGHEST]: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">\n    <path d="M18 11.5C18 11.5 13.5811 5.50001 12 5.5C10.4188 5.49999 6 11.5 6 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n    <path d="M18 18.5C18 18.5 13.5811 12.5 12 12.5C10.4188 12.5 6 18.5 6 18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n</svg>',
  [Priority.HIGH]: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">\n    <path d="M18 15C18 15 13.5811 9.00001 12 9C10.4188 8.99999 6 15 6 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n</svg>',
  [Priority.MEDIUM]: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">\n    <path d="M18 15C18 15 13.5811 9.00001 12 9C10.4188 8.99999 6 15 6 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n</svg>',
  [Priority.LOW]: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">\n    <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n</svg>',
  [Priority.LOWEST]: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">\n    <path d="M18 12.5C18 12.5 13.5811 18.5 12 18.5C10.4188 18.5 6 12.5 6 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n    <path d="M18 5.50005C18 5.50005 13.5811 11.5 12 11.5C10.4188 11.5 6 5.5 6 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n</svg>',
};

export class PriorityValue {
  value: Priority;
  text: string;
  color: string;
  icon: string;
  constructor(priority: Priority) {
    this.value = priority;
    this.text = PriorityDisplayValues[priority];
    this.color = PriorityColors[priority];
    this.icon = PriorityIconValues[priority];
  }
}

export const PriorityValues = [
  new PriorityValue(Priority.HIGHEST),
  new PriorityValue(Priority.HIGH),
  new PriorityValue(Priority.MEDIUM),
  new PriorityValue(Priority.LOW),
  new PriorityValue(Priority.LOWEST)
];
