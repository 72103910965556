import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from './dropdown.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {EmptyModule} from "@app-web-central/web/shared/components/indicators/empty";
import {HasPermissionModule} from "@app-web-central/web/shared/directives/has-permission";

@NgModule({
  imports: [
    IconModule,
    EmptyModule,
    CommonModule,
    ButtonModule,
    TranslateModule,
    NzToolTipModule,
    NzDropDownModule,
    HasPermissionModule,
  ],
  declarations: [
    DropdownComponent
  ],
  exports: [
    DropdownComponent
  ],
})
export class DropdownModule {
}
