import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {LicensesState} from "./licenses.reducer";
import {loadLicenses} from "./licenses.actions";
import {getLicenses, getLicensesLoading} from "./licenses.selectors";
import {Observable} from "rxjs";
import {CfLicense} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class LicensesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getLicensesLoading));
  public licenses$: Observable<CfLicense[] | null>
    = this.store.pipe(select(getLicenses));

  constructor(
    private store: Store<LicensesState>
  ) { }

  public loadLicenses(): void {
    this.store.dispatch(loadLicenses());
  }

}
