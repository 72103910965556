<div class="nav-bar-aside-secondary"
     [style.width.rem]="sidebarWidth">
  <div class="nav-bar-aside-secondary-container">
    <div class="area-logo">
      <ng-container
        *ngIf="organizationFacade.organization$ | async then logoTpl else noLogoTpl"
        [ngTemplateOutlet]="logoTpl"
        [ngTemplateOutletContext]="{ organization: organizationFacade.organization$ | async }">
      </ng-container>

      <ng-template #logoTpl let-organization>
        <div class="flex items-center">
          <as-avatar
            class="flex"
            [rounded]="false"
            nz-tooltip
            [nzTooltipPlacement]="'right'"
            [nzTooltipTrigger]="(expanded$ | async) ? null : 'hover'"
            [nzTooltipTitle]="companyTooltip"
            [className]="'avatar-bg-squared'"
            [avatarUrl]="organization.appLogoUrl"
            [text]="organization.companyName"
            [size]="32">
          </as-avatar>
          <div class="pl-[12px]" *ngIf="(expanded$ | async)">
            <div class="font-sans text-slate-900 dark:text-white text-base">
              {{ organization.companyName }}
            </div>
            <div class="text-sm text-slate-700 dark:text-slate-400">
              <span *ngIf="authFacade.user$ | async as session">{{ session.fullName }}</span>
            </div>
          </div>
          <ng-template #companyTooltip>
            <div class="font-sans text-white dark:text-white text-base">
              {{ organization.companyName }}
            </div>
            <div class="text-sm text-slate-400">
              <span *ngIf="authFacade.user$ | async as session">{{ session.fullName }}</span>
            </div>
          </ng-template>
        </div>
      </ng-template>

      <ng-template #noLogoTpl>
        <img class="h-8 w-auto" src="assets/images/logo_Quantum CMMS_4224x1024_purple.png" alt="Quantum logo">
      </ng-template>

    </div>
    <div class="vertical-items">
      <nav class="vertical-items-wrapper">
        <ng-container *ngFor="let item of items; let idx = index">

<!--          <p *ngIf="idx === 6"-->
<!--             [style.margin-top.px]="20"-->
<!--             class="px-2 text-sm font-sans text-gray-400">-->
<!--            {{ 'sidebar.links.general_settings' | translate }}-->
<!--          </p>-->

<!--          <p *ngIf="idx === 7"-->
<!--             [style.margin-top.px]="20"-->
<!--             class="px-2 text-sm font-sans text-gray-400">-->
<!--            {{ 'sidebar.links.billing' | translate }}-->
<!--          </p>-->

          <a [routerLink]="item.url"
             nz-tooltip
             *asHasPermission="[item.scope]"
             [nzTooltipPlacement]="'right'"
             [nzTooltipTrigger]="(expanded$ | async) ? null : 'hover'"
             nzTooltipTitle="{{ item.label | translate }}"
             routerLinkActive="active"
             class="group item-nav"
          >
            <svg-icon [key]="item.icon"
                      class="!flex !items-center mr-4 flex-shrink-0">
            </svg-icon>
            <span *ngIf="(expanded$ | async)">{{ item.label | translate }}</span>
          </a>
        </ng-container>
      </nav>
    </div>
    <div class="flex flex-col flex-shrink-0 space-y-1 px-3 py-4 w-full">
      <a
        [routerLink]="handleRouteToOrganization()"
        nz-tooltip
        [nzTooltipPlacement]="'right'"
        [nzTooltipTrigger]="(expanded$ | async) ? null : 'hover'"
        nzTooltipTitle="{{ 'sidebar.links.organization' | translate }}"
        routerLinkActive="active"
        class="group item-nav w-full"
      >
        <svg-icon key="outline/building-office-2" class="!flex !items-center mr-4 flex-shrink-0"></svg-icon>
        <span *ngIf="(expanded$ | async)">{{ 'sidebar.links.organization' | translate }}</span>
      </a>
<!--      <a-->
<!--        [routerLink]="handleRouteToSubscription()"-->
<!--        nz-tooltip-->
<!--        [nzTooltipPlacement]="'right'"-->
<!--        [nzTooltipTrigger]="(expanded$ | async) ? null : 'hover'"-->
<!--        nzTooltipTitle="{{ 'sidebar.links.subscription' | translate }}"-->
<!--        routerLinkActive="active"-->
<!--        class="group item-nav w-full"-->
<!--      >-->
<!--        <svg-icon key="outline/credit-card" class="!flex !items-center mr-4 flex-shrink-0"></svg-icon>-->
<!--        <span *ngIf="(expanded$ | async)">{{ 'sidebar.links.subscription' | translate }}</span>-->
<!--      </a>-->
      <a
        [routerLink]="handleRouteToCompliance()"
        nz-tooltip
        [nzTooltipPlacement]="'right'"
        [nzTooltipTrigger]="(expanded$ | async) ? null : 'hover'"
        nzTooltipTitle="{{ 'sidebar.links.compliance' | translate }}"
        routerLinkActive="active"
        class="group item-nav w-full"
      >
        <svg-icon key="outline/shield-check" class="!flex !items-center mr-4 flex-shrink-0"></svg-icon>
        <span *ngIf="(expanded$ | async)">{{ 'sidebar.links.compliance' | translate }}</span>
      </a>
      <a (click)="toggle()" class="group item-nav w-full">
        <svg-icon [key]="(expanded$ | async) ? 'solid/chevron-left' : 'solid/chevron-right'"
                  class="!flex !items-center mr-4 flex-shrink-0">
        </svg-icon>
        <span *ngIf="(expanded$ | async)">{{ 'buttons.collapse' | translate }}</span>
      </a>
    </div>
  </div>
</div>
