import {Component, Input} from '@angular/core';
import {CfAddress, CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-address',
  templateUrl: './task-drawer-address.component.html',
  styleUrls: ['./task-drawer-address.component.scss'],
})
export class TaskDrawerAddressComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @Input() addresses!: CfAddress[];

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);
}
