import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {loadGroups, loadGroupsError, loadGroupsSuccess} from "./groups.actions";
import {GroupApi} from "@app-web-central/web/shared/data-access/stouds-api";

@Injectable({ providedIn: 'root' })
export class GroupsEffects {
  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroups),
      switchMap(() => this._groupApi.list()
        .pipe(
          map(response => loadGroupsSuccess({ groups: response.payload })),
          catchError((error) => [loadGroupsError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _groupApi: GroupApi
  ) { }
}
