<button
  [type]="type"
  [class.in-group]="btnGroup"
  [ngClass]="['button', variant, shape, sizeBtn, buttonColor, overrideClasses]"
  [disabled]="disabled" >
  <svg-icon
    *ngIf="icon && !suffix"
    [key]="icon"
    [size]="size"
    [ngClass]="['icon-btn', !iconOnly ? 'mr-2' : '', variant]">
  </svg-icon>
  <svg-icon
    *ngIf="isWorking"
    key="outline/spin"
    class="!flex !items-center !justify-center"
    [ngClass]="['icon-btn', 'mr-1.5', spinnerColor]">
  </svg-icon>
  <ng-content></ng-content>
  <svg-icon
    *ngIf="icon && suffix"
    [key]="icon"
    [size]="size"
    [ngClass]="['icon-btn', !iconOnly ? 'ml-2' : '', variant]">
  </svg-icon>
</button>
