import {Component, Input} from '@angular/core';
import {UserStatusValues} from "@app-web-central/web/shared/data-access/models";

@Component({
  selector: 'as-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() avatarUrl!: string | undefined;
  @Input() size = 12;
  @Input() name = '';
  @Input() rounded = true;
  @Input() className = '';
  @Input() text!: string;
  @Input() editable = false;
  @Input() bgColor!: any;
  @Input() multiple = false;
  @Input() objectId!: string | null;
  @Input() folder!: string;
  @Input() bordered = false;
  @Input() status!: string | null;
  @Input() isActive = false;
  @Input() isContained = false;

  statuses = UserStatusValues as any;

  get active() {
    return this.isActive ? 'avatar-online' : 'avatar-offline';
  }

  get style() {
    return {
      ...this.bgColor,
      width: `${this.size}px`,
      height: `${this.size}px`,
      'background-size': !this.isContained ? `cover` : 'contain',
      'background-image': `url('${this.avatarUrl}')`,
      'border-radius': this.rounded ? '100%' : '0.375rem',
      'border': this.bordered ? '2px solid #FFFFFF' : 'none'
    };
  }

}
