<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'add_task.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form [formGroup]="taskForm" (ngSubmit)="submit()">
    <div class="modal-container">

      <div class="lg:grid lg:grid-cols-6 lg:gap-x-12 xl:gap-x-16">
        <div class="col-span-4 space-y-5">

          <div class="form-group">
            <label class="label">
              {{ 'add_task.task_project' | translate }}
            </label>
            <as-task-project-select
              [control]="controls('project')"
              (activeProject)="onChangeProject($event)"
              [projects]="projects$ | async">
            </as-task-project-select>
          </div>

          <as-field
            label="{{ 'add_task.task_title' | translate }}"
            placeholder="{{ 'add_task.task_title_placeholder' | translate }}"
            [submitted]="submitted"
            [control]="controls('name')">
          </as-field>

          <div>
            <as-field-editor
              label="{{ 'add_task.task_description' | translate }}"
              [control]="controls('description')"
              placeholder="{{ 'add_task.task_description_placeholder' | translate }}">
            </as-field-editor>
          </div>

          <div class="mt-3 form-group">
            <label class="label">
              {{ 'add_task.task_reporter' | translate }}
            </label>
            <as-task-reporter-select
              *ngIf="reporters$ | async as reporters"
              [control]="controls('createdBy')"
              [users]="reporters">
            </as-task-reporter-select>
          </div>

          <ng-container *ngIf="session$ | async as session">
            <div class="mt-3 form-group">
              <label class="label">
                {{ 'add_task.task_delivery_date' | translate }}
              </label>
              <as-task-delivery-date
                [control]="controls('deliveryDate')"
                [session]="session">
              </as-task-delivery-date>
              <small class="mt-2 flex items-center">
                <svg-icon key="mini/question-circle" size="xs" class="mr-2"></svg-icon>
                {{ 'add_task.task_delivery_date_optional' | translate }}
              </small>
            </div>
          </ng-container>
        </div>
        <div class="col-span-2 mt-10 lg:mt-0">
          <div class="form-group">
            <label class="label">
              {{ 'add_task.task_assignees' | translate }}
            </label>
            <as-task-assignees-select
              *ngIf="assignees$ | async as assignees"
              [control]="controls('users')"
              [users]="assignees">
            </as-task-assignees-select>
            <small class="mt-2 flex items-center">
              <svg-icon key="mini/question-circle" size="xs" class="mr-2"></svg-icon>
              {{ 'add_task.task_assignees_optional' | translate }}
            </small>
          </div>

          <div class="mt-3 form-group">
            <label class="label">
              {{ 'add_task.task_address' | translate }}
            </label>
            <as-task-address-select
              *ngIf="addresses$ | async as addresses"
              [control]="controls('address')"
              [addresses]="addresses">
            </as-task-address-select>
          </div>

          <div class="mt-3 form-group"
               *ngIf="controls('address').value
                  && controls('address').value.suites
                  && controls('address').value.suites.length">
            <label class="label">
              {{ 'add_task.task_suite' | translate }}
            </label>
            <as-task-suite-select
              *ngIf="controls('suite')"
              [control]="controls('suite')"
              [address]="controls('address').value">
            </as-task-suite-select>
          </div>

          <div class="mt-3 form-group">
            <label class="label">
              {{ 'add_task.task_metadata' | translate }}
            </label>

            <div class="border border-gray-200 rounded-md p-2"
                 *ngIf="controls('metadata').value && controls('metadata').value.length">
              <ng-container *ngFor="let data of controls('metadata').value">
                <div class="flex items-center justify-between">
                  <p class="text-gray-500 max-w-[12rem]">
                    <span class="font-sans">{{ data.key }}</span> : {{ data.value }}
                  </p>
                  <div>
                    <as-button
                      class="ml-1.5"
                      (click)="onEditMetadata(data)"
                      variant="plain"
                      size="xs"
                      icon="outline/pencil">
                    </as-button>
                    <as-button
                      class="ml-1.5"
                      (click)="onRemoveMetadata(data)"
                      variant="plain"
                      size="xs"
                      icon="outline/trash">
                    </as-button>
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="openMetadataForm">
              <as-task-metadata
                [controlKey]="controls('metadataKey')"
                [controlValue]="controls('metadataValue')"
                (metadataChanged)="onAddMetadata($event)">
              </as-task-metadata>
            </div>

            <div class="mt-3 flex items-center">
              <as-button
                (click)="onOpenMetadataForm()"
                variant="twotone"
              >
                {{ 'buttons.add' | translate }}
              </as-button>
              <as-button
                *ngIf="openMetadataForm"
                class="ml-2"
                (click)="onCancelMetadataForm()"
                variant="plain"
              >
                {{ 'buttons.cancel' | translate }}
              </as-button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="taskForm.invalid"
        [isWorking]="isWorking$ | async"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>

</div>
