import {Component, Input} from '@angular/core';
import {CfOrganization, CfUser} from "@app-web-central/web/shared/data-access/models";
import {FolderStore} from "@app-web-central/web/folder/data-access";

@Component({
  selector: 'as-modal-folder-share-result',
  templateUrl: './modal-folder-share-result.component.html',
  styleUrls: ['./modal-folder-share-result.component.scss'],
})
export class ModalFolderShareResultComponent {
  @Input() users!: CfUser[];
  @Input() endSlice = 2;
  @Input() title!: string;
  @Input() folderId!: string;
  @Input() session!: CfUser | null;
  @Input() organization!: CfOrganization;

  constructor(
    private _folderStore: FolderStore
  ) { }

  invite(user: CfUser) {
    const newMember = { ...user } as any;
    newMember["invitor"] = this.session?.fullName;
    newMember["organization"] = this.organization.companyName;
    delete newMember.roles;
    delete newMember.sessionHistory;
    delete newMember.sessions;
    this._folderStore.inviteMember({ member: newMember, folderId: this.folderId });
  }
}
