import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {FullCalendarComponent} from "@fullcalendar/angular";

interface CalendarState {
  calendar: FullCalendarComponent;
}

const initialState = {};

@Injectable({ providedIn: 'root' })
export class CalendarStore extends ComponentStore<CalendarState> {
  calendar$ = this.select(({ calendar }) => calendar);

  setCalendar$ = this.updater((state, calendarInstance: FullCalendarComponent) => ({
    ...state,
    calendar: calendarInstance
  }));

  constructor() {
    super(<CalendarState>{ calendar: initialState });
  }
}
