import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {
  CfProject,
  CfProjectMember,
  CfProjectMemberExtended,
  CfTaskAssignee,
  CfUIProject,
  CfUser,
  GenericState, NavLink
} from "@app-web-central/web/shared/data-access/models";
import {filter, Observable, withLatestFrom} from "rxjs";
import {map, switchMap, tap} from 'rxjs/operators';
import {RouteUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {ActivatedRoute, Router} from "@angular/router";
import {getProjects, loadProjectsSuccess, ProjectsState} from "../projects";
import {select, Store} from "@ngrx/store";
import {ProjectApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {ProjectUtil} from "@app-web-central/web/shared/utils";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {LocalStorageService} from "@app-web-central/web/shared/services/local-storage";
import {LocalNotificationService} from "@app-web-central/web/shared/services/local-notification";
import {AuthFacade} from "../../../../../../auth/data-access/src/lib/store/auth.facade";

export const PINNED_PROJECTS_KEY = 'PINNED_PROJECTS';

interface ProjectState extends GenericState<CfProject> {
  projectKey: string;
  projectId: string;
}

@Injectable({ providedIn: 'root' })
export class ProjectStore extends ComponentStore<ProjectState> {
  projects$ = this._store.pipe(select(getProjects));
  users$ = this._usersFacade.users$;
  usersEnabled$ = this._usersFacade.usersEnabled$;
  usersAgentEnabled$ = this._usersFacade.usersEnabledAndAgent$;
  session$ = this.authFacade.user$;
  projectIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["projectKey"]),
    filter((projectKey: string) => !!projectKey)
  );

  projectIdQueryParams$: Observable<string> = this._route.queryParams.pipe(
    map((params) => params["project"]),
    filter((projectId: string) => !!projectId)
  );


  isCurrentProjectLoading$ = this.select(SelectorUtil.isLoading);

  project$ = this.projectIdParams$.pipe(
    tap((projectKey) => {
      this.patchState({
        projectKey
      });
      this.loadProject({ projectKey });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadProject = this.effect<{ projectKey: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{projectKey}, users]) =>
        this._projectApi.getByKey(projectKey).pipe(
          tapResponse(
            (response) => {
              const project = { ...response.payload };
              project.projectLead = users?.find((x) => x.id === project.projectLeadId);
              project.defaultAssignee = users?.find((x) => x.id === project.defaultAssigneeId);
              this.patchState({
                data: project,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  loadProjectById = this.effect<{ projectId: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ projectId }, users]) => this._projectApi.get(projectId)
        .pipe(
          tapResponse(
            (response) => {
              const project = { ...response.payload };
              project.projectLead = users?.find((x) => x.id === project.projectLeadId);
              project.defaultAssignee = users?.find((x) => x.id === project.defaultAssigneeId);
              this.patchState({
                data: project,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  createProject = this.effect<CfProject>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([project, projects, users]) => this._projectApi.create(project)
        .pipe(
          tapResponse((response) => {
            const newProject = {...response.payload};
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, 'create');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'create', error);
          })
        )
      )
    )
  );

  updateProject = this.effect<{ project: CfProject, key: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ project, key }, projects, users]) => this._projectApi.update(project.id, project)
        .pipe(
          tapResponse((response) => {
            const newProject = {...response.payload};
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, key);
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, key, error);
          })
        )
      )
    )
  );

  archiveProject = this.effect<{ project: CfProject, key: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ project, key }, projects, users]) => this._projectApi.update(project.id, project)
        .pipe(
          tapResponse((response) => {
            const newProject = {...response.payload};
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, key);
            this._router.navigate([RouteUtil.getProjectsRouteUrl()]);
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, key, error);
          })
        )
      )
    )
  );

  deleteProject = this.effect<CfProject>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([project, projects]) => this._projectApi.delete(project.id)
        .pipe(
          tapResponse(() => {
            const newProjects = ProjectUtil.removeProjectFromActiveProjects(projects, project);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this._notify(true, 'delete');
            this._router.navigate([RouteUtil.getProjectsRouteUrl()]);
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'delete', error);
          })
        )
      )
    )
  );

  inviteMember = this.effect<{ member: CfUser | CfProjectMemberExtended, projectId: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{member, projectId}, projects, users]) => this._projectApi.invite(member, projectId)
        .pipe(
          tapResponse((response) => {
            const newProject = { ...response.payload };
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, 'invite');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'invite', error);
          })
        )
      )
    )
  );

  acceptInvitation = this.effect<{ projectId: string, validationCode: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({projectId, validationCode}) => this._projectApi.accept(projectId, validationCode)
        .pipe(
          tapResponse(() => {
            this._router.navigate([RouteUtil.getProjectsRouteUrl()]);
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'accept-invite', error);
          })
        )
      )
    )
  );

  revokeInvitation = this.effect<{ projectId: string, validationCode: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ projectId, validationCode}) => this._projectApi.revoke(projectId, validationCode)
        .pipe(
          tapResponse(() => {
            this._notify(true, 'revoke');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'revoke', error);
          })
        )
      )
    )
  );

  updateMember = this.effect<{ member: CfProjectMember }>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{member}, projects, users]) => this._projectApi.updateMember(member)
        .pipe(
          tapResponse((response) => {
            const newProject = { ...response.payload };
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, 'update_role');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'update_role', error);
          })
        )
      )
    )
  );

  removeMember = this.effect<{ member: CfProjectMember }>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$, this.users$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{member}, projects, users]) => this._projectApi.removeMember(member)
        .pipe(
          tapResponse((response) => {
            const newProject = { ...response.payload };
            ProjectUtil.replaceProjectLeadAndDefaultAssignee(newProject, users);
            const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, newProject);
            this._store.dispatch(
              loadProjectsSuccess({
                projects: newProjects
              })
            );
            this.patchState({
              data: newProject,
              status: 'success',
              error: ''
            });
            this._notify(true, 'remove_member');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'remove_member', error);
          })
        )
      )
    )
  );

  addPinnedProject = this.effect<CfProject>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$),
      tap(([project, projects]) => {
        this._localStorageAddToCache(project);
        const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, project);
        this._store.dispatch(
          loadProjectsSuccess({
            projects: newProjects
          })
        );
        this.patchState({
          data: project,
          status: 'success',
          error: ''
        });
      }, (error) => {
        this.patchState({
          status: 'error',
          error: error as unknown as string
        });
      })
    )
  );

  removePinnedProject = this.effect<CfProject>((params$) =>
    params$.pipe(
      withLatestFrom(this.projects$),
      tap(([project, projects]) => {
        this._localStorageRemoveFromCache(project);
        const newProjects = ProjectUtil.replaceProjectAndGetNewProjects(projects, project);
        this._store.dispatch(
          loadProjectsSuccess({
            projects: newProjects
          })
        );
        this.patchState({
          data: project,
          status: 'success',
          error: ''
        });
      }, (error) => {
        this.patchState({
          status: 'error',
          error: error as unknown as string
        });
      })
    )
  );

  projectById$ = (projectId: string): Observable<CfProject | undefined> => this.projects$.pipe(
    map((projects) => projects !== null
      ? projects.find((p) => p.id === projectId)
      : {} as CfProject
    )
  );

  usersEnabledByProject$ = (projectId: string): Observable<CfUser[]> => this.usersEnabled$.pipe(
    withLatestFrom(this.projects$),
    map(([users, projects]) => users != null && projects != null
      ? users.filter((x) => projects.find((p) => p.id === projectId)?.members.some((m) => m.members === x?.id))
      : []
    )
  );

  usersEnabledAsAssigneeByProject$ = (projectId: string): Observable<CfTaskAssignee[]> => this.usersEnabled$.pipe(
    withLatestFrom(this.projects$),
    map(([users, projects]) => users != null && projects != null
      ? users.filter((x) => projects.find((p) => p.id === projectId)?.members.some((m) => m.members === x?.id))
        .map((user) => ({
          assigneeId: user.id,
          assigneeImageUrl: user.avatarUrl,
          assigneeFullName: user.fullName,
          assigneeFirstName: user.firstName,
          assigneeLastName: user.lastName,
          assigneeEmail: user.email
        }))
      : []
    )
  );

  usersEnabledAgentByProject$ = (projectId: string): Observable<CfUser[]> => this.usersAgentEnabled$.pipe(
    withLatestFrom(this.projects$),
    map(([users, projects]) => users !== null && projects !== null
      ? users.filter((x) => projects.find((p) => p.id === projectId)?.members.some((m) => m.members === x.id))
      : []
    )
  );

  hasAccessProject$ = (projectId: string): Observable<boolean> => this.projects$.pipe(
    withLatestFrom(this.session$),
    map(([projects, session]) => projects !== null
      ? projects.find((project: CfProject) => project.id === projectId)?.members
        .some((m) => m.members === session?.id) || false
      : false
    )
  );

  projectsFilteredByUserAccess$ = (): Observable<CfProject[]> => this.projects$.pipe(
    withLatestFrom(this.session$),
    map(([projects, session]) => projects !== null
      ? projects.filter((x) => !x.archived && x.members.some((m) => m.members === session?.id))
      : []
    )
  );

  projectsEnabledForSelect$ = (): Observable<any[]> => this.projectsFilteredByUserAccess$().pipe(
    map((projects) => projects?.map((project) => ({
        label: project.name,
        value: project,
      }))
    )
  );

  projectsForNavLink$ = (): Observable<NavLink[]> => this.projects$.pipe(
    withLatestFrom(this.session$),
    map(([projects, session]) => {
      projects = [...this._localStoragePinnedProjects(projects)];
      return projects
        .filter((z) => !z.archived && z.members.some((m) => m.members === session?.id))
        .map((x) => (
          new NavLink(x.name, `p/${x.key}`, null, x.color, x.pinned, x,true, [
            { handler: this.pinProject.bind(this), icon: 'outline/pin', iconOnly: true, overrideClass: '!px-1', size: 'xs', variant: 'plain', tooltip: 'buttons.pin'}
          ])
        ))
    })
  );

  pinProject(project: CfProject) {
    const newProject = { ...project };
    newProject.pinned = !newProject.pinned;
    if (newProject.pinned) {
      this.addPinnedProject(newProject);
    } else {
      this.removePinnedProject(newProject);
    }
  }

  projectsAsUIProjects$ = (): Observable<CfUIProject[]> => this.projects$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([projects, users, session]) => {
      projects = [...this._localStoragePinnedProjects(projects)];
      return projects
        .filter((z) => !z.archived && z.members.some((m) => m.members === session?.id))
        .map((x) => ({
        name: {value: x.name, key: x.key, description: x.description, color: x.color, pinned: x.pinned, route: x.key},
        members: x.members.map((y) => ({...users?.find((z) => z.id === y.members)}) as CfUser),
        dateUpd: x.dateUpd,
        data: x
      }))
    })
  );

  projectsPinnedAsUIProjects$ = (): Observable<CfUIProject[]> => this.projectsAsUIProjects$().pipe(
    map((projects) => projects.filter((y) => y.data.pinned))
  );

  _localStorageAddToCache(project: CfProject) {
    const projectsInCache = this._localStorageService.getItem(PINNED_PROJECTS_KEY);
    const isInCache = projectsInCache?.some((x: CfProject) => x.id === project.id);
    const addToCache = !isInCache
      ? projectsInCache !== null
        ? [...projectsInCache, {id: project.id}]
        : [{id: project.id}]
      : projectsInCache;
    this._localStorageService.deleteItem(PINNED_PROJECTS_KEY);
    this._localStorageService.setItem(PINNED_PROJECTS_KEY, addToCache);
  }

  _localStorageRemoveFromCache(project: CfProject) {
    const projectsInCache = this._localStorageService.getItem(PINNED_PROJECTS_KEY);
    const isInCache = projectsInCache?.some((x: CfProject) => x.id === project.id);
    const newItemsInCache = isInCache
      ? projectsInCache.filter((x: CfProject) => x.id !== project.id)
      : projectsInCache;
    this._localStorageService.deleteItem(PINNED_PROJECTS_KEY);
    this._localStorageService.setItem(PINNED_PROJECTS_KEY, newItemsInCache);
  }

  _localStoragePinnedProjects(projects: CfProject[] | null) {
    const projectsInCache = this._localStorageService.getItem(PINNED_PROJECTS_KEY);
    const newProjects: CfProject[] = [];
    if (projects) {
      projects.forEach((project: CfProject) => {
        const newProject = {...project};
        projectsInCache?.forEach((projectInCache: CfProject) => {
          if (newProject.id === projectInCache.id) {
            newProject["pinned"] = true;
          }
        })
        newProjects.push(newProject);
      })
    }
    return newProjects;
  }

  _notify(hasSucceeded: boolean, key: string, backendError?: any) {
    if (hasSucceeded) {
      this._localNotification.success(
        'notifications.project.success',
        `projects.notifications.${key}.success_message`
      );
    } else {
      let errorMessage = `projects.notifications.${key}.error_message`;
      if (backendError) {
        errorMessage = backendError;
      }
      this._localNotification.error('notifications.project.error', errorMessage);
    }
  }

  constructor(
    private _router: Router,
    private authFacade: AuthFacade,
    private _store: Store<ProjectsState>,
    private _usersFacade: UsersFacade,
    private _projectApi: ProjectApi,
    private _route: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    private _localNotification: LocalNotificationService
  ) {
    super(<ProjectState>{});
  }
}
