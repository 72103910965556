<nz-select
  *ngIf="address.suites && address.suites.length > 0"
  class="w-full"
  [formControl]="control"
  nzShowSearch
>
  <nz-option
    nzCustomContent
    *ngFor="let suite of address.suites"
    nzLabel="{{suite.suiteNumber}} - {{suite.type}}"
    [nzValue]="suite"
  >
    {{suite.suiteNumber}} - {{suite.type}}
  </nz-option>
</nz-select>
