import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {CfAbility} from "@app-web-central/web/shared/data-access/models";
import {select, Store} from "@ngrx/store";
import {createAbility, deleteAbility, loadAbilities, updateAbility} from "./abilities.actions";
import {AbilitiesState} from "./abilities.reducer";
import {getAbilities, getAbilitiesLoading} from "./abilities.selectors";

@Injectable({ providedIn: 'root' })
export class AbilitiesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getAbilitiesLoading));
  public abilities$: Observable<CfAbility[] | null>
    = this.store.pipe(select(getAbilities));

  constructor(
    private store: Store<AbilitiesState>
  ) { }

  public loadAbilities(): void {
    this.store.dispatch(loadAbilities());
  }

  public createOne(ability: CfAbility): void {
    this.store.dispatch(createAbility({ ability }));
  }

  public updateOne(ability: CfAbility): void {
    if (ability && ability.id) {
      this.store.dispatch(updateAbility({ ability }));
    }
  }

  public deleteOne(abilityId: string): void {
    if (abilityId) {
      this.store.dispatch(deleteAbility({ abilityId }));
    }
  }

}
