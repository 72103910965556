<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'modals.feedback.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form [formGroup]="feedbackForm" (ngSubmit)="submit()">
    <div class="modal-container">
      <div class="form-group">
        <label for="service" class="label">
          {{ 'modals.feedback.service' | translate }}
        </label>

        <nz-select id="service"
                   class="w-full"
                   formControlName="service">
          <nz-option *ngFor="let state of feedbackOptions"
                     nzLabel=" {{ state.label }} "
                     [nzValue]="state.value">
            {{ state.value }}
          </nz-option>
        </nz-select>
      </div>

      <div class="form-group mt-3">
        <label class="label">
          {{ 'modals.feedback.subject' | translate }}
        </label>
        <input type="text"
               class="form-input"
               placeholder="{{ 'modals.feedback.subject_placeholder' | translate }}"
               formControlName="subject"/>
      </div>

      <div class="form-group mt-3">
        <label class="label">
          {{ 'modals.feedback.description' | translate }}
        </label>
        <textarea class="form-input"
                  placeholder="{{ 'modals.feedback.description_placeholder' | translate }}"
                  formControlName="description">
      </textarea>
      </div>

      <div class="form-group mt-3">
        <label class="label">
          {{ 'modals.feedback.acknowledge' | translate }}
        </label>

        <div class="form-check">
          <input type="checkbox"
                 id="acknowledge"
                 class="form-check-input"
                 formControlName="acknowledge" />
          <label class="form-check-label" for="acknowledge">
            {{ 'modals.feedback.acknowledge_desc' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="feedbackForm.invalid"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>
</div>
