<span *ngIf="content && isNumber" class="badge-wrapper">
  <span [ngClass]="['badge']">
    {{ contentValue }}
  </span>
</span>

<span *ngIf="content && !isNumber" [ngClass]="['badge']">
  {{ toString }}
</span>

<span [ngClass]="['badge-dot']"></span>
