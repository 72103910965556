import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {ChannelsState, getChannels, getChannelsLoading, loadChannels} from "@app-web-central/web/channel/data-access";
import {Observable} from "rxjs";
import {CfChannel} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ChannelsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getChannelsLoading));
  public channels$: Observable<CfChannel[] | null>
    = this.store.pipe(select(getChannels));

  constructor(
    private store: Store<ChannelsState>,
  ) { }

  public loadChannels(): void {
    this.store.dispatch(loadChannels());
  }
}
