<div class="hidden lg:flex lg:flex-shrink-0">
  <div class="flex w-20 flex-col">
    <div class="flex min-h-0 flex-1 flex-col overflow-y-auto bg-white">
      <div class="flex-1">
        <nav aria-label="TaskSidebar" class="flex flex-col items-center space-y-3 py-6">
          <ng-container *ngFor="let item of items; let idx = index">
<!--            <as-button-->
<!--              *ngIf="item.handler"-->
<!--              type="button"-->
<!--              (click)="item.handler(); item.checked = !item.checked; emitView(item)"-->
<!--              [icon]="item.icon"-->
<!--              [hasBadge]="idx === 1"-->
<!--              [countValue]="idx === 1 ? countComments : 0"-->
<!--              [className]="className(item)">-->
<!--            </as-button>-->
          </ng-container>
        </nav>
      </div>
    </div>
  </div>
</div>
