<div class="border-l-4 rounded mt-3 p-4" [ngClass]="[alertDisplayValue.bgColor, alertDisplayValue.borderColor]">
  <div class="flex flex-wrap items-center justify-start">
    <div class="flex-shrink-0">
      <svg-icon [key]="alertDisplayValue.icon"
                class="!flex !items-center {{ alertDisplayValue.iconColor }}">
      </svg-icon>
    </div>
    <div class="ml-3 flex-1 md:flex flex-col">
      <p *ngIf="param" class="text-sm {{ alertDisplayValue.textColor }}">{{ message | translate: {param}  }}</p>
      <p *ngIf="!param" class="text-sm {{ alertDisplayValue.textColor }}">{{ message | translate }}</p>

      <div
        *ngIf="alertButton"
        class="alert-button"
      >
        <as-button
          size="sm"
          (click)="alertButton.handler()"
          [className]="buttonClass"
        >
          {{ alertButton.label | translate }}
        </as-button>
      </div>
    </div>
  </div>
</div>
