<div class="mt-3 grid grid-cols-12 gap-3">
  <div class="col-span-12 sm:col-span-4">
    <label class="label">
      {{ 'add_task.task_metadata_key' | translate }}
    </label>
    <input
      type="text"
      class="form-input"
      [style.max-height.rem]="2.5"
      [formControl]="controlKey" />
  </div>
  <div class="col-span-12 sm:col-span-6">
    <div>
      <label class="label">
        {{ 'add_task.task_metadata_value' | translate }}
      </label>
      <input
        type="text"
        class="form-input"
        [formControl]="controlValue" />
    </div>
  </div>
  <div class="col-span-12 sm:col-span-2">
    <label class="label" [style.height.px]="32"></label>
    <div class="flex items-center">
      <as-button
        [iconOnly]="true"
        icon="mini/x-circle"
        (click)="onCancelMetadataForm()"
        variant="default"
      >
      </as-button>
      <as-button
        class="ml-2"
        [iconOnly]="true"
        icon="mini/check"
        (click)="onSaveMetadataForm()"
        variant="twotone"
        color="success"
      >
      </as-button>
    </div>
  </div>
</div>
