import {Component, Input} from '@angular/core';
import {CfAddress, CfTask} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'as-task-drawer-suite',
  templateUrl: './task-drawer-suite.component.html',
  styleUrls: ['./task-drawer-suite.component.scss'],
})
export class TaskDrawerSuiteComponent {
  @Input() task!: CfTask;
  @Input() address!: CfAddress;
  @Input() control!: FormControl;

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);
}
