import {createAction, props} from "@ngrx/store";
import {CfCategory} from "@app-web-central/web/shared/data-access/models";

export const loadCategories = createAction('[Category List] Load Categories');

export const loadCategoriesSuccess = createAction(
  '[Category List] Load Categories Success',
  props<{
    categories: CfCategory[];
  }>()
);

export const loadCategoriesError = createAction(
  '[Category List] Load Categories Error',
  props<{
    error: string;
  }>()
);
