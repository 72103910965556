import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutNoLeftbarComponent} from './layout-no-leftbar.component';
import {FooterDesktopModule} from "@app-web-central/web/shell/ui/footer-desktop";
import {NavigationModule} from "@app-web-central/web/shell/ui/navigation";
import {NavBarAsideSecondaryModule} from "@app-web-central/web/shell/ui/nav-bar-aside-secondary";
import {MainViewModule} from "@app-web-central/web/shell/ui/main-view";
import {CookieModule} from "@app-web-central/web/shared/ui/cookie";
import {StoreModule} from "@ngrx/store";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {LoaderModule} from "@app-web-central/web/shared/components/indicators/loader";


@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    StoreModule,
    LoaderModule,
    CookieModule,
    MainViewModule,
    NavigationModule,
    FooterDesktopModule,
    NavBarAsideSecondaryModule
  ],
  declarations: [
    LayoutNoLeftbarComponent
  ],
  exports: [
    LayoutNoLeftbarComponent
  ],
})
export class LayoutNoLeftbarModule {
}
