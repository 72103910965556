import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  CfFrequency,
  CfTask,
  DayPosition,
  Month,
  MonthValue,
  RRuleBySetDayPositionValue,
  RRuleByWeekDayName,
  RRuleFrequencyValue,
  WeekDay
} from "@app-web-central/web/shared/data-access/models";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormUtil} from "@app-web-central/web/shared/utils";
import {RruleBuilder} from "./rrule-builder";
import {RRule} from "rrule";


@UntilDestroy()
@Component({
  selector: 'as-modal-recurrence',
  templateUrl: './modal-recurrence.component.html',
  styleUrls: ['./modal-recurrence.component.scss']
})
export class ModalRecurrenceComponent implements OnInit {
  @Input() task!: CfTask;
  recurringForm!: FormGroup;
  endChoiceControl!: FormControl;
  monthlyChoiceControl!: FormControl;
  yearlyChoiceControl!: FormControl;

  isDailySelected = false;
  isWeeklySelected = false;
  isMonthlySelected = false;
  isYearlySelected = false;

  byWeekDay = [] as number[];
  byWeekDayNames!: RRuleByWeekDayName[];
  frequencies!: RRuleFrequencyValue[];
  bySetDayPositions!: RRuleBySetDayPositionValue[];
  byMonthNames!: MonthValue[];
  monthDays = Array(31).fill(1).map((x, i) => i +1);

  constructor(
    private _modalRef: NzModalRef,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._initData();
    if (this.task && this.task.id) {
      // if (this.task.rrule) {
      //   this.task.rrule = ({
      //     ...this.task.rrule,
      //     dateStart: new Date(this.task.taskDate.startTimeWindow),
      //     until: this.task.rrule.until == null ? null : new Date(this.task.rrule.until)
      //   });
      // }
      this._updateForm();
    }

    // this.timezone$.pipe(untilDestroyed(this)).subscribe(timezone => {
    //   this._setFormField('timezoneId', timezone);
    // });
  }

  public closeModal(rrule?: any): void {
    this._modalRef.close(rrule);
  }

  cancel() {
    this.recurringForm.reset();
    this.closeModal(null);
  }

  onBlurFrequency() {
    this._reset();
  }

  onChangeFrequency() {
    if (this.recurringForm.get('frequency')) {
      this.isDailySelected = this.recurringForm.value.frequency === CfFrequency.DAILY;
      this.isWeeklySelected = this.recurringForm.value.frequency === CfFrequency.WEEKLY;
      this.isMonthlySelected = this.recurringForm.value.frequency === CfFrequency.MONTHLY;
      this.isYearlySelected = this.recurringForm.value.frequency === CfFrequency.YEARLY;
    }
  }

  onSelectByWeekDay(event: any) {
    const value = parseInt(event.target.value, 10);
    if (value != null) {
      if (this.byWeekDay.some(x => x === value)) {
        this.byWeekDay = this.byWeekDay.filter(day => day !== value);
      } else {
        this.byWeekDay.push(value);
      }
      this._setFormField('byWeekDay', this.byWeekDay);
    }
  }

  onChangeRadioEnds(event: string) {
    const choice = event.toUpperCase();
    if (choice === Choice.NEVER) {
      this._setFormFieldDisable('count');
      this._setFormField('count',null);
      this._setFormField('until',null);
    } else if (choice === Choice.AFTER) {
      this._setFormFieldEnable('count');
      this._setFormField('until', null);
    } else {
      this._setFormFieldDisable('count');
      this._setFormField('count', null);
    }
  }

  onChangeRadioMonthly(event: string) {
    const choice = event.toUpperCase();
    if (choice === Choice.M_DAY) {
      this._setFormField('bySetPos',null);
      this._setFormField('byWeekDay',null);
    }  else {
      this._setFormField('byMonthDay',null);
    }
  }

  onChangeRadioYearly(event: string) {
    const choice = event.toUpperCase();
    if (choice === Choice.Y_MONTH) {
      this._setFormField('bySetPos',null);
      this._setFormField('byWeekDay',null);
      this._setFormField('byMonth',null);
    }  else {
      this._setFormField('byMonth',null);
      this._setFormField('byMonthDay',null);
    }
  }

  onChangeExcludeWeekend(value: boolean) {
    if (value) {
      this.byWeekDay = [0, 1, 2, 3, 4];
      this._setFormField('byWeekDay', this.byWeekDay);
      this._setFormField('frequency', CfFrequency.WEEKLY);
    }
  }

  submit() {
    if (this.recurringForm.invalid) {
      return;
    }

    if (this.recurringForm.get('until')?.value) {
      this._setFormField('count',null);
    }

    const rawData = this.recurringForm.getRawValue();
    const formValue = ({
      ...rawData,
      byWeekDay: FormUtil.convertToArray(rawData.byWeekDay),
      byMonth: FormUtil.convertToArray(rawData.byMonth),
      bySetPosition: FormUtil.convertToArray(rawData.bySetPos),
      byMonthDay: FormUtil.convertToArray(rawData.byMonthDay),
    });
    delete formValue.bySetPos;

    const brule = new RruleBuilder(formValue);
    const rrule = new RRule(brule);

    const newRrule = {...formValue};
    newRrule.dateStart = formValue.dateStart.getTime();
    newRrule.until = formValue.until == null ? null : formValue.until.getTime();
    newRrule.rule = rrule.toString();

    if (newRrule.until == null && newRrule.count == null) {
      newRrule['isInfinite'] = true;
    } else {
      newRrule['isInfinite'] = false;
    }

    this.closeModal(newRrule);
  }

  private _reset() {
    this.recurringForm.reset({
      dateStart: this.recurringForm.controls['dateStart'].value,
      interval: this.recurringForm.controls['interval'].value,
      frequency: this.recurringForm.controls['frequency'].value,
    });
  }

  private _setFormField(field: string, value: any) {
    this.recurringForm.controls[field].setValue(value);
  }

  private _setFormFieldEnable(field: string) {
    this.recurringForm.controls[field].enable();
  }

  private _setFormFieldDisable(field: string) {
    this.recurringForm.controls[field].disable();
  }

  private _initData() {
    this.byWeekDayNames = [
      new RRuleByWeekDayName(WeekDay.MO, false),
      new RRuleByWeekDayName(WeekDay.TU, false),
      new RRuleByWeekDayName(WeekDay.WE, false),
      new RRuleByWeekDayName(WeekDay.TH, false),
      new RRuleByWeekDayName(WeekDay.FR, false),
      new RRuleByWeekDayName(WeekDay.SA, false),
      new RRuleByWeekDayName(WeekDay.SU, false)
    ];
    this.frequencies = [
      new RRuleFrequencyValue(CfFrequency.DAILY),
      new RRuleFrequencyValue(CfFrequency.WEEKLY),
      new RRuleFrequencyValue(CfFrequency.MONTHLY),
      new RRuleFrequencyValue(CfFrequency.YEARLY)
    ];
    this.bySetDayPositions = [
      new RRuleBySetDayPositionValue(DayPosition.FIRST),
      new RRuleBySetDayPositionValue(DayPosition.SECOND),
      new RRuleBySetDayPositionValue(DayPosition.THIRD),
      new RRuleBySetDayPositionValue(DayPosition.FOURTH),
      new RRuleBySetDayPositionValue(DayPosition.FIFTH),
      new RRuleBySetDayPositionValue(DayPosition.LAST),
    ];
    this.byMonthNames = [
      new MonthValue(Month.JANUARY),
      new MonthValue(Month.FEBRUARY),
      new MonthValue(Month.MARCH),
      new MonthValue(Month.APRIL),
      new MonthValue(Month.MAY),
      new MonthValue(Month.JUNE),
      new MonthValue(Month.JULY),
      new MonthValue(Month.AUGUST),
      new MonthValue(Month.SEPTEMBER),
      new MonthValue(Month.OCTOBER),
      new MonthValue(Month.NOVEMBER),
      new MonthValue(Month.DECEMBER)
    ];
  }

  private _initForm() {
    this.recurringForm = this._formBuilder.group({
      id: [null],
      dateStart: [new Date(this.task.dates?.startDate), [Validators.required]],
      interval: [1, [Validators.required]],
      frequency: ['DAILY'],
      count: [{value: null, disabled: true}],
      until: [null],
      bySetPos: [null],
      byWeekDay: [null],
      byMonth: [null],
      byMonthDay: [null],
      excludeWeekend: [false],
      // timezoneId: [null]
    });
  }

  private _updateForm() {
    this.recurringForm.patchValue(
      this.task.recurrence
    );
    this.onChangeFrequency();
    this._updateChoiceControl();
  }

  private _updateChoiceControl() {
    if (this.task && this.task.recurrence) {
      this._initEndsSelection();
      this._initWeeklySelection();
      this._initMonthlySelection();
      this._initYearlySelection();
    } else {
      this._initBaseChoiceSelection();
    }
  }

  private _initBaseChoiceSelection() {
    this.endChoiceControl = new FormControl(ChoiceValue[Choice.NEVER]);
    this.monthlyChoiceControl = new FormControl(ChoiceValue[Choice.M_DAY]);
    this.yearlyChoiceControl = new FormControl(ChoiceValue[Choice.Y_MONTH]);
  }

  private _initEndsSelection() {
    if (this.task.recurrence.until) {
      this.endChoiceControl = new FormControl(ChoiceValue[Choice.UNTIL]);
    } else if (this.task.recurrence.count) {
      this.endChoiceControl = new FormControl(ChoiceValue[Choice.AFTER]);
    } else {
      this.endChoiceControl = new FormControl(ChoiceValue[Choice.NEVER]);
    }
  }

  private _initWeeklySelection() {
    if (this.isWeeklySelected) {
      this.byWeekDay = [...this.task.recurrence.byWeekDay];
      this.byWeekDay.forEach((weekDay: any) => {
        this.byWeekDayNames[weekDay].checked = true;
      });
    }
  }

  private _initMonthlySelection() {
    if (this.isMonthlySelected) {
      if (this.task.recurrence.bySetPosition.length > 0 && this.task.recurrence.byWeekDay.length > 0) {
        this.monthlyChoiceControl = new FormControl(ChoiceValue[Choice.M_WEEK]);
        this._setFormField('bySetPos', this.task.recurrence.bySetPosition[0]);
        this._setFormField('byWeekDay', this.task.recurrence.byWeekDay[0]);
      } else {
        this._baseMonthlySelection();
      }
    } else {
      this._baseMonthlySelection();
    }
  }

  private _baseMonthlySelection() {
    this.monthlyChoiceControl = new FormControl(ChoiceValue[Choice.M_DAY]);
    this._setFormField('byMonthDay', null);
  }

  private _initYearlySelection() {
    if (this.isYearlySelected) {
      if (this.task.recurrence.bySetPosition.length > 0 && this.task.recurrence.byWeekDay.length > 0
        && this.task.recurrence.byMonth.length > 0) {
        this.yearlyChoiceControl = new FormControl(ChoiceValue[Choice.Y_FREQUENCY]);
        this._setFormField('bySetPos', this.task.recurrence.bySetPosition[0]);
        this._setFormField('byWeekDay', this.task.recurrence.byWeekDay[0]);
        this._setFormField('byMonth', this.task.recurrence.byMonth[0]);
      } else {
        this._baseYearlySelection();
      }
    } else {
      this._baseYearlySelection();
    }
  }

  private _baseYearlySelection() {
    this.yearlyChoiceControl = new FormControl(ChoiceValue[Choice.Y_MONTH]);
    this._setFormField('byMonth', null);
    this._setFormField('byMonthDay', null);
  }

}

enum Choice {
  NEVER = 'NEVER',
  AFTER = 'AFTER',
  UNTIL = 'UNTIL',
  M_DAY = 'M_DAY',
  M_WEEK = 'M_WEEK',
  Y_MONTH = 'Y_MONTH',
  Y_FREQUENCY = 'Y_FREQUENCY'
}

const ChoiceValue = {
  [Choice.NEVER]: 'never',
  [Choice.AFTER]: 'after',
  [Choice.UNTIL]: 'until',
  [Choice.M_DAY]: 'm_day',
  [Choice.M_WEEK]: 'm_week',
  [Choice.Y_MONTH]: 'y_month',
  [Choice.Y_FREQUENCY]: 'y_frequency'
}
