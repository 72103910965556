import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class DirectionApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public addressLookup(body: any) {
    return this.httpClient.post<ResponseState<any[]>>(
      `${this._appConfig.directionV1Url}autocomplete-address-lookup`, body
    );
  }

}
