import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {loadScopes, loadScopesError, loadScopesSuccess} from "./scopes.actions";
import {RoleApi} from "@app-web-central/web/shared/data-access/stouds-api";

@Injectable({ providedIn: 'root' })
export class ScopesEffects {

  loadScopes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadScopes),
      switchMap(() => this.roleApi.scopes()
        .pipe(
          map((response) => loadScopesSuccess({ scopes: response.payload })),
          catchError((error) => [loadScopesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private roleApi: RoleApi
  ) { }
}
