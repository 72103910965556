export enum UIAlert {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS'
}

export const UIAlertDisplayValues = {
  [UIAlert.INFO]: {
    textColor: 'text-sky-700 dark:text-sky-100',
    bgColor: 'bg-sky-50 dark:bg-gray-700',
    icon: 'solid/information-circle',
    iconColor: 'text-sky-400 dark:text-sky-100',
    borderColor: 'border-sky-400 bg-sky-50',
  },
  [UIAlert.WARNING]: {
    textColor: 'text-amber-800 dark:text-amber-50',
    bgColor: 'bg-amber-50 dark:bg-gray-700',
    icon: 'solid/exclamation-circle',
    iconColor: 'text-amber-400 dark:text-amber-100',
    borderColor: 'border-amber-400 bg-amber-50',
  },
  [UIAlert.DANGER]: {
    textColor: 'text-rose-800 dark:text-rose-100',
    bgColor: 'bg-rose-50 dark:bg-gray-700',
    icon: 'solid/x-circle',
    iconColor: 'text-rose-400 dark:text-rose-100',
    borderColor: 'border-rose-400 bg-rose-50',
  },
  [UIAlert.SUCCESS]: {
    textColor: 'text-emerald-800 dark:text-emerald-50',
    bgColor: 'bg-emerald-50 dark:bg-gray-700',
    icon: 'solid/check-circle',
    iconColor: 'text-emerald-400 dark:text-emerald-100',
    borderColor: 'border-emerald-400 bg-emerald-50',
  }
};
