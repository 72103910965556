import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {NavItemWithUrl} from "libs/web/shared/data-access/models/src";
import {ModalFeedbackComponent} from "libs/web/shell/ui/modal-feedback/src";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiConfig, APP_CONFIG} from "libs/web/shared/api-config/src";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'as-drawer-help',
  templateUrl: './drawer-help.component.html',
  styleUrls: ['./drawer-help.component.scss'],
})
export class DrawerHelpComponent implements OnInit {
  items!: NavItemWithUrl[];
  privacyUrl = `${this._appConfig.legalUrl}/p/legal/privacy-policy`;
  termsUrl = `${this._appConfig.legalUrl}/p/legal/terms-of-use`;
  choicesUrl = `${this._appConfig.legalUrl}/p/legal/privacy-choices`;
  cookiesUrl = `${this._appConfig.legalUrl}/p/legal/cookie-policy`;
  eulaUrl = `${this._appConfig.legalUrl}/p/legal/eula`;

  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _drawer: NzDrawerRef,
    public sanitizer: DomSanitizer,
    private _modal: NzModalService,
    private _cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.handleCreateQuickItems(prefersDark);
    if (prefersDark) {
      prefersDark.addEventListener("change", this.handleCreateQuickItems.bind(this));
    }
  }

  handleCreateQuickItems(e: MediaQueryListEvent | MediaQueryList) {
    this.items = [
      // new NavItemWithUrl('tooltips.help', 'outline/document-text', this.routeUrl.bind(this), RouterUtil.ExternalUrls.Documentation, true),
      new NavItemWithUrl('tooltips.support', 'outline/megaphone', this.openFeedbackModal.bind(this)),
      new NavItemWithUrl('tooltips.privacy', 'outline/shield-check', this.routeUrl.bind(this), this.privacyUrl, true),
      new NavItemWithUrl('tooltips.terms', 'outline/document-text', this.routeUrl.bind(this), this.termsUrl, true),
      new NavItemWithUrl('tooltips.choices', 'outline/document-text', this.routeUrl.bind(this), this.choicesUrl, true),
      new NavItemWithUrl('tooltips.cookies', 'outline/document-text', this.routeUrl.bind(this), this.cookiesUrl, true),
      new NavItemWithUrl('tooltips.eula', 'outline/document-text', this.routeUrl.bind(this), this.eulaUrl, true),
    ];
    this._cdRef.detectChanges();
  }

  routeUrl(url: string) {
    window.open(url, '_blank');
  }

  closeDrawer() {
    this._drawer.close();
  }

  openFeedbackModal() {
    this._modal.create({
      nzContent: ModalFeedbackComponent,
      nzClosable: false,
      nzWidth: 450,
      nzFooter: null
    });
  }

}
