import {CfCategory, GenericState} from "@app-web-central/web/shared/data-access/models";
import {createReducer, on} from "@ngrx/store";
import {loadCategories, loadCategoriesError, loadCategoriesSuccess} from "./categories.actions";

export interface CategoriesState extends GenericState<CfCategory[]> { }

const initialState: CategoriesState = {
  data: null,
  error: null,
  status: 'pending'
}

export const categoriesFeatureKey = 'categories';

export const categoriesReducer = createReducer(
  initialState,
  on(loadCategories, (state) => ({
    ...state,
    status: 'loading',
    error: null
  })),
  on(loadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    data: categories,
    status: 'success',
    error: null
  })),
  on(loadCategoriesError, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
