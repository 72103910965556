<div [class.-mx-3]="negativeMargin" class="pt-4">
  <div *ngIf="title" class="stacked-list-title">
    <div class="sm:flex-auto">
      <h1>{{ title | translate }}</h1>
    </div>
  </div>
  <div class="mt-6">
    <ng-container *ngIf="data && data.length else noResult">
      <ul role="list" class="stacked-list-divider">
        <ng-container *ngFor="let dt of data | slice: 0:limit">
          <ng-container *ngTemplateOutlet="templateRef; context: {$implicit: dt }"></ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <ng-template #noResult>
      <ng-container *ngTemplateOutlet="noResultRef"></ng-container>
    </ng-template>
  </div>
  <div *ngIf="data.length > 4 && data.length > limit" class="pt-6">
    <as-button
      size="sm"
      class="w-full"
      variant="default"
      (click)="handleViewMore()"
    >
      {{ 'buttons.view_more' | translate }}
    </as-button>
  </div>
</div>
