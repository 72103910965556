import {HandlerWithParams} from "./nav-item";

type Variant = 'default' | 'twotone' | 'solid' | 'plain';
type Size = 'xs' | 'sm' | 'md' | 'lg';

export interface NavLinkHandler {
  handler: HandlerWithParams;
  variant: Variant;
  size: Size;
  overrideClass: string;
  icon: string | null;
  iconOnly: boolean | null;
  tooltip: string;
}

export class NavLink {
  constructor(
    public label: string,
    public url: string,
    public icon: string | null,
    public dot: string | null,
    public pinned: boolean | null,
    public data: any | null,
    public dropdown: boolean | null,
    public handlers?: NavLinkHandler[]
  ) { }
}
