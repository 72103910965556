import {
  CfTask,
  CfUser,
  Priority,
  TaskType,
  TaskStatus,
  TaskStepEnum,
  TaskStepIcon,
  CfTaskAssignee,
  TaskPriorityIcon,
  BadgeItemWithClass,
} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "./route-util";

export class TaskUtil {
  static clear(formValue: any): void {
    if (formValue.modifiedBy) {
      const newModifiedBy = { ...formValue.modifiedBy };
      delete newModifiedBy.sessions;
      delete newModifiedBy.sessionHistory;
      delete newModifiedBy.settings;
      delete newModifiedBy.roles;
      delete newModifiedBy.notifications;
      delete newModifiedBy.password;
      delete newModifiedBy.passwordCountRetry;
      delete newModifiedBy.passwordToken;
      delete newModifiedBy.passwordTokenExpiration;
      delete newModifiedBy.lastPasswordGeneration;
      delete newModifiedBy.lastIpAddress;
      delete newModifiedBy.lastConnectionDate;
      delete newModifiedBy.firstConnectionDate;
      delete newModifiedBy.accountStatus;
      delete newModifiedBy.availabilities;
      delete newModifiedBy.enabled;
      delete newModifiedBy.enabledAccessKey;
      delete newModifiedBy.enabledAgent;
      delete newModifiedBy.enabledConsole;
      delete newModifiedBy.isBuiltIn;
      formValue.modifiedBy = { ...newModifiedBy };
    }
    if (formValue.createdBy) {
      const newCreatedBy = { ...formValue.createdBy };
      delete newCreatedBy.sessions;
      delete newCreatedBy.sessionHistory;
      delete newCreatedBy.settings;
      delete newCreatedBy.roles;
      delete newCreatedBy.notifications;
      delete newCreatedBy.password;
      delete newCreatedBy.passwordCountRetry;
      delete newCreatedBy.passwordToken;
      delete newCreatedBy.passwordTokenExpiration;
      delete newCreatedBy.lastPasswordGeneration;
      delete newCreatedBy.lastIpAddress;
      delete newCreatedBy.lastConnectionDate;
      delete newCreatedBy.firstConnectionDate;
      delete newCreatedBy.accountStatus;
      delete newCreatedBy.availabilities;
      delete newCreatedBy.enabled;
      delete newCreatedBy.enabledAccessKey;
      delete newCreatedBy.enabledAgent;
      delete newCreatedBy.enabledConsole;
      delete newCreatedBy.isBuiltIn;
      formValue.createdBy = { ...newCreatedBy };
    }
    delete formValue.deliveryDate;
    delete formValue.startTime;
    delete formValue.endTime;
    delete formValue.allDay;
    delete formValue.assigneeId;
    delete formValue.addressId;
    delete formValue.suiteId;
    delete formValue.reporter;
    delete formValue.reporterId;
    delete formValue.recurrenceId;
    delete formValue.recipientId;
    delete formValue.project;
    delete formValue.attachmentIds;
    delete formValue.channelId;
    delete formValue.channel;
    delete formValue.createdById;
    delete formValue.dateAdd;
    delete formValue.dateUpd;
    delete formValue.eventType;
    delete formValue.identifier;
    delete formValue.interventionIds;
    delete formValue.modifiedById;
  }

  static mapAssignees(formValue: any) {
    return formValue.users.map((user: CfUser) => ({
      assigneeId: user.id,
      assigneeImageUrl: user.avatarUrl,
      assigneeFullName: user.fullName,
      assigneeFirstName: user.firstName,
      assigneeLastName: user.lastName,
      assigneeEmail: user.email
    }));
  }

  static doAssignee(user: CfUser) {
    return ({
      assigneeId: user.id,
      assigneeImageUrl: user.avatarUrl,
      assigneeFullName: user.fullName,
      assigneeFirstName: user.firstName,
      assigneeLastName: user.lastName,
      assigneeEmail: user.email
    });
  }

  static doAssignees(users: CfUser[]): any[] {
    if (!users) {
      return [];
    }
    return users.map((user: CfUser) => ({
      assigneeId: user.id,
      assigneeImageUrl: user.avatarUrl,
      assigneeFullName: user.fullName,
      assigneeFirstName: user.firstName,
      assigneeLastName: user.lastName,
      assigneeEmail: user.email
    }));
  }

  static doUsers(assignees: CfTaskAssignee[]): any[] {
    if (!assignees) {
      return [];
    }
    return assignees.map((assignee: CfTaskAssignee) => ({
      id: assignee.assigneeId,
      firstName: assignee.assigneeFirstName,
      lastName: assignee.assigneeLastName,
      fullName: assignee.assigneeFullName,
      avatarUrl: assignee.assigneeImageUrl,
      email: assignee.assigneeEmail
    }));
  }

  static getTaskStatusWithBadge(taskStatus: TaskStatus) {
    return new BadgeItemWithClass(taskStatus);
  }

  static searchString(str: string, searchString: string): boolean {
    str = str ?? '';
    searchString = searchString ?? '';
    return str.trim().toLowerCase().includes(searchString.trim().toLowerCase());
  }

  static searchStringInArray(arr: string[], searchString: string): boolean {
    arr = arr ?? [];
    searchString = searchString ?? '';
    return arr.includes(searchString.trim().toLowerCase());
  }

  static getTaskTypeIcon(taskType: TaskType): string {
    return taskType?.toLowerCase();
  }

  static getTaskStepTypeIcon(taskStep: TaskStepEnum) {
    return new TaskStepIcon(taskStep);
  }

  static getTaskPriorityIcon(priority: Priority): TaskPriorityIcon {
    return new TaskPriorityIcon(priority);
  }

  static buildUITaskObject(event: CfTask) {
    return {
      name: {id: event.id, identifier: event.identifier, name: event.name, description: event.description, tags: event.tags, suite: event.suite, address: event.address },
      assignee: event.assignee !== undefined ? event.assignee : null,
      status: event.status,
      dateAdd: event.dateAdd,
      dueDate: event.dates.deliveryDate
    };
  }

  static buildUITaskObjectWithoutDateAdd(event: CfTask) {
    return {
      name: {id: event.id, identifier: event.identifier, name: event.name, description: event.description, projectId: event.projectId, route: RouteUtil.getProjectsRouteUrl() },
      assignee: event.assignee !== undefined ? event.assignee : null,
      status: event.status,
      dueDate: event.dates.deliveryDate
    };
  }

  static setChannelSubscribers(task: CfTask, users: CfUser[] | null) {
    const subscribers = [...task.channel.subscribers];
    for (const subscriber of subscribers) {
      const user = users?.find((x) => x.id === subscriber.subscriberId);
      subscriber.fullName = user != null ? user.fullName : 'Unknown user';
      subscriber.avatarUrl = user != null ? user.avatarUrl : 'Unknown avatar';
    }
    return subscribers;
  }

  static isDateWithinRange(date: number, day: number[], week: number[], month: number[], year: number[], customRange: number[]): boolean {
    if (day.length) {
      return date > day[0] && date < day[1];
    }
    if (week.length) {
      return date > week[0] && date < week[1];
    }
    if (month.length) {
      return date > month[0] && date < month[1];
    }
    if (year.length) {
      return date > year[0] && date < year[1];
    }
    if (customRange.length) {
      return date > customRange[0] && date < customRange[1];
    }
    return false;
  }

}
