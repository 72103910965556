import {CfIntervention} from "../api/intervention";
import {CfCategory} from "../api/category";
import {CfAbility} from "../api/ability";

export const ListOfInterventionColumn = [
  {
    title: 'interventions.columns.name',
    compare: (a: CfIntervention, b: CfIntervention) => a.name.localeCompare(b.name)
  },
  {
    title: 'interventions.columns.category',
    compare: (a: CfIntervention, b: CfIntervention) => a.category?.name.localeCompare(b.category?.name)
  },
  {
    title: 'interventions.columns.status',
    compare: (a: CfIntervention, b: CfIntervention) => Number(a.enabled) - Number(b.enabled),
  },
  {
    title: 'interventions.columns.updated',
    compare: (a: CfIntervention, b: CfIntervention) => a.dateUpd - b.dateUpd,
  }
];

export const ListOfCategoryColumn = [
  {
    title: 'categories.columns.name',
    compare: (a: CfCategory, b: CfCategory) => a.name.localeCompare(b.name)
  },
  {
    title: 'categories.columns.status',
    compare: (a: CfCategory, b: CfCategory) => Number(a.enabled) - Number(b.enabled),
  },
  {
    title: 'categories.columns.updated',
    compare: (a: CfCategory, b: CfCategory) => a.dateUpd - b.dateUpd,
  }
];

export const ListOfAbilityColumn = [
  {
    title: 'abilities.columns.name',
    compare: (a: CfAbility, b: CfAbility) => a.name.localeCompare(b.name)
  },
  {
    title: 'abilities.columns.added',
    compare: (a: CfAbility, b: CfAbility) => a.dateAdd - b.dateAdd,
  },
  {
    title: 'abilities.columns.updated',
    compare: (a: CfAbility, b: CfAbility) => a.dateUpd - b.dateUpd,
  }
];
