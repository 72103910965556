import {Injectable, Provider} from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {catchError, Observable, switchMap, throwError} from "rxjs";
import {OcfCookieService} from "@app-web-central/web/shared/services/cookie-service";
import {AuthService} from "@auth0/auth0-angular";
import {TranslateService} from "@ngx-translate/core";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cookieService: OcfCookieService,
    private translateService: TranslateService
  ) { }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getAccessTokenSilently()
      .pipe(
        switchMap((token: string) => {
          const authReq: HttpRequest<any> = req.clone({
            headers: req.headers
              .set('Authorization', `Bearer ${token}`)
              .set('Accept-Language', this.translateService.currentLang)
          });
          return next.handle(authReq);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === FORBIDDEN || error.status === UNAUTHORIZED) {
            this.redirectToAuthorize();
          }
          return throwError(error);
        })
      );
  }

  private redirectToAuthorize(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.authService.logout();
  }

}

export const authInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
