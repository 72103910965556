<nav aria-label="Notification list"
     class="notification-list shadow-2xl">
  <ul role="list" class="notification-list-wrapper">
    <ng-container
      *ngIf="notificationsFacade.notifications$ | async then notificationsTpl else spinnerTpl"
      [ngTemplateOutlet]="notificationsTpl"
      [ngTemplateOutletContext]="{ notifications: notificationsFacade.notifications$ | async }">
    </ng-container>
    <ng-template #notificationsTpl let-notifications>
      <li class="menu-item-header">
        <div class="menu-item-header-wrapper">
          <h6 class="text-lg font-sans dark:text-white">Notifications</h6>
          <span
            class="tooltip-wrapper"
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.3"
            nzTooltipTitle="Marquer tout comme lu"
            nzTooltipPlacement="top">
            <as-button
              size="sm"
              variant="plain"
              icon="outline/envelope-open"
              (click)="readAllNotifications()"
            >
            </as-button>
          </span>
        </div>
      </li>
      <ng-container *ngFor="let notification of notifications; let idx = index">
        <as-notification-item
          [index]="idx"
          [length]="notifications.length"
          [notification]="notification">
        </as-notification-item>
      </ng-container>
    </ng-template>
    <ng-template #spinnerTpl>
      <as-spinner></as-spinner>
    </ng-template>
  </ul>
</nav>
