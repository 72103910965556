import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap, withLatestFrom} from "rxjs";
import {AbilityApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {
  createAbility,
  createAbilityError,
  createAbilitySuccess, deleteAbility, deleteAbilityError, deleteAbilitySuccess,
  loadAbilities,
  loadAbilitiesError,
  loadAbilitiesSuccess,
  updateAbility,
  updateAbilityError,
  updateAbilitySuccess
} from "./abilities.actions";
import {CfAbility, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {AbilitiesFacade} from "./abilities.facade";

@Injectable({ providedIn: 'root' })
export class AbilitiesEffects {

  public loadAbilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAbilities),
      switchMap(() => this.abilityApi.list()
        .pipe(
          map(response => loadAbilitiesSuccess({ abilities: response.payload })),
          catchError(error => [loadAbilitiesError(error)])
        )
      )
    )
  );

  public createOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAbility),
      withLatestFrom(this.abilitiesFacade.abilities$),
      switchMap(([{ ability }, abilities]) => this.abilityApi.create(ability)
        .pipe(
          map((response: ResponseState<CfAbility>) => {
            const newAbilities: CfAbility[] = abilities ? [ ...abilities] : [];
            newAbilities.push(response.payload);
            return createAbilitySuccess({ abilities: newAbilities });
          }),
          catchError((error) => {
            return [createAbilityError(error)]
          })
        )
      )
    )
  );

  public updateOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbility),
      withLatestFrom(this.abilitiesFacade.abilities$),
      switchMap(([{ ability }, abilities]) => this.abilityApi.update(ability.id, ability)
        .pipe(
          map((response: ResponseState<CfAbility>) => {
            const newAbilities: CfAbility[] = abilities ? [ ...abilities] : [];
            const index: number = newAbilities.findIndex((skill: CfAbility) => skill.id === ability.id);
            if (index > -1) {
              newAbilities[index] = { ...response.payload };
            }
            return updateAbilitySuccess({ abilities: newAbilities });
          }),
          catchError((error) => {
            return [updateAbilityError(error)]
          })
        )
      )
    )
  );

  public deleteOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAbility),
      withLatestFrom(this.abilitiesFacade.abilities$),
      switchMap(([{ abilityId }, abilities]) => this.abilityApi.delete(abilityId)
        .pipe(
          map(() => {
            const newAbilities: CfAbility[] = abilities ? [ ...abilities] : [];
            const index: number = newAbilities.findIndex((skill: CfAbility) => skill.id === abilityId);
            if (index > -1) {
              newAbilities.splice(index, 1);
            }
            return deleteAbilitySuccess({ abilities: newAbilities });
          }),
          catchError((error) => {
            return [deleteAbilityError(error)]
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private abilityApi: AbilityApi,
    private abilitiesFacade: AbilitiesFacade
  ) { }

}
