import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfIntervention, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class InterventionApi {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  get(interventionId: string) {
    return this._http.get<ResponseState<CfIntervention>>(
      `${this._appConfig.interventionV1Url}manage/interventions/${interventionId}`
    );
  }

  list() {
    return this._http.get<ResponseState<CfIntervention[]>>(
      `${this._appConfig.interventionV1Url}manage/interventions`
    );
  }

  create(intervention: CfIntervention) {
    return this._http.post<ResponseState<CfIntervention>>(
      `${this._appConfig.interventionV1Url}manage/interventions`, intervention
    );
  }

  update(interventionId: string, intervention: CfIntervention) {
    return this._http.put<ResponseState<CfIntervention>>(
      `${this._appConfig.interventionV1Url}manage/interventions/${interventionId}`, intervention
    );
  }

  delete(interventionId: string) {
      return this._http.delete<ResponseState<CfIntervention>>(
        `${this._appConfig.interventionV1Url}manage/interventions/${interventionId}`
      );
    }

}
