import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarAsideSecondaryComponent} from './nav-bar-aside-secondary.component';
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AvatarModule} from "@app-web-central/web/shared/ui/avatar";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";
import {HasPermissionModule} from "@app-web-central/web/shared/directives/has-permission";

@NgModule({
    imports: [
        IconModule,
        AvatarModule,
        CommonModule,
        RouterModule,
        NzToolTipModule,
        TranslateModule,
        ButtonModule,
        HasPermissionModule,
    ],
  declarations: [
    NavBarAsideSecondaryComponent
  ],
  exports: [
    NavBarAsideSecondaryComponent
  ],
})
export class NavBarAsideSecondaryModule {
}
