import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {getInterventions, InterventionsState, loadInterventionsSuccess} from "../interventions";
import {select, Store} from "@ngrx/store";
import {Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {CfIntervention, GenericState, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {InterventionApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";

interface InterventionState extends GenericState<CfIntervention> {
  interventionId: string;
}

@Injectable({ providedIn: 'root' })
export class InterventionStore extends ComponentStore<InterventionState> {
  public interventions$: Observable<CfIntervention[] | null>
    = this.store.pipe(select(getInterventions));
  public isLoading$: Observable<boolean>
    = this.select(SelectorUtil.isLoading);

  public create = this.effect<CfIntervention>((params$) => (
    params$.pipe(
      withLatestFrom(this.interventions$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([intervention, interventions]) =>
        this.interventionApi.create(intervention)
          .pipe(
            tapResponse(
              (response: ResponseState<CfIntervention>) => {
                const newInterventions: CfIntervention[] = interventions ? [ ...interventions ] : [];
                newInterventions.push(response.payload);
                this.store.dispatch(
                  loadInterventionsSuccess({
                    interventions: newInterventions
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'create');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'create');
              }
            )
          )
      )
    )
  ));

  public update = this.effect<{ interventionId: string, intervention: CfIntervention }>((params$) => (
    params$.pipe(
      withLatestFrom(this.interventions$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ interventionId, intervention}, interventions]) =>
        this.interventionApi.update(interventionId, intervention)
          .pipe(
            tapResponse(
              (response: ResponseState<CfIntervention>) => {
                const newInterventions: CfIntervention[] = interventions ? [ ...interventions ] : [];

                const index: number = newInterventions
                  .findIndex((x) => x.id === response.payload.id);
                newInterventions[index] = { ...response.payload };

                this.store.dispatch(
                  loadInterventionsSuccess({
                    interventions: newInterventions
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'update');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'update');
              }
            )
          )
      )
    )
  ));

  public delete = this.effect<{ interventionId: string }>((params$) => (
    params$.pipe(
      withLatestFrom(this.interventions$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ interventionId}, interventions]) =>
        this.interventionApi.delete(interventionId)
          .pipe(
            tapResponse(
              (response: ResponseState<CfIntervention>) => {
                const newInterventions: CfIntervention[] = interventions
                  ? [ ...interventions ].filter((x) => x.id !== interventionId)
                  : [];

                this.store.dispatch(
                  loadInterventionsSuccess({
                    interventions: newInterventions
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'delete');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'delete');
              }
            )
          )
      )
    )
  ));

  private notify(state: string, action: string): void {
    this.notificationService.create(
      state,
      this.translateService.instant(`notifications.${state}`),
      this.translateService.instant(`interventions.notifications.${action}.${state}_message`)
    );
  }

  constructor(
    private store: Store<InterventionsState>,
    private interventionApi: InterventionApi,
    private translateService: TranslateService,
    private notificationService: NzNotificationService
  ) {
    super(<InterventionState>{})
  }
}
