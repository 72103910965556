import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {RolesState} from "./roles.reducer";
import {getRoles, getRolesLoading} from "./roles.selectors";
import {loadRoles} from "./roles.actions";
import {Observable} from "rxjs";
import {CfRole} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class RolesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getRolesLoading));
  public roles$: Observable<CfRole[] | null>
    = this.store.pipe(select(getRoles));

  constructor(
    private store: Store<RolesState>
  ) { }

  public loadRoles(): void {
    this.store.dispatch(loadRoles());
  }
}
