import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {
  getOrganizationsLoading,
  loadOrganizations,
  OrganizationsState,
  OrganizationStore
} from "@app-web-central/web/organization/data-access";
import {CfOrganization} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class OrganizationsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getOrganizationsLoading));
  public organization$: Observable<CfOrganization>
    = this.organizationStore.organization$;

  constructor(
    private store: Store<OrganizationsState>,
    private organizationStore: OrganizationStore
  ) { }

  public loadOrganizations(): void {
    this.store.dispatch(loadOrganizations());
  }

  public update(organization: CfOrganization): void {
    this.organizationStore.update(organization);
  }

}
