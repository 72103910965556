import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfComment, CfTask, ResponseState} from "@app-web-central/web/shared/data-access/models";

export interface TaskAssignees {
  assignee: string;
  modifiedBy: string;
  eventIds: string[];
}

export interface TaskManyAssignee {
  assigneeIds: string[];
  modifiedBy: string;
  eventIds: string[];
}

@Injectable({ providedIn: 'root' })
export class TaskApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _http: HttpClient
  ) { }

  /**
   * Get task by id.
   *
   * @param taskId as { *string }.
   */
  get(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}`
    );
  }

  /**
   * Get list of tasks from service api.
   */
  list() {
    return this._http.get<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events`
    );
  }

  /**
   * Create a task.
   *
   * @param task the task as { CfTask }
   */
  create(task: CfTask) {
    return this._http.post<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events`, task
    );
  }

  /**
   * Update the current task.
   *
   * @param taskId the task id as { string }
   * @param task the task as { CfTask }
   */
  update(taskId: string, task: CfTask) {
    return this._http.put<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}`, task
    );
  }

  /**
   * Update multiple tasks at once.
   *
   * @param eventIds the list of task ids.
   * @param task the task to update.
   */
  updateAll(eventIds: string[], task: CfTask) {
    return this._http.put<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events/bulk/update`, { eventIds, instance: task }
    );
  }

  /**
   * Update the current task status.
   *
   * @param taskId the task id as { string }
   * @param task the task as { CfTask }
   */
  updateStatus(taskId: string, task: CfTask) {
    return this._http.put<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/set?status=${task.status}`, null
    );
  }

  /**
   * Start a task. The task will be set as IN_TRANSIT state.
   *
   * @param taskId the task id as { string }.
   */
  start(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/start`
    );
  }

  /**
   * Hold a task. the task will stay in progress but a step will be added.
   *
   * @param taskId the task id as { string }.
   */
  hold(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/hold`
    );
  }

  /**
   * Validate a task. The task will be set as Completed.
   *
   * @param taskId the task id as { string }.
   */
  validate(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/validate`
    );
  }

  /**
   * Delete a task by id.
   *
   * @param taskId the task id as { string }
   */
  delete(taskId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}`
    );
  }

  /**
   * Delete a batch of task by ids.
   *
   * @param taskIds as { string[] }
   */
  deleteBulk(taskIds: string[]) {
    return this._http.post<ResponseState<string>>(
      `${this._appConfig.tasksV1Url}manage/events/bulk/delete`, taskIds
    );
  }

  /**
   * Enable a task by id.
   *
   * @param taskId the task id as { string }
   */
  enable(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/enable`
    );
  }

  /**
   * Enable a batch of task by ids.
   *
   * @param taskIds as { string[] }
   */
  enableBulk(taskIds: string[]) {
    return this._http.post<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events/bulk/enable`, taskIds
    );
  }

  /**
   * Disable a task by id.
   *
   * @param taskId the task id as { string }
   */
  disable(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/disable`
    );
  }

  /**
   * Disable a batch of task by ids.
   *
   * @param taskIds as { string[] }
   */
  disableBulk(taskIds: string[]) {
    return this._http.post<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events/bulk/disable`, taskIds
    );
  }

  /**
   * Assign a list of task Ids to an agent.
   *
   * @param assignee the task assignment as { TaskAssignees }
   */
  assignees(assignee: any) {
    return this._http.put<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events/assign/${assignee.assignee}/modify/${assignee.modifiedBy}`, assignee.eventIds
    );
  }

  /**
   * Assign a list of task Ids to many agents.
   *
   * @param assignee the task assignment as { TaskAssignees }
   */
  assigneeMany(assignee: TaskManyAssignee) {
    return this._http.put<ResponseState<CfTask[]>>(
      `${this._appConfig.tasksV1Url}manage/events/assign/modify/${assignee.modifiedBy}`, {
        eventIds: assignee.eventIds,
        assigneeIds: assignee.assigneeIds,
      }
    );
  }

  /**
   * Unassigned all agents from a task by id.
   *
   * @param taskId the task id as { string }
   */
  unassigned(taskId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/unassigned`
    );
  }

  /**
   * Unassigned an agent from a task by id.
   *
   * @param taskId the task id as { string }
   * @param assigneeId the user to unassigned as { string }.
   */
  unassignedOne(taskId: string, assigneeId: string) {
    return this._http.get<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/unassigned/${assigneeId}`
    );
  }

  /**
   * Add tags to the current task.
   *
   * @param taskId the current task id as { uuid }.
   * @param tags th tags as list of { string }.
   */
  tags(taskId: string, tags: string[]) {
    return this._http.post<ResponseState<CfTask>>(
      `${this._appConfig.tasksV1Url}manage/events/${taskId}/tags`, tags
    );
  }

  /**
   * Add a new comment to the task.
   *
   * @param comment the comment as { CfComment }
   */
  addComment(comment: CfComment) {
    return this._http.post<ResponseState<CfComment[]>>(
      `${this._appConfig.tasksV1Url}manage/events/comments`, comment
    );
  }

  /**
   * Update a comment by id.
   *
   * @param commentId the comment id as { string }
   * @param comment the comment as { CfComment }
   */
  editComment(commentId: string, comment: CfComment) {
    return this._http.put<ResponseState<CfComment[]>>(
      `${this._appConfig.tasksV1Url}manage/events/comments/${commentId}`, comment
    );
  }

  /**
   * Delete a comment by id.
   *
   * @param commentId the comment id as { string }
   */
  removeComment(commentId: string) {
    return this._http.delete<ResponseState<CfComment[]>>(
      `${this._appConfig.tasksV1Url}manage/events/comments/${commentId}`
    );
  }

  /**
   * Like a comment by id and user id.
   *
   * @param commentId the comment id as { string }.
   * @param userId the user id as { string }.
   */
  like(commentId: string, userId: string) {
    return this._http.get<ResponseState<CfComment>>(
      `${this._appConfig.tasksV1Url}manage/events/comments/${userId}/like/${commentId}`
    );
  }

  /**
   * Dislike a comment by id and user id.
   *
   * @param commentId the comment id as { string }.
   * @param userId the user id as { string }.
   */
  dislike(commentId: string, userId: string) {
    return this._http.get<ResponseState<CfComment>>(
      `${this._appConfig.tasksV1Url}manage/events/comments/${userId}/dislike/${commentId}`
    );
  }

  /**
   * Get all comments by event id.
   *
   * @param taskId the task id as { string }.
   */
  listCommentsByTaskId(taskId: string) {
    return this._http.get<ResponseState<CfComment[]>>(
      `${this._appConfig.tasksV1Url}manage/events/comments/${taskId}/event`
    );
  }

}
