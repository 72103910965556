<header class="flex-shrink-0 relative h-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-white/20 flex items-center">
  <!--  <as-nav-bar-logo-area-->
  <!--    *ngIf="logoArea">-->
  <!--  </as-nav-bar-logo-area>-->
  <!--  <as-nav-bar-picker-area-->
  <!--    class="mx-auto lg:hidden">-->
  <!--  </as-nav-bar-picker-area>-->
  <!--  <as-nav-bar-burger-area-->
  <!--    (manualToggle)="toggle()">-->
  <!--  </as-nav-bar-burger-area>-->
  <as-nav-bar-desktop-menu
    [hasBreadcrumbs]="hasBreadcrumbs"
    [hasCreationButton]="hasCreationButton"
    class="nav-bar-desktop-menu">
  </as-nav-bar-desktop-menu>
  <!--  <as-nav-bar-mobile-menu-->
  <!--    *ngIf="expanded"-->
  <!--    (manualToggle)="toggle()">-->
  <!--  </as-nav-bar-mobile-menu>-->
</header>
