import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './alert.component';
import {TranslateModule} from "@ngx-translate/core";
import {IconModule} from "@app-web-central/web/shared/ui/icon";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";

@NgModule({
  imports: [
    IconModule,
    ButtonModule,
    CommonModule,
    TranslateModule,
  ],
  declarations: [
    AlertComponent
  ],
  exports: [
    AlertComponent
  ],
})
export class AlertModule {
}
