import {HandlerWithArgs} from "./handler";

type Variant = 'default' | 'twotone' | 'solid' | 'plain';
type Size = 'xs' | 'sm' | 'md' | 'lg';

export class BtnItem {
  constructor(
    public label: string,
    public variant: Variant,
    public size: Size,
    public icon: string,
    public handler: HandlerWithArgs,
    public tooltip?: boolean
  ) { }
}

export class CalBtnItem {
  constructor(
    public label: string | null,
    public variant: Variant,
    public size: Size,
    public icon: string | null,
    public handler: HandlerWithArgs,
    public tooltip?: boolean
  ) { }
}
