<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'recurring.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form [formGroup]="recurringForm" (ngSubmit)="submit()" class="w-full max-w-lg">
    <input type="hidden" formControlName="dateStart">

    <label for="interval" class="label">
      {{ 'recurring.every' | translate }}
    </label>

    <div class="flex -mx-3 mb-2">
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <input
          id="interval"
          type="number"
          formControlName="interval"
          class="form-input"/>
      </div>

      <div class="w-full px-3 mb-6 md:mb-0">
        <nz-select
          id="frequency"
          formControlName="frequency"
          (nzBlur)="onBlurFrequency()"
          (ngModelChange)="onChangeFrequency()">
          <nz-option
            *ngFor="let frequency of frequencies"
            nzLabel="{{ 'calendar.frequencies.' + frequency.label | translate }}"
            [nzValue]="frequency.value">
          </nz-option>
        </nz-select>
      </div>
    </div><!-- end of interval block -->

    <div class="flex pb-3" *ngIf="isWeeklySelected">
      <div class="flex flex-row justify-center flex-wrap">
        <label
          *ngFor="let byWeekDay of byWeekDayNames"
          class="mr-2 text-gray-800 flex items-center"
          [ngClass]="{'is-active': byWeekDay.checked}">
          <input
            autocomplete="off"
            type="checkbox"
            class="mr-1"
            [checked]="byWeekDay.checked"
            value="{{ byWeekDay.value }}"
            (click)="onSelectByWeekDay($event)">
          {{ 'calendar.week_days.' + byWeekDay.label | translate }}
        </label>
      </div>
    </div><!-- end of weekly -->

    <div class="flex flex-1 pb-3" *ngIf="isMonthlySelected">
      <nz-radio-group
        class="!flex !flex-col w-full"
        [formControl]="monthlyChoiceControl"
        (ngModelChange)="onChangeRadioMonthly($event)">
        <label nz-radio nzValue="m_day" class="mt-1 flex flex-1 flex-row">
          <span>{{ 'calendar.keywords.the' | translate }}</span>
          <nz-select
            id="byMonthDay"
            class="mx-2 w-auto"
            nzPlaceHolder="1"
            [nzDisabled]="monthlyChoiceControl.value !== 'm_day'"
            [style.min-width.px]="100"
            formControlName="byMonthDay">
            <nz-option
              *ngFor="let day of monthDays"
              [nzLabel]="day"
              [nzValue]="day">
            </nz-option>
          </nz-select>
          <span>{{ 'calendar.keywords.every_month' | translate }}</span>
        </label>

        <label nz-radio nzValue="m_week" class="mt-1 flex flex-1 flex-row">
          <span>{{ 'calendar.keywords.the' | translate }}</span>
          <nz-select
            id="bySetPosition"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.week_day_pos.' + bySetDayPositions[0].label | translate }}"
            [nzDisabled]="monthlyChoiceControl.value !== 'm_week'"
            [style.min-width.px]="100"
            formControlName="bySetPos">
            <nz-option
              *ngFor="let bySetPos of bySetDayPositions"
              nzLabel="{{ 'calendar.week_day_pos.' + bySetPos.label | translate }}"
              [nzValue]="bySetPos.value">
            </nz-option>
          </nz-select>

          <nz-select
            id="byWeekDay"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.week_days.' + byWeekDayNames[0].label | translate }}"
            [nzDisabled]="monthlyChoiceControl.value !== 'm_week'"
            [style.min-width.px]="100"
            formControlName="byWeekDay">
            <nz-option
              *ngFor="let byWeekDay of byWeekDayNames"
              nzLabel="{{ 'calendar.week_days.' + byWeekDay.label | translate }}"
              [nzValue]="byWeekDay.value">
            </nz-option>
          </nz-select>

        </label>
      </nz-radio-group>
    </div><!-- end of monthly -->

    <div class="flex pb-3" *ngIf="isYearlySelected">
      <nz-radio-group
        class="!flex !flex-col w-full"
        [formControl]="yearlyChoiceControl"
        (ngModelChange)="onChangeRadioYearly($event)">
        <label nz-radio nzValue="y_month" class="mt-1 flex flex-1 flex-row">
          <span>{{ 'calendar.keywords.in' | translate }}</span>
          <nz-select
            id="byMonth"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.months.' + byMonthNames[0].label | translate }}"
            [nzDisabled]="yearlyChoiceControl.value !== 'y_month'"
            [style.min-width.px]="100"
            formControlName="byMonth">
            <nz-option
              *ngFor="let byMonth of byMonthNames"
              nzLabel="{{ 'calendar.months.' + byMonth.label | translate }}"
              [nzValue]="byMonth.value">
            </nz-option>
          </nz-select>
          <span class="ml-2">{{ 'calendar.keywords.the' | translate }}</span>
          <nz-select
            id="byMonthDate"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ monthDays[0] }}"
            [nzDisabled]="yearlyChoiceControl.value !== 'y_month'"
            [style.min-width.px]="100"
            formControlName="byMonthDay">
            <nz-option
              *ngFor="let day of monthDays"
              [nzLabel]="day"
              [nzValue]="day">
            </nz-option>
          </nz-select>
        </label>

        <label nz-radio nzValue="y_frequency" class="mt-1 flex flex-1 flex-row">
          <span>{{ 'calendar.keywords.the' | translate }}</span>
          <nz-select
            id="bySetPositionForYear"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.week_day_pos.' + bySetDayPositions[0].label | translate }}"
            [nzDisabled]="yearlyChoiceControl.value !== 'y_frequency'"
            [style.min-width.px]="75"
            formControlName="bySetPos">
            <nz-option
              *ngFor="let bySetPos of bySetDayPositions"
              nzLabel="{{ 'calendar.week_day_pos.' + bySetPos.label | translate }}"
              [nzValue]="bySetPos.value">
            </nz-option>
          </nz-select>

          <nz-select
            id="byWeekDayForYear"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.week_days.' + byWeekDayNames[0].label | translate }}"
            [nzDisabled]="yearlyChoiceControl.value !== 'y_frequency'"
            [style.min-width.px]="100"
            formControlName="byWeekDay">
            <nz-option
              *ngFor="let byWeekDay of byWeekDayNames"
              nzLabel="{{ 'calendar.week_days.' + byWeekDay.label | translate }}"
              [nzValue]="byWeekDay.value">
            </nz-option>
          </nz-select>

          <nz-select
            id="byMonthForYear"
            class="ml-2 w-auto"
            nzPlaceHolder="{{ 'calendar.months.' + byMonthNames[0].label | translate | slice:0:3 }}"
            [nzDisabled]="yearlyChoiceControl.value !== 'y_frequency'"
            [style.min-width.px]="75"
            formControlName="byMonth">
            <nz-option
              *ngFor="let byMonth of byMonthNames"
              nzLabel="{{ 'calendar.months.' + byMonth.label | translate | slice:0:3 }}"
              [nzValue]="byMonth.value">
            </nz-option>
          </nz-select>

        </label>
      </nz-radio-group>
    </div><!-- end of yearly -->

    <label for="interval" class="label pb-0">
      {{ 'recurring.ends' | translate }}
    </label>

    <nz-radio-group
      class="!flex !flex-col !flex-1"
      [formControl]="endChoiceControl"
      (ngModelChange)="onChangeRadioEnds($event)">
      <label nz-radio nzValue="never">
        {{ 'recurring.never' | translate }}
      </label>

      <label nz-radio nzValue="after" class="mt-1">
        <span [style.min-width.rem]="4" class="mr-2 inline-block">
          {{ 'recurring.after' | translate }}
        </span>
        <input
          type="number"
          formControlName="count"
          placeholder="{{ 'recurring.after_placeholder' | translate }}"
          class="form-input"/>
      </label>

      <label nz-radio nzValue="until" class="mt-1">
        <span [style.min-width.rem]="4" class="mr-2 inline-block">
          {{ 'recurring.until' | translate }}
        </span>
        <nz-date-picker
          [nzDisabled]="endChoiceControl.value !== 'until'"
          nzFormat="{{ 'yyyy-MM-dd' }}"
          formControlName="until">
        </nz-date-picker>
      </label>
    </nz-radio-group>

    <label
      nz-checkbox
      class="mt-3"
      formControlName="excludeWeekend"
      (ngModelChange)="onChangeExcludeWeekend($event)">
      {{ 'recurring.exclude_weekend' | translate }}
    </label>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="recurringForm.invalid"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>

</div>
