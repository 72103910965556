import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {CfCategory, GenericState, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {select, Store} from "@ngrx/store";
import {SelectorUtil} from "@app-web-central/web/shared/utils";
import {getCategories, CategoriesState, loadCategoriesSuccess} from "../categories";
import {CategoryApi} from "../../../../../shared/data-access/stouds-api/src/lib/category-api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";

interface CategoryState extends GenericState<CfCategory> {
  categoryId: string;
}

@Injectable({ providedIn: 'root' })
export class CategoryStore extends ComponentStore<CategoryState> {
  public categories$: Observable<CfCategory[] | null>
    = this.store.pipe(select(getCategories));
  public isLoading$: Observable<boolean>
    = this.select(SelectorUtil.isLoading);

  public categoriesForSelect$: Observable<{ label: string, value: CfCategory }[]> = this.categories$.pipe(
    map((categories) => categories
      ? categories.map((category) => ({
        label: category.name,
        value: category
      }))
      : []
    )
  );

  public create = this.effect<CfCategory>((params$) => (
    params$.pipe(
      withLatestFrom(this.categories$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([category, categories]) =>
        this.categoryApi.create(category)
          .pipe(
            tapResponse(
              (response: ResponseState<CfCategory>) => {
                const newCategories: CfCategory[] = categories ? [ ...categories ] : [];
                newCategories.push(response.payload);

                this.store.dispatch(
                  loadCategoriesSuccess({
                    categories: newCategories
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'create');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'create');
              }
            )
          )
      )
    )
  ));

  public update = this.effect<{ categoryId: string, category: CfCategory }>((params$) => (
    params$.pipe(
      withLatestFrom(this.categories$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ categoryId, category}, categories]) =>
        this.categoryApi.update(categoryId, category)
          .pipe(
            tapResponse(
              (response: ResponseState<CfCategory>) => {
                const newCategories: CfCategory[] = categories ? [ ...categories ] : [];

                const index: number = newCategories
                  .findIndex((x) => x.id === categoryId);
                newCategories[index] = { ...response.payload };

                this.store.dispatch(
                  loadCategoriesSuccess({
                    categories: newCategories
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'update');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'update');
              }
            )
          )
      )
    )
  ));

  public delete = this.effect<{ categoryId: string }>((params$) => (
    params$.pipe(
      withLatestFrom(this.categories$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ categoryId}, categories]) =>
        this.categoryApi.delete(categoryId)
          .pipe(
            tapResponse(
              (response: ResponseState<CfCategory>) => {
                const newCategories: CfCategory[] = categories
                  ? [ ...categories ].filter((x) => x.id !== categoryId)
                  : [];

                this.store.dispatch(
                  loadCategoriesSuccess({
                    categories: newCategories
                  })
                );
                this.patchState({
                  data: response.payload,
                  status: 'success',
                  error: ''
                });

                this.notify('success', 'delete');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });

                this.notify('error', 'delete');
              }
            )
          )
      )
    )
  ));

  private notify(state: string, action: string): void {
    this.notificationService.create(
      state,
      this.translateService.instant(`notifications.${state}`),
      this.translateService.instant(`categories.notifications.${action}.${state}_message`)
    );
  }

  constructor(
    private categoryApi: CategoryApi,
    private store: Store<CategoriesState>,
    private translateService: TranslateService,
    private notificationService: NzNotificationService
  ) {
    super(<CategoryState>{})
  }
}
