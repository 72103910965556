<nz-select
  class="multi-selection assignees"
  nzAllowClear
  [nzMaxTagCount]="3"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  nzMode="multiple"
  [nzNotFoundContent]="noUserFoundTmpl"
  [nzCustomTemplate]="assigneesTpl"
  nzPlaceHolder="{{ 'task_detail.add_assignee' | translate }}"
  [formControl]="control">
  <nz-option
    *ngFor="let item of users"
    [nzLabel]="item.fullName"
    [nzValue]="item">
  </nz-option>
</nz-select>

<ng-template #tagPlaceHolder let-selectedList>
  {{ 'constants.and_more' | translate: { param: selectedList.length } }}
</ng-template>

<ng-template #assigneesTpl let-selected>
  <div class="ant-select-selection-item-content">
    <as-user
      [user]="selected.nzValue"
      [imageOnly]="true">
    </as-user>
  </div>
</ng-template>

<ng-template #noUserFoundTmpl>
  {{ 'add_task.assignee_not_found' | translate }}
</ng-template>
