import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {getProjects, getProjectsLoading, loadProjects, ProjectsState} from "@app-web-central/web/project/data-access";
import {Observable} from "rxjs";
import {CfProject} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ProjectsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getProjectsLoading));
  public readonly projects$: Observable<CfProject[] | null>
    = this.store.pipe(select(getProjects));

  constructor(
    private store: Store<ProjectsState>,
  ) { }

  public loadProjects(): void {
    this.store.dispatch(loadProjects());
  }
}
