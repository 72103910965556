<div class="mt-3">
  <h2 class="pb-2 text-sm font-sans text-gray-900">{{ 'task_detail.schedule' | translate }}</h2>

  <div class="flex flex-col">
    <div class="flex flex-row">
      <nz-date-picker
        class="mr-1 w-full"
        [nzDisabled]="!task.enabled || !!task.dates.validationDate"
        [nzShowTime]="{ nzFormat: session.settings.timeFormat }"
        [nzFormat]="session.settings.dateFormatFull"
        [formControl]="startDate"
        nzPlaceHolder="Start"
        (ngModelChange)="handleStartOpenChange($event)"
      ></nz-date-picker>
      <nz-date-picker
        class="ml-1 w-full"
        [nzDisabled]="!task.enabled || !!task.dates.validationDate"
        [nzShowTime]="{ nzFormat: session.settings.timeFormat }"
        [nzFormat]="session.settings.dateFormatFull"
        [formControl]="endDate"
        nzPlaceHolder="End"
        (ngModelChange)="handleEndOpenChange($event)"
      ></nz-date-picker>
    </div>
    <div class="flex flex-row mt-3 items-center">
      <label
        nz-checkbox
        class="ml-1"
        [nzDisabled]="!!task.dates?.validationDate"
        [formControl]="allDay"
        (ngModelChange)="handleOnAllDay($event)">
        {{ 'task_detail.all_day' | translate }}
      </label>
      <as-button
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        variant="default"
        [nzDisabled]="!!task.dates?.validationDate"
        class="ml-3 uppercase text-sm">
        <span *ngIf="isRecurring">{{ 'task_detail.recurrent' | translate }}</span>
        <span *ngIf="!isRecurring">{{ 'task_detail.not_recurrent' | translate }}</span>
      </as-button>
    </div>
    <div
      *ngIf="task && task.isRecurring"
      (click)="onEditRecurrence()"
      class="recurrence uppercase text-xs mt-3">
      <span class="rrule">{{ task.recurrence.rule }}</span>
    </div>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="dropdown">
    <li
      nz-menu-item
      class="dropdown-item"
      (click)="handleOnResetRecurring()">
      {{ 'task_detail.not_recurrent' | translate }}
    </li>
    <li
      nz-menu-item
      class="dropdown-item"
      (click)="handleOnRecurring()">
      {{ 'task_detail.recurrent' | translate }}
    </li>
  </ul>
</nz-dropdown-menu>
