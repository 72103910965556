import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {CfCategory} from "@app-web-central/web/shared/data-access/models";
import {select, Store} from "@ngrx/store";
import {getCategories, getCategoriesLoading} from "./categories.selectors";
import {CategoriesState} from "./categories.reducer";
import {loadCategories} from "./categories.actions";

@Injectable({ providedIn: 'root' })
export class CategoriesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getCategoriesLoading));
  public categories$: Observable<CfCategory[] | null>
    = this.store.pipe(select(getCategories));

  constructor(
    private store: Store<CategoriesState>
  ) { }

  public loadCategories(): void {
    this.store.dispatch(loadCategories());
  }
}
