import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {
  BreadcrumbsLink, CfAttachment,
  CfFolder,
  CfUIFolder,
  CfUser,
  GenericState
} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {filter, map, Observable, withLatestFrom} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import {FileApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {select, Store} from "@ngrx/store";
import {LocalNotificationService} from "@app-web-central/web/shared/services/local-notification";
import {FileUtil, FolderUtil, RouteUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {UsersFacade} from "@app-web-central/web/user/data-access";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "@app-web-central/web/shared/services/local-storage";
import {FoldersState, getFolders, loadFoldersSuccess} from "../folders";
import {AuthFacade} from "@app-web-central/web/auth/data-access";

export const STARRED_FOLDERS_KEY = 'STARRED_FOLDERS';

interface FolderState extends GenericState<CfFolder> {
  folderId: string;
  folderKey: string;
}

@Injectable({ providedIn: 'root' })
export class FolderStore extends ComponentStore<FolderState> {
  isLoading$ = this.select(SelectorUtil.isLoading);
  folders$ = this._store.pipe(select(getFolders));
  session$ = this.authFacade.user$;
  users$ = this._usersFacade.users$;

  folderIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["folderKey"]),
    filter((folderKey: string) => !!folderKey)
  );

  folder$ = this.folderIdParams$.pipe(
    tap((folderKey) => {
      this.patchState({
        folderKey
      });
      this.loadFolder({ folderKey });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadFolder = this.effect<{ folderKey: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ folderKey }) =>
        this._fileApi.getByKey(folderKey).pipe(
          tapResponse(
            (response) => {
              const folder = { ...response.payload };
              this.patchState({
                data: folder,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  createFolder = this.effect<CfFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$, this.session$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([folder, folders, session]) => {
          if (session) {
            folder.owner = session.id;
          }
          return this._fileApi.create(folder)
            .pipe(
              tapResponse((response) => {
                const newFolder = { ...response.payload };
                const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
                this._store.dispatch(
                  loadFoldersSuccess({
                    folders: newFolders
                  })
                );
                this.patchState({
                  data: newFolder,
                  status: 'success',
                  error: ''
                });
                this._notify(true, 'create');
              }, (error) => {
                this.patchState({
                  status: 'error',
                  error: error as unknown as string
                });
                this._notify(false, 'create', error);
              })
            )
        }
      )
    )
  );

  updateFolder = this.effect<CfFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([folder, folders]) => this._fileApi.update(folder.id, folder)
        .pipe(
          tapResponse((response) => {
            const newFolder = { ...response.payload };
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'update');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'update', error);
          })
        )
      )
    )
  );

  updateFile = this.effect<CfAttachment>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([file, folders]) => this._fileApi.updateFile(file)
        .pipe(
          tapResponse((response) => {
            const newFile = { ...response.payload };
            const folder = folders?.find((f) => f.id === newFile.folderId);
            const folderIdx = folders?.findIndex((f) => f.id === newFile.folderId);
            if (folder && folders) {
              const files = [ ...folder.files ];
              const fileIdx = files.findIndex((f) => f.id === file.id);
              files[fileIdx] = newFile;
              folder.files = [ ...files ];
              if (folderIdx) {
                folders[folderIdx] = { ...folder };
              }

              this._store.dispatch(
                loadFoldersSuccess({
                  folders: folders
                })
              );
              this.patchState({
                data: folder,
                status: 'success',
                error: ''
              });
              this._notify(true, 'update');
            }
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'update', error);
          })
        )
      )
    )
  )


  deleteFile = this.effect<{ file: CfAttachment, folder: CfFolder }>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{ file, folder }, folders]) => this._fileApi.delete(file.filename)
        .pipe(
          tapResponse(() => {
            const newFolder = { ...folder };
            const files = [ ...newFolder.files ];
            const index = files.findIndex((f: CfAttachment) => f.id === file.id);
            files.splice(index, 1);
            newFolder.files = [ ...files ];
            const newFolders = folders != null ? [...folders] : [];
            const idx = newFolders.findIndex((f: CfFolder) => f.id === newFolder.id);
            newFolders[idx] = newFolder;
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'update');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'update', error);
          })
        )
      )
    )
  );

  inviteMember = this.effect<{ member: CfUser | any, folderId: string }>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([{member, folderId}, folders]) => this._fileApi.invite(member, folderId)
        .pipe(
          tapResponse((response) => {
            const newFolder = { ...response.payload };
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'invite');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'invite', error);
          })
        )
      )
    )
  );

  joinFolder = this.effect<{ folderId: string, validationCode: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ folderId, validationCode }) => this._fileApi.accept(folderId, validationCode)
        .pipe(
          tapResponse(() => {
            this._router.navigate([RouteUtil.getFoldersRouteUrl()]);
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'accept-invite', error);
          })
        )
      )
    )
  );

  addStarredFolder = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(([uiFolder, folders]) => {
        const folder = folders?.find((f) => f.id === uiFolder.name.id);
        if (folder) {
          this._localStorageAddToStarred(folder);
          const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, folder);
          this._store.dispatch(
            loadFoldersSuccess({
              folders: newFolders
            })
          );
          this.patchState({
            data: folder,
            status: 'success',
            error: ''
          });
        }
      }, (error) => {
        this.patchState({
          status: 'error',
          error: error as unknown as string
        });
      })
    )
  );

  removeStarredFolder = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(([uiFolder, folders]) => {
        const folder = folders?.find((f) => f.id === uiFolder.name.id);
        if (folder) {
          this._localStorageRemoveFromStarred(folder);
          const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, folder);
          this._store.dispatch(
            loadFoldersSuccess({
              folders: newFolders
            })
          );
          this.patchState({
            data: folder,
            status: 'success',
            error: ''
          });
        }
      }, (error) => {
        this.patchState({
          status: 'error',
          error: error as unknown as string
        });
      })
    )
  );

  emptyBin = this.effect((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([_, folders]) => this._fileApi.deleteAll()
        .pipe(
          tapResponse(() => {
            const newFolders = FolderUtil.removeAllTrashedFoldersAndGetNewFolders(folders);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: null,
              status: 'success',
              error: ''
            });
            this._notify(true, 'restore');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'restore', error);
          })
        )
      )
    )
  );

  folderRestore = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => this._fileApi.folderRestore(uiFolder.name.id)
        .pipe(
          tapResponse((response) => {
            const newFolder = { ...response.payload };
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'restore');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'restore', error);
          })
        )
      )
    )
  );

  folderMoveToTrash = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => {
        return this._fileApi.folderTrash(uiFolder.name.id).pipe(
          tapResponse((response) => {
            const newFolder = { ...response.payload };
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'trash');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'trash', error);
          })
        )
      })
    )
  );

  folderRemoveForever = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => this._fileApi.folderDelete(uiFolder.name.id)
        .pipe(
          tapResponse(() => {
            const newFolder = FolderUtil.findFolderById(folders, uiFolder.name.id);
            const newFolders = FolderUtil.removeFolderAndGetNewFolders(folders, uiFolder.name.id);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'delete');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'delete', error);
          })
        )
      )
    )
  );

  fileMoveToTrash = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => this._fileApi.fileMoveToTrash(uiFolder.name.id)
        .pipe(
          tapResponse((response) => {
            const newFile = { ...response.payload };
            const newFolder = FolderUtil.replaceFileInFolder(folders, newFile);
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'trash');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'trash', error);
          })
        )
      )
    )
  );

  fileRestore = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => this._fileApi.fileRestore(uiFolder.name.id)
        .pipe(
          tapResponse((response) => {
            const newFile = { ...response.payload };
            const newFolder = FolderUtil.replaceFileInFolder(folders, newFile);
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'restore');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'restore', error);
          })
        )
      )
    )
  );

  fileRemoveForever = this.effect<CfUIFolder>((params$) =>
    params$.pipe(
      withLatestFrom(this.folders$),
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(([uiFolder, folders]) => this._fileApi.fileDeleteById(uiFolder.name.id)
        .pipe(
          tapResponse(() => {
            const newFolder = FolderUtil.removeFileInFolder(folders, uiFolder.name.id, uiFolder.name.folder.id);
            const newFolders = FolderUtil.replaceFolderAndGetNewFolders(folders, newFolder);
            this._store.dispatch(
              loadFoldersSuccess({
                folders: newFolders
              })
            );
            this.patchState({
              data: newFolder,
              status: 'success',
              error: ''
            });
            this._notify(true, 'delete');
          }, (error) => {
            this.patchState({
              status: 'error',
              error: error as unknown as string
            });
            this._notify(false, 'delete', error);
          })
        )
      )
    )
  );

  folderBreadcrumbs$ = (folder: CfFolder): Observable<BreadcrumbsLink[]> => this.folders$.pipe(
    map((folders) => {
      const breadcrumbs: BreadcrumbsLink[] = [];
      breadcrumbs.push(new BreadcrumbsLink(folder.name, RouteUtil.getFolderRouteUrl(folder.identifier)));
      if (folder && folders) {
        this._hasParentFolder(folder, folders, breadcrumbs);
      }
      breadcrumbs.push(new BreadcrumbsLink('components.folder.my_folders', RouteUtil.getFoldersRouteUrl()));
      return breadcrumbs.reverse();
    })
  );

  _hasParentFolder(folder: CfFolder, folders: CfFolder[], breadcrumbs: BreadcrumbsLink[]) {
    if (folder && folder.parentId) {
      const parentFolder = folders?.find((x) => x.id === folder.parentId);
      if (parentFolder) {
        breadcrumbs.push(new BreadcrumbsLink(parentFolder.name, RouteUtil.getFolderRouteUrl(parentFolder.identifier)));
        if (parentFolder.parentId) {
          this._hasParentFolder(parentFolder, folders, breadcrumbs);
        }
      }
    }
  }

  folderAsUIFolder$ = (folderId: string): Observable<CfUIFolder | null> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      const folder = folders !== null ? folders.find((f) => f.id === folderId) : null;
      if (folder) {
        return FileUtil.buildUIFolder(folder, users, session);
      } else {
        return null;
      }
    })
  );

  foldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      return folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && !x.trashed
          && (!x.parentId || x.members.some((u) => u.member === session?.id) && x.owner !== session?.id))
        .map((folder) => FileUtil.buildUIFolder(folder, users, session))
    })
  );

  myFoldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      return folders
        .filter((x) =>
          x.owner === session?.id
          && x.private
          && !x.trashed
          && !x.parentId
        )
        .map((folder) => FileUtil.buildUIFolder(folder, users, session))
    })
  );

  sharedFoldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      return folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && !x.trashed
          && x.members.some((u) => u.member === session?.id) && x.owner !== session?.id)
        .map((folder) => FileUtil.buildUIFolder(folder, users, session))
    })
  );

  recentFoldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      const foldersUI = folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && !x.trashed)
        .map((folder) => FileUtil.buildUIFolder(folder, users, session));
      const filesUI = folders
        .filter((f) => f.files && f.files.length && (f.owner === session?.id
          || !f.private
          || f.members.some((u) => u.member === session?.id)))
        .flatMap((folder) => folder.files
          .filter((file) => !file.trashed)
          .map((file) => FileUtil.buildUIFile(folder, users, session, file))) as CfUIFolder[];
      return [...foldersUI, ...filesUI].sort((a, b) => b.dateUpd - a.dateUpd);
    })
  );

  starredFoldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      return folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && !x.trashed
          && x.starred)
        .map((folder) => FileUtil.buildUIFolder(folder, users, session))
    })
  );

  trashedFoldersAsUIFolders$ = (): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      const foldersUI = folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && x.trashed)
        .map((folder) => FileUtil.buildUIFolder(folder, users, session))
      const filesUI = folders
        .filter((f) => (f.owner === session?.id
          || !f.private
          || f.members.some((u) => u.member === session?.id))
          && f.files && f.files.length)
        .flatMap((folder) => folder.files
          .filter((file) => file.trashed)
          .map((file) => FileUtil.buildUIFile(folder, users, session, file))) as CfUIFolder[];
      return [...foldersUI, ...filesUI];
    })
  );

  subFoldersAsUIFolders$ = (parentId: string): Observable<CfUIFolder[]> => this.folders$.pipe(
    withLatestFrom(this.users$, this.session$),
    map(([folders, users, session]) => {
      folders = folders !== null ? [ ...this._localStorageStarredFolders(folders) ] : [];
      const foldersUI = folders
        .filter((x) => (x.owner === session?.id
            || !x.private
            || x.members.some((u) => u.member === session?.id))
          && !x.trashed
          && x.parentId === parentId)
        .map((folder) => FileUtil.buildUIFolder(folder, users, session));
      const filesUI = folders
        .filter((f) => f.id === parentId && f.files && f.files.length)
        .flatMap((folder) => folder.files
          .filter((file) => !file.trashed)
          .map((file) => FileUtil.buildUIFile(folder, users, session, file))) as CfUIFolder[];
      return [...foldersUI, ...filesUI];
    })
  );

  foldersStarredAsUIFolders$ = (): Observable<CfUIFolder[]> => this.foldersAsUIFolders$().pipe(
    map((folders) => folders.filter((f) => f.name.starred))
  );

  _localStorageAddToStarred(folder: CfFolder) {
    const foldersInCache = this._localStorageService.getItem(STARRED_FOLDERS_KEY);
    const isInCache = foldersInCache?.some((x: CfFolder) => x.id === folder.id);
    const addToCache = !isInCache
      ? foldersInCache !== null
        ? [...foldersInCache, {id: folder.id}]
        : [{id: folder.id}]
      : foldersInCache;
    this._localStorageService.deleteItem(STARRED_FOLDERS_KEY);
    this._localStorageService.setItem(STARRED_FOLDERS_KEY, addToCache);
  }

  _localStorageRemoveFromStarred(folder: CfFolder) {
    const foldersInCache = this._localStorageService.getItem(STARRED_FOLDERS_KEY);
    const isInCache = foldersInCache?.some((x: CfFolder) => x.id === folder.id);
    const newItemsInCache = isInCache
      ? foldersInCache.filter((x: CfFolder) => x.id !== folder.id)
      : foldersInCache;
    this._localStorageService.deleteItem(STARRED_FOLDERS_KEY);
    this._localStorageService.setItem(STARRED_FOLDERS_KEY, newItemsInCache);
  }

  _localStorageStarredFolders(folders: CfFolder[] | null) {
    const foldersInCache = this._localStorageService.getItem(STARRED_FOLDERS_KEY);
    const newFolders: CfFolder[] = [];
    if (folders) {
      folders.forEach((folder: CfFolder) => {
        const newFolder = {...folder};
        foldersInCache?.forEach((folderInCache: CfFolder) => {
          if (newFolder.id === folderInCache.id) {
            newFolder["starred"] = true;
          }
        });
        newFolders.push(newFolder);
      })
    }
    return newFolders;
  }

  _notify(hasSucceeded: boolean, key: string, backendError?: any) {
    if (hasSucceeded) {
      this._localNotification.success(
        'notifications.folder.success',
        `files.notifications.${key}.success_message`
      );
    } else {
      let errorMessage = `files.notifications.${key}.error_message`;
      if (backendError) {
        errorMessage = backendError;
      }
      this._localNotification.error('notifications.folder.error', errorMessage);
    }
  }

  constructor(
    private _router: Router,
    private _fileApi: FileApi,
    private authFacade: AuthFacade,
    private _route: ActivatedRoute,
    private _store: Store<FoldersState>,
    private _usersFacade: UsersFacade,
    private _localStorageService: LocalStorageService,
    private _localNotification: LocalNotificationService
  ) {
    super(<FolderState>{});
  }
}
