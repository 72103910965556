<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'modals.share.title' | translate: {param: folder.name.value} }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form>
    <div class="modal-container">
      <p class="pb-2">
        {{ 'modals.share.subtitle' | translate }}
      </p>

      <div class="form-group">
        <input
          type="email"
          [formControl]="control"
          class="form-input"
          placeholder="{{ 'modals.share.form.email_placeholder' | translate }}"/>

        <ng-container *ngIf="hasSearchTermInput">
          <ng-container *ngIf="results$ | async as results">
            <ng-container *ngIf="results?.length; else noResultTmpl">
              <div class="mt-6">
                <as-modal-folder-share-result
                  title="drawers.search.search_result"
                  [users]="results"
                  [folderId]="folder.name.id"
                  [session]="session"
                  [organization]="organization"
                  [endSlice]="5">
                </as-modal-folder-share-result>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        class="mr-2"
        (click)="closeModal()"
        variant="default"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
    </div>

  </form>
</div>

<ng-template #noResultTmpl>
  <as-alert
    [alertType]="warning"
    message="{{ 'drawers.search.no_result' | translate }}. {{ 'drawers.search.try_again' | translate }}"
  ></as-alert>
</ng-template>
