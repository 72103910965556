import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap, withLatestFrom} from "rxjs";
import {
  loadOrganizations,
  loadOrganizationsError,
  loadOrganizationsSuccess,
  updateOrganizations, updateOrganizationsError, updateOrganizationsSuccess
} from "./organizations.actions";
import {OrganizationApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {select, Store} from "@ngrx/store";
import {getOrganizations} from "./organizations.selectors";
import {OrganizationsState} from "./organizations.reducer";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: 'root' })
export class OrganizationsEffects {
  organizations$ = this.store.pipe(select(getOrganizations));

  public loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganizations),
      switchMap(() => this.organizationApi.list()
        .pipe(
          map(response => loadOrganizationsSuccess({ organizations: response.payload })),
          catchError((error) => [loadOrganizationsError(error)])
        )
      )
    )
  );

  updateOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizations),
      withLatestFrom(this.organizations$),
      switchMap(([{ organization }, organizations]) => this.organizationApi.update(organization.id, organization)
        .pipe(
          map((response) => {
            this.notify('success', 'save');
            const newOrganization = response.payload;
            const newOrganizations = organizations !== null ? [ ...organizations ] : [];
            const index = newOrganizations.findIndex((x) => x.id === newOrganization.id);
            newOrganizations[index] = { ...newOrganization };
            return updateOrganizationsSuccess({ organizations: newOrganizations });
          }),
          catchError((error) => {
            this.notify('error', 'save');
            return [updateOrganizationsError(error)];
          })
        )
      )
    )
  );

  private notify(state: string, action: string): void {
    this.notificationService.create(
      state,
      this.translateService.instant(`notifications.${state}`),
      this.translateService.instant(`notifications.organization.${action}.${state}_message`)
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<OrganizationsState>,
    private organizationApi: OrganizationApi,
    private translateService: TranslateService,
    private notificationService: NzNotificationService
  ) { }
}
