import {Component, Input} from '@angular/core';

export type Variant = 'default' | 'twotone' | 'solid' | 'plain';
export type Size = 'xs' | 'sm' | 'md' | 'lg';
export type Shape = 'radius-round' | 'radius-none' | 'radius-full';
export type Color = 'danger' | 'warning' | 'success' | 'info';

@Component({
  selector: 'as-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() variant: Variant = 'default';
  @Input() size: Size = 'md';
  @Input() disabled!: boolean;
  @Input() color!: Color;
  @Input() shape: Shape = 'radius-round';
  @Input() icon!: string;
  @Input() iconOnly = false;
  @Input() suffix = false;
  @Input() btnGroup = false;
  @Input() overrideClass!: string;
  @Input() isWorking!: boolean | null;
  @Input() isActive!: boolean | null;

  get sizeBtn() {
    if (this.size === 'xs') {
      return 'h-7 px-1.5 py-1 text-xs';
    } else if (this.size === 'sm') {
      return 'h-9 px-2 py-2 text-sm';
    } else if (this.size === 'lg') {
      return 'h-14 px-4 py-2';
    } else {
      return 'h-[42px] px-2 py-2';
    }
  }

  get spinnerColor() {
    return 'text-white';
  }

  get buttonColor() {
    if (this.color &&
      (this.variant === 'solid'
        || this.variant === "twotone")
    ) {
      return this.color;
    }
    return '';
  }

  get overrideClasses() {
    return this.overrideClass
      ? this.overrideClass
      : '';
  }

}
