import {CfChannelSubscribers, CfUser} from "@app-web-central/web/shared/data-access/models";

export class ChannelUtil {
  static setChannelSubscriber(subscriber: CfChannelSubscribers, users: CfUser[] | null) {
    const user = users?.find((x) => x.id === subscriber.subscriberId);
    subscriber.fullName = user != null ? user.fullName : 'Unknown user';
    subscriber.avatarUrl = user != null ? user.avatarUrl : 'Unknown avatar';
    return subscriber;
  }
}
