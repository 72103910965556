<div class="flex items-center py-1 px-3 rounded transition duration-100 cursor-pointer select-none hover:bg-gray-50">
  <svg-icon key="solid/document-check" size="md" class="!flex !items-center text-blue-400">
  </svg-icon>
  <div class="pl-4">
    <div class="text-gray-800 text-15">
      {{ task?.name }}
    </div>
    <div class="uppercase text-xs text-slate-400">
      T-{{task?.identifier}}
    </div>
  </div>
</div>
