import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ProjectStore} from "@app-web-central/web/project/data-access";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RouteUtil} from "@app-web-central/web/shared/utils";


@UntilDestroy()
@Component({
  selector: 'as-join-workspace',
  templateUrl: './join-workspace.component.html',
  styleUrls: ['./join-workspace.component.scss'],
})
export class JoinWorkspaceComponent implements OnInit {
  public loading!: boolean;
  public organization!: string;
  public projectLead!: string;
  private validationCode!: string;
  private projectId!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectStore: ProjectStore
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        if (params) {
          this.projectId = params["project"];
          this.validationCode = params["validation_code"];
          this.projectLead = params["invitor"] || 'nr.';
          this.organization = params["organization"] || 'Quantum CMMS';
        }
      });
  }

  public acceptInvitation(): void {
    this.loading = true;
    this.projectStore.acceptInvitation({
      projectId: this.projectId,
      validationCode: this.validationCode
    });
    this.router.navigateByUrl(RouteUtil.getHomeRouteUrl());
  }
}
