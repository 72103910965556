import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {combineLatest, map, Observable, switchMap, tap} from "rxjs";
import {UsersConfigFacade, UsersFacade} from "@app-web-central/web/user/data-access";
import {RolesFacade, ScopesFacade} from "@app-web-central/web/role/data-access";
import {TasksFacade} from "@app-web-central/web/task/data-access";
import {ProjectsFacade} from "@app-web-central/web/project/data-access";
import {AbilitiesFacade, CategoriesFacade, InterventionsFacade} from "@app-web-central/web/intervention/data-access";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {NotificationsFacade} from "@app-web-central/web/notification/data-access";
import {LicensesFacade} from "@app-web-central/web/license/data-access";
import {AddressesFacade} from "@app-web-central/web/address/data-access";
import {GroupsFacade} from "@app-web-central/web/group/data-access";
import {SuitesFacade} from "@app-web-central/web/suite/data-access";
import {FoldersFacade} from "@app-web-central/web/folder/data-access";
import {ChannelsFacade} from "@app-web-central/web/channel/data-access";
import {TemplatesFacade} from "@app-web-central/web/dashboard/data-access";
import {CacheStore} from "../cache";
import {CfCountry, CfLanguage} from "@app-web-central/web/shared/data-access/models";
import {ConfigApi} from "@app-web-central/web/shared/data-access/stouds-api";

export interface AppState {
  isLoading: boolean;
  percentage: number;
  languages: CfLanguage[];
  countries: CfCountry[];
}

@Injectable({ providedIn: 'root' })
export class AppStore extends ComponentStore<AppState> {
  public isLoading$: Observable<boolean>
    = this.select(({ isLoading }) => isLoading);
  public percentage$: Observable<number>
    = this.select(({ percentage }) => percentage);
  public languages$: Observable<CfLanguage[]>
    = this.select(({ languages }) => languages);
  public countries$: Observable<CfCountry[]>
    = this.select(({ countries }) => countries);
  private loadCounter = 0;
  private sizeOfServices = 4;

  public loadApp$ = this.effect((params$) =>
    params$.pipe(
      tap(() => {
        this.loadCounter = 0;
        this.setLoading$(true);
        this.usersFacade.loadUsers();
        this.rolesFacade.loadRoles();
        this.tasksFacade.loadTasks();
        this.suitesFacade.loadUnits();
        this.scopesFacade.loadScopes();
        this.groupsFacade.loadGroups();
        this.foldersFacade.loadFolders();
        this.cacheStore.loadLocalCache();
        this.projectsFacade.loadProjects();
        this.channelsFacade.loadChannels();
        this.licensesFacade.loadLicenses();
        this.addressesFacade.loadAddresses();
        this.categoryFacade.loadCategories();
        this.templatesFacade.loadTemplates();
        this.abilitiesFacade.loadAbilities();
        this.organizationFacade.loadOrganizations();
        this.notificationFacade.loadNotifications();
        this.interventionFacade.loadInterventions();
        this.userLocalConfigFacade.loadUserLocalConfig();
      }),
      switchMap(() =>
        combineLatest([
          this.usersFacade.isLoading$,
          this.rolesFacade.isLoading$,
          this.tasksFacade.isLoading$,
          this.scopesFacade.isLoading$,
          this.projectsFacade.isLoading$,
          this.categoryFacade.isLoading$,
          this.organizationFacade.isLoading$,
          this.notificationFacade.isLoading$,
          this.interventionFacade.isLoading$,
          this.licensesFacade.isLoading$,
          this.suitesFacade.isLoading$,
          this.channelsFacade.isLoading$,
          this.abilitiesFacade.isLoading$,
          this.addressesFacade.isLoading$,
          this.foldersFacade.isLoading$,
          this.groupsFacade.isLoading$,
          this.templatesFacade.isLoading$,
          this.userLocalConfigFacade.isLoading$,
          this.loadAppData()
        ]).pipe(
          tapResponse(([
             loadedUsers,
             loadedRoles,
             loadedTasks,
             loadedScopes,
             loadedLicenses,
             loadedProjects,
             loadCategories,
             loadOrganizations,
             loadNotifications,
             loadInterventions,
             loadedGroups,
             loadedAddresses,
             loadedSuites,
             loadedFolders,
             loadedChannels,
             loadedTemplates,
             loadedUserLocalConfig,
             loadedAbilities,
             loadedAppData,
           ]) => {
            this.loadCounter++;
            this.setPercentage$((this.loadCounter / this.sizeOfServices) * 100);
            this.setAppData$(loadedAppData);
            if (!loadedUsers
              && !loadedRoles
              && !loadedTasks
              && !loadedScopes
              && !loadedLicenses
              && !loadedProjects
              && !loadCategories
              && !loadOrganizations
              && !loadNotifications
              && !loadInterventions
              && !loadedGroups
              && !loadedAddresses
              && !loadedSuites
              && !loadedFolders
              && !loadedChannels
              && !loadedTemplates
              && !loadedUserLocalConfig
              && !loadedAbilities
            ) {
              this.setLoading$(false);
            }
          }, () => {
            this.setLoading$(false); // TODO: handle gracefully error
          })
        )
      )
    )
  );

  private setLoading$ = this.updater((state: AppState, isLoading: boolean) => ({
    ...state,
    isLoading
  }));

  private setPercentage$ = this.updater((state: AppState, percentage: number) => ({
    ...state,
    percentage
  }));

  private setAppData$ = this.updater((state: AppState, data: { countries: CfCountry[], languages: CfLanguage[] }) => ({
    ...state,
    countries: data.countries,
    languages: data.languages
  }));

  private loadAppData(): Observable<{ countries: CfCountry[], languages: CfLanguage[] }> {
    return combineLatest([
      this.configApi.fetchCountries(),
      this.configApi.fetchLanguages()
    ]).pipe(
      map(([responseCountries, responseLanguages]) => {
        return {
          countries: responseCountries.payload,
          languages: responseLanguages.payload
        };
      })
    );
  }

  constructor(
    private configApi: ConfigApi,
    private cacheStore: CacheStore,
    private usersFacade: UsersFacade,
    private rolesFacade: RolesFacade,
    private tasksFacade: TasksFacade,
    private suitesFacade: SuitesFacade,
    private scopesFacade: ScopesFacade,
    private groupsFacade: GroupsFacade,
    private foldersFacade: FoldersFacade,
    private channelsFacade: ChannelsFacade,
    private licensesFacade: LicensesFacade,
    private projectsFacade: ProjectsFacade,
    private abilitiesFacade: AbilitiesFacade,
    private addressesFacade: AddressesFacade,
    private categoryFacade: CategoriesFacade,
    private templatesFacade: TemplatesFacade,
    private notificationFacade: NotificationsFacade,
    private interventionFacade: InterventionsFacade,
    private organizationFacade: OrganizationsFacade,
    private userLocalConfigFacade: UsersConfigFacade,
  ) {
    super(<AppState>{
      isLoading: false,
      percentage: 0,
      languages: [],
      countries: []
    });
  }
}
