<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ title | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()"
    >
    </as-button>
  </div>

  <form>
    <div class="modal-container">
      <p>{{ content | translate }}</p>
    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        variant="solid"
        color="info"
        (click)="submit()"
      >
        {{ 'buttons.ok' | translate }}
      </as-button>
    </div>

  </form>
</div>
