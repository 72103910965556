import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfProject, CfProjectMember, CfUser, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ProjectApi {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
    private _http: HttpClient
  ) {}

  get(projectId: string) {
    return this._http.get<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`);
  }

  getByKey(projectKey: string) {
    return this._http.get<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/key/${projectKey}`);
  }

  list() {
    return this._http.get<ResponseState<CfProject[]>>(
      `${this._appConfig.projectV1Url}manage/projects`);
  }

  create(project: CfProject) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects`, project,
    );
  }

  update(projectId: string, project: CfProject) {
    return this._http.put<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`, project,
    );
  }

  delete(projectId: string) {
    return this._http.delete<ResponseState<string>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}`,
    );
  }

  invite(user: CfUser | any, projectId: string) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}/invite`, user,
    );
  }

  accept(projectId: string, validationCode: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}/accept-invitation/${validationCode}`,
    );
  }

  revoke(projectId: string, validationCode: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.projectV1Url}manage/projects/${projectId}/revoke-invitation/${validationCode}`,
    );
  }

  updateMember(member: CfProjectMember) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/update-member`, member,
    );
  }

  removeMember(member: CfProjectMember) {
    return this._http.post<ResponseState<CfProject>>(
      `${this._appConfig.projectV1Url}manage/projects/remove-member`, member,
    );
  }

}
