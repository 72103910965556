import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {loadGroups} from "./groups.actions";
import {GroupsState} from "./groups.reducer";
import {getGroups, getGroupsLoading} from "./groups.selectors";
import {Observable} from "rxjs";
import {CfTeam} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class GroupsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getGroupsLoading));
  public groups$: Observable<CfTeam[] | null>
    = this.store.pipe(select(getGroups));

  constructor(
    private store: Store<GroupsState>
  ) { }

  public loadGroups(): void {
    this.store.dispatch(loadGroups());
  }

}
