import {createAction, props} from "@ngrx/store";
import {CfTask, GenericStoreStatus} from "@app-web-central/web/shared/data-access/models";
import {TaskAssignees, TaskManyAssignee} from "@app-web-central/web/shared/data-access/stouds-api";

// TODO: may add a limit : 50 ????
export const loadTasks = createAction(
  '[Task List] Load Tasks'
);

export const loadTasksSuccess = createAction(
  '[Task List] Load Tasks Success',
  props<{
    tasks: CfTask[];
  }>()
);

export const loadTasksError = createAction(
  '[Task List] Load Tasks Error',
  props<{
    error: string;
  }>()
);

export const setLoadingTasksState = createAction(
  '[Browse Page] Set Tasks state status',
  props<{
    status: GenericStoreStatus;
  }>()
);

export const updateTask = createAction(
  '[Task Object] Update Task',
  props<{
    task: CfTask
  }>()
);

export const updateTaskSuccess = createAction(
  '[Task Object] Update Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const updateTaskError = createAction(
  '[Task Object] Update Task Error',
  props<{
    error: string
  }>()
);

export const updateTaskStatus = createAction(
  '[Task Object] Update Task Status',
  props<{
    task: CfTask
  }>()
);

export const updateTaskStatusSuccess = createAction(
  '[Task Object] Update Task Status Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const updateTaskStatusError = createAction(
  '[Task Object] Update Task Status Error',
  props<{
    error: string
  }>()
);

export const createTask = createAction(
  '[Task Object] Create Task',
  props<{
    task: CfTask
  }>()
);

export const createTaskSuccess = createAction(
  '[Task Object] Create Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const createTaskError = createAction(
  '[Task Object] Create Task Error',
  props<{
    error: string
  }>()
);

export const removeTask = createAction(
  '[Task Object] Remove Task',
  props<{
    taskId: string
  }>()
);

export const removeTaskSuccess = createAction(
  '[Task Object] Remove Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const removeTaskError = createAction(
  '[Task Object] Remove Task Error',
  props<{
    error: string
  }>()
);

export const removeBulkTask = createAction(
  '[Task Object] Remove Bulk Task',
  props<{
    taskIds: string[]
  }>()
);

export const removeBulkTaskSuccess = createAction(
  '[Task Object] Remove Bulk Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const removeBulkTaskError = createAction(
  '[Task Object] Remove Bulk Task Error',
  props<{
    error: string
  }>()
);

export const enableTask = createAction(
  '[Task Object] Enable Task',
  props<{
    taskId: string
  }>()
);

export const enableTaskSuccess = createAction(
  '[Task Object] Enable Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const enableTaskError = createAction(
  '[Task Object] Enable Task Error',
  props<{
    error: string
  }>()
);

export const enableBulkTask = createAction(
  '[Task List] Enable Bulk Task',
  props<{
    taskIds: string[]
  }>()
);

export const enableBulkTaskSuccess = createAction(
  '[Task List] Enable Bulk Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const enableBulkTaskError = createAction(
  '[Task List] Enable Bulk Task Error',
  props<{
    error: string
  }>()
);

export const disableTask = createAction(
  '[Task Object] Disable Task',
  props<{
    taskId: string
  }>()
);

export const disableTaskSuccess = createAction(
  '[Task Object] Disable Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const disableTaskError = createAction(
  '[Task Object] Disable Task Error',
  props<{
    error: string
  }>()
);

export const disableBulkTask = createAction(
  '[Task List] Disable Bulk Task',
  props<{
    taskIds: string[]
  }>()
);

export const disableBulkTaskSuccess = createAction(
  '[Task List] Disable Bulk Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const disableBulkTaskError = createAction(
  '[Task List] Disable Bulk Task Error',
  props<{
    error: string
  }>()
);

export const assigneesTask = createAction(
  '[Task List] Assign Bulk Task',
  props<{
    assignees: TaskAssignees
  }>()
);

export const assigneesTaskSuccess = createAction(
  '[Task List] Assign Bulk Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const assigneesTaskError = createAction(
  '[Task List] Assign Bulk Task Error',
  props<{
    error: string
  }>()
);

export const assigneesManyTask = createAction(
  '[Task List] Assign Bulk Task to Many Agents',
  props<{
    assignees: TaskManyAssignee
  }>()
);

export const assigneesManyTaskSuccess = createAction(
  '[Task List] Assign Bulk Task to Many Agents Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const assigneesManyTaskError = createAction(
  '[Task List] Assign Bulk Task to Many Agents Error',
  props<{
    error: string
  }>()
);

export const unassignedTask = createAction(
  '[Task Object] Unassigned Single Task',
  props<{
    taskId: string
  }>()
);

export const unassignedTaskSuccess = createAction(
  '[Task Object] Unassigned Single Task Success',
  props<{
    tasks: CfTask[] | null
  }>()
);

export const unassignedTaskError = createAction(
  '[Task Object] Unassigned Single Task Error',
  props<{
    error: string
  }>()
);
