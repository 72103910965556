<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'reminder.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form [formGroup]="reminderForm" (ngSubmit)="submit()" class="w-full max-w-lg">
    <input type="hidden" formControlName="client">

    <div class="form-group">
      <label for="title" class="label">
        {{ 'reminder.form.title' | translate }}
      </label>

      <input id="title"
             type="text"
             placeholder="{{ 'reminder.form.title_placeholder' | translate }}"
             formControlName="title"
             class="form-input" >
    </div>

    <div class="form-group mt-3">
      <label for="message" class="label">
        {{ 'reminder.form.message' | translate }}
      </label>

      <textarea id="message"
                class="form-input"
                placeholder="{{ 'reminder.form.message_placeholder' | translate }}"
                formControlName="message">
      </textarea>
    </div>

    <div class="form-group mt-3">
      <label class="label" for="dueDate">
        {{ 'reminder.form.due_date' | translate }}
      </label>

      <nz-date-picker id="dueDate"
                      nzShowTime
                      nzFormat="yyyy-MM-dd HH:mm"
                      formControlName="dueDate" >
      </nz-date-picker>
    </div>

    <div class="form-group mt-3">
      <label class="label" for="priority">
        {{ 'reminder.form.priority' | translate }}
      </label>

      <nz-select id="priority"
                 class="w-full"
                 formControlName="priority"
                 nzShowSearch >
        <nz-option *ngFor="let priority of priorities"
                   nzLabel=" {{ 'reminder.priorities.' + priority.label.toLowerCase() | translate }} "
                   [nzValue]="priority.value">
        </nz-option>
      </nz-select>
    </div>

    <div class="form-group mt-3">
      <div class="form-check">
        <input type="checkbox"
               id="enabled"
               class="form-check-input"
               formControlName="enabled" />
        <label class="form-check-label" for="enabled">
          {{ 'reminder.form.enabled' | translate }}
        </label>
      </div>
    </div><!-- end of enabled -->

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        [disabled]="reminderForm.invalid"
        variant="solid"
        color="info"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>

</div>
