import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CfEventStats, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class EventStatsApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public get(queryParams: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    return this.httpClient.get<ResponseState<CfEventStats>>(
      `${this._appConfig.eventStatsV1Url}dataset`, {
        params: new HttpParams({ fromObject: queryParams })
      }
    );
  }

}
