<li [className]="
      index === length -1
      ? 'notification-item rounded-bl-xl rounded-br-xl'
      : 'notification-item'"
    (click)="notification.referenceKey === 'EVENT'
      ? onOpenTaskDrawer(notification.referenceId, notification.id)
      : null"
>
  <div class="flex flex-row">
    <div class="w-8">
      <svg-icon
        [key]="icon"
        class="!flex !items-center mt-1 text-purple-600">
      </svg-icon>
    </div>
    <div class="truncate notification-item-wrapper w-full">
      <div class="flex justify-between space-x-3">
        <div class="min-w-0 flex-1">
          <a class="block focus:outline-none">
            <span class="absolute inset-0" aria-hidden="true"></span>
            <p class="truncate font-display text-base text-slate-900">
              {{ notification.notificationSubject }}
            </p>
          </a>
        </div>
        <time class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
          {{ notification.dateAdd | dateAgo }}
        </time>
        <div class="relative"
             *ngIf="!notification.markedAsRead">
          <as-badge></as-badge>
        </div>
      </div>
      <div class="mt-1">
        <p class="text-sm text-slate-600 line-clamp-4">
          <span [innerHTML]="notification.referenceDescription"></span>
        </p>
      </div>
      <div class="mt-1" *ngIf="notification.referenceKey === 'EVENT'">
        <p class="notification-link">
          {{ 'buttons.learn_more' | translate }}
          <svg-icon
            key="mini/arrow-top-right-on-square"
            class="!flex !items-center ml-2">
          </svg-icon>
        </p>

        <div [hidden]="true">
          <ng-template #TaskTitleTmpl [ngIf]="project && task">
            <div class="ml-10 flex flex-col items-start">
              <div class="flex items-center">
                <div class="w-3 h-3 mr-3 rounded"
                     [style.background-color]="project.color">
                </div>
                <div class="truncate text-gray-800">{{ project.name }}</div>
              </div>
              <div class="flex items-center">
                <span class="text-gray-500">
                  {{ 'task_detail.popover.identifier' | translate }} {{ task.identifier }}
                </span>
              </div>
            </div>
          </ng-template>
        </div>

      </div>
    </div>
  </div>
</li>
