import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {filter, Observable} from "rxjs";
import {CfUser} from "@app-web-central/web/shared/data-access/models";

export interface AuthState {
  accessToken: string | null;
  tokenType: string | null;
  expiresIn: number;
  state: string | null;
  user: CfUser | null;
}

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
  public user$: Observable<CfUser | null>
    = this.select(({ user }) => user);
  public tenantId$: Observable<string | undefined>
    = this.select(({ user }) => user?.tenant);

  public readonly token$: Observable<string>
    = this.select((s) => s.accessToken).pipe(
    filter((token) => !!token)
  ) as Observable<string>;

  public setUser$ = this.updater((state: AuthState, user: CfUser) => ({
    ...state,
    user
  }));

  constructor() {
    super(<AuthState>{});
  }
}
