import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {
  CfContactUs,
  CfFeedback,
  CfUserNotification,
  ResponseState
} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class NotificationApi {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  contactUs(contactUs: CfContactUs) {
    return this._http.post<ResponseState<any>>(
      `${this._appConfig.notificationV1Url}contact-us`, contactUs
    );
  }

  feedback(notification: CfFeedback) {
    return this._http.post<ResponseState<any>>(
      `${this._appConfig.notificationV1Url}support`, notification
    );
  }

  public findAll(): Observable<ResponseState<CfUserNotification[]>> {
    return this._http.get<ResponseState<CfUserNotification[]>>(
      `${this._appConfig.notificationV1Url}users/notifications`
    );
  }

  findAllByUserId(userId?: string) {
    return this._http.get<ResponseState<CfUserNotification[]>>(
      `${this._appConfig.notificationV1Url}users/notifications/${userId}`
    );
  }

  findLast10ByUserId(userId: string) {
    return this._http.get<ResponseState<CfUserNotification[]>>(
      `${this._appConfig.notificationV1Url}users/notifications/${userId}/last-10`
    );
  }

  read(notificationId: string) {
    return this._http.get<ResponseState<CfUserNotification>>(
      `${this._appConfig.notificationV1Url}users/notifications/${notificationId}/read`
    );
  }

  readAll(userId: string) {
    return this._http.get<ResponseState<string>>(
      `${this._appConfig.notificationV1Url}users/notifications/${userId}/read-all`
    );
  }

}
