import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

export enum UsageStateEnum {
  SUCCESS = "success",
  ERROR = "error",
}

export interface CfUsageState {
  count: number;
  state: UsageStateEnum;
}

@Injectable({providedIn: 'root'})
export class UsageApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public get(period: number, usageType: string): Observable<ResponseState<CfUsageState>> {
    return this.httpClient.get<ResponseState<CfUsageState>>(
      `${this._appConfig.usageV1Url}manage/usages/${period}/count/${usageType}`
    );
  }

}
