import {CfRole, GenericState} from "@app-web-central/web/shared/data-access/models";
import {Injectable} from "@angular/core";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {select, Store} from "@ngrx/store";
import {RoleUtil, SelectorUtil} from "@app-web-central/web/shared/utils";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {getRoles, loadRolesSuccess, RolesState} from "@app-web-central/web/role/data-access";
import {RoleApi} from "@app-web-central/web/shared/data-access/stouds-api";

interface RoleState extends GenericState<CfRole> {
  roleId: string;
}

@Injectable({ providedIn: 'root' })
export class RoleStore extends ComponentStore<RoleState> {
  roles$ = this._store.pipe(select(getRoles));
  isRoleLoading$ = this.select(SelectorUtil.isLoading);

  roleIdParams$: Observable<string> = this._route.params.pipe(
    map((params) => params["roleId"]),
    filter((roleId: string) => !!roleId)
  );

  role$ = this.roleIdParams$.pipe(
    tap((roleId) => {
      this.patchState({
        roleId
      });
      this.loadRole({ roleId });
    }),
    switchMap(() => this.select((s) => s.data))
  );

  loadRole = this.effect<{ roleId: string }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading',
          error: null
        });
      }),
      switchMap(({ roleId }) =>
        this._roleApi.get(roleId).pipe(
          tapResponse(
            (response) => {
              const role = { ...response.payload };
              this.patchState({
                data: role,
                status: 'success',
                error: ''
              });
            },
            (error) => {
              this.patchState({
                status: 'error',
                error: error as unknown as string
              });
            }
          )
        )
      )
    )
  );

  update = this.effect<CfRole>((params$) => (
      params$.pipe(
        withLatestFrom(this.roles$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([role, roles]) =>
          this._roleApi.update(role.id, role)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'update');
                  const newRoles = roles !== null ? [...roles] : [];
                  const index = newRoles.findIndex((item) => item.id === response.payload.id);
                  newRoles[index] = {...response.payload};
                  this._store.dispatch(
                    loadRolesSuccess({
                      roles: newRoles
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'update');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  create = this.effect<CfRole>((params$) => (
      params$.pipe(
        withLatestFrom(this.roles$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([role, roles]) =>
          this._roleApi.create(role)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'create');
                  const newRoles = roles !== null ? [...roles] : [];
                  newRoles.push(response.payload);
                  this._store.dispatch(
                    loadRolesSuccess({
                      roles: newRoles
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'create');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  delete = this.effect<{ role: CfRole }>((params$) => (
      params$.pipe(
        withLatestFrom(this.roles$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ role }, roles]) =>
          this._roleApi.delete(role.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'delete');
                  const newRoles = RoleUtil.removeRoleFromRoles(roles, role);
                  this._store.dispatch(
                    loadRolesSuccess({
                      roles: newRoles
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'delete');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  enable = this.effect<{ role: CfRole }>((params$) => (
      params$.pipe(
        withLatestFrom(this.roles$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ role }, roles]) =>
          this._roleApi.enable(role.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'enable');
                  const newRoles = RoleUtil.replaceRoleAndGetNewRoles(roles, response.payload);
                  this._store.dispatch(
                    loadRolesSuccess({
                      roles: newRoles
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'enable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  disable = this.effect<{ role: CfRole }>((params$) => (
      params$.pipe(
        withLatestFrom(this.roles$),
        tap(() => {
          this.patchState({
            status: 'loading',
            error: null
          });
        }),
        switchMap(([{ role }, roles]) =>
          this._roleApi.disable(role.id)
            .pipe(
              tapResponse(
                (response) => {
                  this._notify('success', 'disable');
                  const newRoles = RoleUtil.replaceRoleAndGetNewRoles(roles, response.payload);
                  this._store.dispatch(
                    loadRolesSuccess({
                      roles: newRoles
                    })
                  );
                  this.patchState({
                    data: response.payload,
                    status: 'success',
                    error: ''
                  });
                },
                (error) => {
                  this._notify('error', 'disable');
                  this.patchState({
                    status: 'error',
                    error: error as unknown as string
                  });
                }
              )
            )
        )
      )
    )
  );

  private _notify(state: string, action: string) {
    this._notification.create(
      state,
      this._translate.instant(`notifications.${state}`),
      this._translate.instant(`notifications.role.${action}.${state}_message`)
    );
  }

  constructor(
    private _roleApi: RoleApi,
    private _route: ActivatedRoute,
    private _store: Store<RolesState>,
    private _translate: TranslateService,
    private _notification: NzNotificationService
  ) {
    super(<RoleState>{});
  }
}
