import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfAddress, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class AddressApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  public get(addressId: string) {
    return this.httpClient.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses`
    );
  }

  public create(address: CfAddress) {
    return this.httpClient.post<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses`, address
    );
  }

  public update(addressId: string, address: CfAddress) {
    return this.httpClient.put<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`, address
    );
  }

  public delete(addressId: string) {
    return this.httpClient.delete<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}`
    );
  }

  public deleteBulk(addressIds: string[]) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/${addressIds}`
    );
  }

  public enable(addressId: string) {
    return this.httpClient.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}/enable`
    );
  }

  public enableBulk(addressIds: string[]) {
    return this.httpClient.post<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/enable`, addressIds
    );
  }

  public disable(addressId: string) {
    return this.httpClient.get<ResponseState<CfAddress>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/${addressId}/disable`
    );
  }

  public disableBulk(addressIds: string[]) {
    return this.httpClient.post<ResponseState<CfAddress[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/bulk/disable`, addressIds
    );
  }

}
