import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'as-stacked-list',
  templateUrl: './stacked-list.component.html',
  styleUrls: ['./stacked-list.component.scss'],
})
export class StackedListComponent {
  @Input() title!: string;
  @Input() data!: any[];
  @Input() limit!: number;
  @Input() negativeMargin!: boolean;
  @Input() templateRef!: TemplateRef<any>;
  @Input() noResultRef!: TemplateRef<any>;

  handleViewMore() {
    this.limit += 2;
  }

}
