import {ProjectMemberRoleEnum} from "../api/project";

export const ProjectMemberRoleDisplayValues = {
  [ProjectMemberRoleEnum.ADMIN]: 'projects.roles.admin',
  [ProjectMemberRoleEnum.MEMBER]: 'projects.roles.member',
  [ProjectMemberRoleEnum.READONLY]: 'projects.roles.readonly'
};

export class ProjectMemberRole {
  label: string;
  value: ProjectMemberRoleEnum;
  constructor(memberRole: ProjectMemberRoleEnum) {
    this.label = ProjectMemberRoleDisplayValues[memberRole];
    this.value = memberRole;
  }
}
