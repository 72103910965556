import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {ScopesState} from "./scopes.reducer";
import {getScopes, getScopesLoading} from "./scopes.selectors";
import {loadScopes} from "./scopes.actions";
import {Observable} from "rxjs";
import {CfScope} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ScopesFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getScopesLoading));
  public scopes$: Observable<CfScope[] | null>
    = this.store.pipe(select(getScopes));

  constructor(
    private store: Store<ScopesState>
  ) { }

  public loadScopes(): void {
    this.store.dispatch(loadScopes());
  }
}
