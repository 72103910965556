import {createAction, props} from "@ngrx/store";
import {CfReminder, CfUserNotification} from "@app-web-central/web/shared/data-access/models";

export const loadNotifications = createAction(
  '[Notification List] Load Notifications'
);

export const loadNotificationsSuccess = createAction(
  '[Notification List] Load Notifications Success',
  props<{
    notifications: CfUserNotification[]
  }>()
);

export const loadNotificationsError = createAction(
  '[Notification List] Load Last 10 Notifications Error',
  props<{
    error: string
  }>()
);

export const loadMore10Notifications = createAction(
  '[Notification List] Load More 10 Notifications',
  props<{
    userId: string,
    count: number
  }>()
);

export const loadMore10NotificationsSuccess = createAction(
  '[Notification List] Load More 10 Notifications Success',
  props<{
    notifications: CfUserNotification[] | [] | null
  }>()
);

export const loadMore10NotificationsError = createAction('[Notification List] Load More 10 Notifications Error');

export const readOneNotification = createAction(
  '[Notification Update] Read 1 Notification',
  props<{
    notificationId: string
  }>()
);

export const readOneNotificationSuccess = createAction(
  '[Notification Update] Read 1 Notification Success',
  props<{
    notifications: CfUserNotification[] | [] | null
  }>()
);

export const readOneNotificationError = createAction('[Notification Update] Read 1 Notification Error');

export const readAllNotification = createAction(
  '[Notification Update] Read All Notification',
  props<{
    userId: string
  }>()
);

export const readAllNotificationSuccess = createAction(
  '[Notification Update] Read All Notification Success',
  props<{
    notifications: CfUserNotification[];
  }>()
);

export const readAllNotificationError = createAction(
  '[Notification Update] Read All Notification Error',
  props<{
    error: string;
  }>()
);

export const createReminder = createAction(
  '[Reminder Object] Create Reminder',
  props<{
    reminder: CfReminder,
    reminderType: string
  }>()
);

export const createReminderSuccess = createAction(
  '[Reminder Object] Create Reminder Success',
  props<{
    reminder: CfReminder
  }>()
);

export const createReminderError = createAction(
  '[Reminder Object] Create Reminder Error',
  props<{
    error: string
  }>()
);

export const updateReminder = createAction(
  '[Reminder Object] Update Reminder',
  props<{
    reminderId: string,
    reminder: CfReminder,
    reminderType: string
  }>()
);

export const updateReminderSuccess = createAction(
  '[Reminder Object] Update Reminder Success',
  props<{
    reminder: CfReminder
  }>()
);

export const updateReminderError = createAction(
  '[Reminder Object] Update Reminder Error',
  props<{
    error: string
  }>()
);
