import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfOrganization, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class OrganizationApi {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public list(): Observable<ResponseState<CfOrganization[]>> {
    return this._http.get<ResponseState<CfOrganization[]>>(
      `${this._appConfig.organizationV1Url}manage/organizations`
    );
  }

  public update(organizationId: string, organization: CfOrganization): Observable<ResponseState<CfOrganization>> {
    return this._http.put<ResponseState<CfOrganization>>(
      `${this._appConfig.organizationV1Url}manage/organizations/${organizationId}`, organization
    );
  }

}
