import {Component, Input} from '@angular/core';

@Component({
  selector: 'as-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @Input() user!: any;
  @Input() imageOnly = false;
  @Input() fullNameKey: any = 'fullName';
  @Input() imageUrlKey: any = 'avatarUrl';
  @Input() isActive = false;

  get fullName() {
    return this.user[this.fullNameKey];
  }

  get imageUrl() {
    return this.user[this.imageUrlKey];
  }
}
