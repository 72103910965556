import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {CfTask} from "@app-web-central/web/shared/data-access/models";
import {LocalStorageService} from "@app-web-central/web/shared/services/local-storage";

export interface GenericSearchState {
  tasks: CfTask[];
}

interface CacheState {
  search: GenericSearchState;
}

export const RECENT_SEARCH_KEY = 'RECENT_SEARCH';

@Injectable({ providedIn: 'root' })
export class CacheStore extends ComponentStore<CacheState> {
  public all$ = this.select(({search}) => search);
  public searchCache$ = this.select(({search}) => search.tasks);

  public addToLocaleCache$ = this.updater((state, task: CfTask) => {
    const isInCache = state.search.tasks.includes(task);
    const addToCache = !isInCache
      ? [...state.search.tasks, task]
      : state.search.tasks;
    this.localStorageService.deleteItem(RECENT_SEARCH_KEY);
    this.localStorageService.setItem(RECENT_SEARCH_KEY, addToCache);
    const search = ({
      ...state.search,
      tasks: addToCache
    })
    return ({
      ...state,
      search
    })
  });

  public loadLocalCache(): void {
    const localData = this.localStorageService.getItem(RECENT_SEARCH_KEY);
    this.setState((state) => ({
      ...state,
      search: { tasks: localData != null ? [...localData] : [] }
    }));
  }

  constructor(
    private localStorageService: LocalStorageService
  ) {
    super(<CacheState>{search: { tasks: [] }, pinned: []});
  }
}
