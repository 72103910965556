import {LocalStorageService, PINNED_ITEMS} from "@app-web-central/web/shared/services/local-storage";
import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";

export interface ItemState {
  label: string;
  iconKey: string;
  url: string;
  labelFor: string;
}

interface PinnedState {
  items: ItemState[]
}

const initialState: ItemState[] = [];

@Injectable({providedIn: 'root'})
export class PinnedItemStore extends ComponentStore<PinnedState> {
  pinned$ = this.select(({ items }) => items);

  onLoad$ = this.updater((state) => ({
    ...state,
    items: this.getPreferences()
      ? this.mergePreferences()
      : initialState
  }));

  upsertPinnedItem$ = this.updater((state, input: { object: any }) => ({
    ...state,
    items: this.togglePinView(state.items, input.object)
  }));

  private togglePinView(items: ItemState[], object: any) {
    const newItems = [ ...items ];
    const index = newItems.findIndex((i) => i.url === object.url);
    if (index > -1) {
      newItems.splice(index, 1);
    } else {
      newItems.push({
        label: object.label,
        iconKey: object.iconKey,
        url: object.url,
        labelFor: object.labelFor
      });
    }
    this.savePreferences(newItems);
    return newItems;
  }

  private mergePreferences() {
    return this._localStorageService.getItem(PINNED_ITEMS)?.items as ItemState[];
  }

  private getPreferences() {
    return !!this._localStorageService.getItem(PINNED_ITEMS)?.items;
  }

  private savePreferences(items: ItemState[]) {
    this._localStorageService.deleteItem(PINNED_ITEMS);
    this._localStorageService.setItem(PINNED_ITEMS, { items });
  }

  constructor(
    private _localStorageService: LocalStorageService
  ) {
    super(<PinnedState>{ items: initialState });
  }
}
