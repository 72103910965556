import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationListComponent} from './notification-list.component';
import {NotificationItemModule} from "@app-web-central/web/notification/ui/notification-item";
import {SpinnerModule} from "@app-web-central/web/shared/ui/spinner";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    SpinnerModule,
    NzToolTipModule,
    NotificationItemModule,
  ],
  declarations: [
    NotificationListComponent
  ],
  exports: [
    NotificationListComponent
  ],
})
export class NotificationListModule {
}
