import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {CfScope, CfUser} from "@app-web-central/web/shared/data-access/models";
import {AuthFacade} from "@app-web-central/web/auth/data-access";
import {Observable} from "rxjs";

@UntilDestroy()
@Directive({
  selector: '[asHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input() public asHasPermission: Array<string> = [];
  private session$: Observable<CfUser | null>
    = this.authFacade.user$;
  private isVisible = false;

  constructor(
    private authFacade: AuthFacade,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.session$
      .pipe(untilDestroyed(this))
      .subscribe((session: CfUser | null) => {
        if (session) {
          const scopes: CfScope[] = session.roles[0].scopes;
          if (scopes.length < 1) {
            this.isVisible = false;
            this.viewContainerRef.clear();
          }

          const found: boolean = scopes.some((r: CfScope) => this.asHasPermission.includes(r.scopeKey));

          if (found) {
            if (!this.isVisible) {
              this.isVisible = true;
              this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
          } else {
            this.isVisible = false;
            this.viewContainerRef.clear();
          }

        }
      });
  }

}
