import {Routes} from "@angular/router";
import {LayoutNoLeftbarComponent} from "@app-web-central/web/shell/ui/layout-no-leftbar";
import {JoinWorkspaceComponent} from "@app-web-central/web/shell/ui/join-workspace";
import {JoinFolderComponent} from "@app-web-central/web/shell/ui/join-folder";

export const appQuantumRoutes: Routes = [
  {
    path: '',
    component: LayoutNoLeftbarComponent,
    children: [
      {
        path: 's',
        loadChildren: async () =>
          (await import('@app-web-central/web/admin/feature/shell')).AdminShellModule
      },
      {
        path: '',
        redirectTo: 's',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'accept-invitation',
    component: JoinWorkspaceComponent
  },
  {
    path: 'join-folder',
    component: JoinFolderComponent
  },
  {
    path: '**',
    loadChildren: async () =>
      (await import('@app-web-central/web/shared/components/indicators/not-found')).NotFoundModule
  }
];
