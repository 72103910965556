import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {BreadcrumbsLink} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "@app-web-central/web/shared/utils";

interface BreadcrumbState {
  breadcrumbs: BreadcrumbsLink[];
}

const initialState = [
  new BreadcrumbsLink('sidebar.links.settings', RouteUtil.getAdminRouteUrl())
];

@Injectable({ providedIn: 'root' })
export class BreadcrumbsStore extends ComponentStore<BreadcrumbState> {
  breadcrumbs$ = this.select(({ breadcrumbs }) => breadcrumbs);
  menuBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs: [...initialState, ...breadcrumbs]
  }));
  addToBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs: [...state.breadcrumbs, ...breadcrumbs]
  }));
  addToCurrentBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs: !state.breadcrumbs.some((x) => x.url === breadcrumbs[0].url)
      ? [...state.breadcrumbs, ...breadcrumbs]
      : state.breadcrumbs
  }));
  replaceLastBreadcrumbsState$ = this.updater((state, breadcrumb: BreadcrumbsLink) => {
    const newBreadcrumbs: BreadcrumbsLink[] = [ ...state.breadcrumbs ];
    newBreadcrumbs[state.breadcrumbs.length-1] = { ...breadcrumb };
    return {
      ...state,
      breadcrumbs: newBreadcrumbs
    }
  });
  toggleBreadcrumbsState$ = this.updater((state, breadcrumbs: BreadcrumbsLink[]) => ({
    ...state,
    breadcrumbs
  }));
  reset$ = this.updater((state) => ({
    ...state,
    breadcrumbs: initialState
  }));
  constructor() {
    super(<BreadcrumbState>{ breadcrumbs: initialState });
  }
}
