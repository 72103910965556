import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfHub, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class HubApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public create(hub: CfHub) {
    return this.httpClient.post<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs`, hub
    );
  }

  public update(hubId: string, hub: CfHub) {
    return this.httpClient.put<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`, hub
    );
  }

  public get(hubId: string) {
    return this.httpClient.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs`
    );
  }

  public delete(hubId: string) {
    return this.httpClient.delete<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}`
    );
  }

  public deleteBulk(hubIds: string[]) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/${hubIds}`
    );
  }

  public enable(hubId: string) {
    return this.httpClient.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}/enable`
    );
  }

  public enableBulk(hubIds: string[]) {
    return this.httpClient.post<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/enable`, hubIds
    );
  }

  public disable(hubId: string) {
    return this.httpClient.get<ResponseState<CfHub>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/${hubId}/disable`
    );
  }

  public disableBulk(hubIds: string[]) {
    return this.httpClient.post<ResponseState<CfHub[]>>(
      `${this._appConfig.generalConfigV1Url}manage/hubs/bulk/disable`, hubIds
    );
  }

}
