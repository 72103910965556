import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavItemWithIconAndUrl} from "@app-web-central/web/shared/data-access/models";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {ThemeService} from "@app-web-central/web/shared/services/theme";
import {AuthService} from "@auth0/auth0-angular";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AppStore} from "@app-web-central/web/shared/data-access/store";
import {AuthFacade} from "@app-web-central/web/auth/data-access";

@UntilDestroy()
@Component({
  selector: 'as-layout-no-leftbar',
  templateUrl: './layout-no-leftbar.component.html',
  styleUrls: ['./layout-no-leftbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutNoLeftbarComponent implements OnInit {
  public items!: NavItemWithIconAndUrl[];

  constructor(
    public appStore: AppStore,
    private authFacade: AuthFacade,
    public authService: AuthService,
    public themeService: ThemeService,
  ) { appStore.loadApp$() }

  ngOnInit(): void {
    this.themeService.loadThemeMode();
    this.authService.isAuthenticated$
      .pipe(untilDestroyed(this))
      .subscribe((authenticated: boolean) => {
        if (authenticated) {
          this.authFacade.getMe();
          this.initNavItems();
        } else {
          this.authService.loginWithRedirect();
        }
      });
  }

  public manualToggle(): void {
    this.themeService.expanded$.next(!this.themeService.expanded$.getValue());
  }

  private initNavItems(): void {
    this.items = [
      new NavItemWithIconAndUrl('sidebar.links.dashboard', 'outline/dashboard', RouteUtil.getDashboardRouteUrl(), 'project:display',false),
      // new NavItemWithIconAndUrl('sidebar.links.requests', 'outline/inbox', RouteUtil.getUsersRouteUrl(), 'project:display', false),
      new NavItemWithIconAndUrl('sidebar.links.work-orders', 'outline/task', RouteUtil.getWorkOrdersRouteUrl(), 'project:display', false),
      new NavItemWithIconAndUrl('sidebar.links.recurring', 'outline/recycle', RouteUtil.getRecurringWorkOrdersRouteUrl(), 'task:display',false),
      new NavItemWithIconAndUrl('sidebar.links.residences', 'outline/map-pin', RouteUtil.getResidencesRouteUrl(), 'address:display', false),
      // new NavItemWithIconAndUrl('sidebar.links.assets', 'outline/cube', RouteUtil.getUsersRouteUrl(), false),
      new NavItemWithIconAndUrl('sidebar.links.users', 'outline/user', RouteUtil.getUsersRouteUrl(), 'account:display', false),
      new NavItemWithIconAndUrl('sidebar.links.groups', 'outline/user-group', RouteUtil.getGroupsRouteUrl(), 'team:display', false),
      new NavItemWithIconAndUrl('sidebar.links.roles', 'outline/user-lock', RouteUtil.getRolesRouteUrl(), 'role:display', false),
      // new NavItemWithIconAndUrl('sidebar.links.vendors', 'outline/users', RouteUtil.getRolesRouteUrl(), false),
      new NavItemWithIconAndUrl('sidebar.links.categories', 'outline/bookmark', RouteUtil.getInterventionsRouteUrl(), 'intervention:display',false),
      // new NavItemWithIconAndUrl('sidebar.links.checklists', 'outline/checklist', RouteUtil.getChecklistRouteUrl(), false),
      new NavItemWithIconAndUrl('sidebar.links.files', 'outline/document', RouteUtil.getStorageRouteUrl(), 'storage:display', false),
    ];
  }

}
