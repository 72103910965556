import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {OcfCookieAgreement, OcfCookieService} from "@app-web-central/web/shared/services/cookie-service";

@Injectable({ providedIn: 'root' })
export class CookieAgreementService {
  hasAgree$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  constructor(
    private _ocfCookieService: OcfCookieService
  ) { }

  checkAgreement() {
    const cookie = this._ocfCookieService.getItem(OcfCookieAgreement);
    if (!cookie) {
      this.hasAgree$.next(false);
    } else {
      const cookieValue = JSON.parse(cookie);
      if (cookieValue) {
        this.hasAgree$.next(true);
      }
    }
  }

}
