import {Pipe, PipeTransform} from "@angular/core";
import {AuthFacade} from "@app-web-central/web/auth/data-access";

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  readonly user$ = this.authFacade.user$;
  private langCode!: string;
  constructor(
    private authFacade: AuthFacade
  ) {
    this.user$.subscribe(user => {
      this.langCode = user?.settings.preferredLanguage.code || 'en';
    });
  }

  /**
   * @method to transform a date into a 'date ago' indicator.
   * @param value the value to transform.
   * @param args, in case of args.
   */
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        if (this.langCode === 'fr') {
          return 'A l\'instant';
        } else {
          return 'Just now';
        }
      }

      let intervals!: any;
      if (this.langCode === 'fr') {
        intervals = {
          annee: 31536000,
          mois: 2592000,
          semaine: 604800,
          jour: 86400,
          heure: 3600,
          minute: 60,
          seconde: 1
        };
      } else {
        intervals = {
          year: 31536000,
          month: 2592000,
          week: 604800,
          day: 86400,
          hour: 3600,
          minute: 60,
          second: 1
        };
      }

      let counter;
      for (const i in intervals) {
        if (intervals.hasOwnProperty(i)) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              if (this.langCode === 'fr') {
                return 'Il y a ' + counter + ' ' + i; // singular (1 day ago)
              }
              return counter + ' ' + i + ' ago'; // singular (1 day ago)
            } else {
              if (this.langCode === 'fr') {
                if (i.toString() === 'annee') {
                  return 'Il y a ' + counter + ' anneés'; // plural (1 day ago)
                }
                if (i.toString() === 'mois') {
                  return 'Il y a ' + counter + ' ' + i; // plural (1 day ago)
                }
                return 'Il y a ' + counter + ' ' + i + 's'; // plural (1 day ago)
              }
              return counter + ' ' + i + 's ago'; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }

}
