import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular";
import {BrowserTracing} from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://8aa68622fd1445a1ae146e74da750d10@o4504118709977088.ingest.sentry.io/4504225691860992",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://app.quantum-cmms.eu/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
