import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";

interface ToolbarState {
  label: string;
  active: boolean;
  component: any | null;
  data?: Set<string>;
  searchTerm?: string;
}

interface ToolbarsState {
  toolbars: ToolbarState[];
}

const initialState: ToolbarState[] = [
  { label: 'analytics', active: false, component: null },
  { label: 'table', active: false, component: null },
  { label: 'calendar', active: false, component: null },
]

@Injectable({ providedIn: 'root' })
export class ToolbarStore extends ComponentStore<ToolbarsState> {
  active$ = this.select(({ toolbars }) => toolbars
    .find((t) => t.active)
  );

  getData$ = this.select(({ toolbars }) => toolbars
    .find((t) => t.active)?.data
  );

  getSearchTerm$ = this.select(({ toolbars }) => toolbars
    .find((t) => t.active)?.searchTerm
  );

  setActive$ = this.updater((state, toolbar: { label: string, component: any, data?: Set<string> }) => ({
    ...state,
    toolbars: this.activeToolbar(state.toolbars, toolbar.label, toolbar.component, toolbar.data)
  }));

  setData$ = this.updater((state, toolbar: { label: string, data?: Set<string> }) => ({
    ...state,
    toolbars: this.setActiveDataToolbar(state.toolbars, toolbar.label, toolbar.data)
  }));

  setSearchTerm$ = this.updater((state, toolbar: { label: string, searchTerm?: string }) => ({
    ...state,
    toolbars: this.setActiveSearchTermToolbar(state.toolbars, toolbar.label, toolbar.searchTerm)
  }));

  private activeToolbar(toolbars: ToolbarState[], label: string, component: any, data?: Set<string>) {
    toolbars.forEach((toolbar) => {
      toolbar.active = toolbar.label === label;
      toolbar.component = toolbar.label === label ? component : null;
      toolbar.data = toolbar.label === label ? data : undefined;
    });
    return toolbars;
  }

  private setActiveDataToolbar(toolbars: ToolbarState[], label: string, data?: Set<string>) {
    toolbars.forEach((toolbar) => {
      toolbar.active = toolbar.label === label;
      toolbar.data = toolbar.label === label ? data : undefined;
    });
    return toolbars;
  }

  private setActiveSearchTermToolbar(toolbars: ToolbarState[], label: string, searchTerm?: string) {
    toolbars.forEach((toolbar) => {
      toolbar.active = toolbar.label === label;
      toolbar.searchTerm = toolbar.label === label ? searchTerm : undefined;
    });
    return toolbars;
  }

  constructor() {
    super(<ToolbarsState>{ toolbars: initialState });
  }
}
