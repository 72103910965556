<div
  id="cookie-agreement"
  *ngIf="!hasAgree"
  class="fixed left-3 bottom-3 bg-black shadow sm:rounded-lg z-20 shadow-md">
  <div class="px-4 py-5 sm:p-6 flex flex-col">
    <div class="mt-2 max-w-xl text-sm text-white">
      <p>
        {{ 'cookie.content' | translate }} <a href="javascript:void(0)" (click)="redirectTo()" class="link">{{ 'cookie.link' | translate }}</a>.
      </p>
    </div>
    <div class="mt-3 text-sm flex justify-end">
      <a
        href="javascript:void(0)"
        (click)="redirectTo()"
        class="cookie-item">
        {{ 'buttons.more_details' | translate }}
      </a>
      <a
        href="javascript:void(0)"
        (click)="accept()" class="cookie-item">
        {{ 'buttons.ok' | translate }}
      </a>
    </div>
  </div>
</div>
