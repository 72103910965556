import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfCategory, ResponseState} from "@app-web-central/web/shared/data-access/models";


@Injectable({ providedIn: 'root' })
export class CategoryApi {
  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  get(categoryId: string) {
    return this._http.get<ResponseState<CfCategory>>(
      `${this._appConfig.interventionV1Url}manage/categories/${categoryId}`
    );
  }

  list() {
    return this._http.get<ResponseState<CfCategory[]>>(
      `${this._appConfig.interventionV1Url}manage/categories`
    );
  }

  create(category: CfCategory) {
    return this._http.post<ResponseState<CfCategory>>(
      `${this._appConfig.interventionV1Url}manage/categories`, category
    );
  }

  update(categoryId: string, category: CfCategory) {
    return this._http.put<ResponseState<CfCategory>>(
      `${this._appConfig.interventionV1Url}manage/categories/${categoryId}`, category
    );
  }

  delete(categoryId: string) {
    return this._http.delete<ResponseState<CfCategory>>(
      `${this._appConfig.interventionV1Url}manage/categories/${categoryId}`
    );
  }

}
