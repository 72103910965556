import {
  CfAddress,
  CfSuite,
  CfUser,
  CfRecipient,
  CfIntervention,
  CfAttachment,
  CfComment,
  Priority,
  PriorityColors,
  CfChannel,
  PriorityIconValues, PriorityRawIconValues
} from "@app-web-central/web/shared/data-access/models";


export enum TaskType {
  TASK = 'TASK',
  ISSUE = 'ISSUE',
  SUBTASK = 'SUBTASK'
}

export enum TaskStatus {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  FAILED = 'FAILED',
  IN_TRANSIT = 'IN_TRANSIT',
  COMPLETED = 'COMPLETED'
}

export class TaskState {
  value: TaskStatus;
  description: string;
  disable: boolean;
  constructor(value: TaskStatus, description: string, disable: boolean) {
    this.value = value;
    this.description = description;
    this.disable = disable;
  }
}

export const TaskStatusDisplay = {
  [TaskStatus.UNASSIGNED]: 'task_detail.status.unassigned',
  [TaskStatus.ASSIGNED]: 'task_detail.status.assigned',
  [TaskStatus.IN_TRANSIT]: 'task_detail.status.in_transit',
  [TaskStatus.FAILED]: 'task_detail.status.failed',
  [TaskStatus.COMPLETED]: 'task_detail.status.completed'
};

export const TaskStatusClassName = {
  [TaskStatus.UNASSIGNED]: 'default',
  [TaskStatus.ASSIGNED]: 'info',
  [TaskStatus.IN_TRANSIT]: 'warning',
  [TaskStatus.FAILED]: 'danger',
  [TaskStatus.COMPLETED]: 'success'
};

export const TaskStatusesValues = [
  new TaskState(TaskStatus.UNASSIGNED, 'task_detail.status.unassigned_desc', false),
  new TaskState(TaskStatus.ASSIGNED, 'task_detail.status.assigned_desc', false),
  new TaskState(TaskStatus.IN_TRANSIT, 'task_detail.status.in_transit_desc', false),
  new TaskState(TaskStatus.FAILED, 'task_detail.status.failed_desc', false),
  new TaskState(TaskStatus.COMPLETED, 'task_detail.status.completed_desc', false)
];

export enum TaskStepEnum {
  INITIALIZATION = 'INITIALIZATION',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSE = 'PAUSE',
  TERMINATE = 'TERMINATE'
}

export const TaskStepHexColors = {
  [TaskStepEnum.INITIALIZATION]: '#d9d9d9',
  [TaskStepEnum.IN_PROGRESS]: '#722ed2',
  [TaskStepEnum.PAUSE]: '#eb2f96',
  [TaskStepEnum.TERMINATE]: '#52c41a'
};

export const TaskStepTitleLabelsAndValues = {
  [TaskStepEnum.INITIALIZATION]: {label: 'task_detail.steps.initialization', description: 'task_detail.steps.initialization_desc', status: 'wait'},
  [TaskStepEnum.IN_PROGRESS]: {label: 'task_detail.steps.in_progress', description: 'task_detail.steps.in_progress_desc', status: 'progress'},
  [TaskStepEnum.PAUSE]: {label: 'task_detail.steps.pause', description: 'task_detail.steps.pause_desc', status: 'wait'},
  [TaskStepEnum.TERMINATE]: {label: 'task_detail.steps.terminate', description: 'task_detail.steps.terminate_desc', status: 'finish'}
};

export class TaskStepIcon {
  icon: string;
  value: string;
  color: string;
  constructor(taskStep: TaskStepEnum) {
    const nonTerminateSteps = [TaskStepEnum.INITIALIZATION, TaskStepEnum.IN_PROGRESS, TaskStepEnum.PAUSE];
    this.value = taskStep;
    this.icon = nonTerminateSteps.includes(taskStep) ? 'x-circle' : 'check-circle';
    this.color = TaskStepHexColors[taskStep];
  }
}

export interface CfTaskDate {
  deliveryDate: number;
  startDate: number;
  endDate: number;
  validationDate: number;
  failureDate: number;
  allDay: boolean;
}

export enum TaskRruleStatus {
  RRULE = 'rrule',
  NO_RRULE = 'no_rrule'
}

export const TaskRruleStatusDisplay = {
  [TaskRruleStatus.RRULE]: 'recurrent',
  [TaskRruleStatus.NO_RRULE]: 'not_recurrent'
}

export class TaskRruleStatusValueTitle {
  value: TaskRruleStatus;
  label: string;
  constructor(taskRruleStatus: TaskRruleStatus) {
    this.value = taskRruleStatus;
    this.label = TaskRruleStatusDisplay[taskRruleStatus];
  }
}

export enum WeekDay {
  MO = 'MO',
  TU = 'TU',
  WE = 'WE',
  TH = 'TH',
  FR = 'FR',
  SU = 'SU',
  SA = 'SA'
}

export const WeekDayDisplay = {
  [WeekDay.MO]: {label: 'monday', value: 0},
  [WeekDay.TU]: {label: 'tuesday', value: 1},
  [WeekDay.WE]: {label: 'wednesday', value: 2},
  [WeekDay.TH]: {label: 'thursday', value: 3},
  [WeekDay.FR]: {label: 'friday', value: 4},
  [WeekDay.SA]: {label: 'saturday', value: 5},
  [WeekDay.SU]: {label: 'sunday', value: 6}
}

export class RRuleByWeekDayName {
  value: number;
  checked: boolean;
  label: string;
  constructor(weekDay: WeekDay, checked: boolean) {
    this.value = WeekDayDisplay[weekDay].value;
    this.label = WeekDayDisplay[weekDay].label;
    this.checked = checked;
  }
}

export enum CfFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export const FrequencyDisplay = {
  [CfFrequency.DAILY]: 'daily',
  [CfFrequency.WEEKLY]: 'weekly',
  [CfFrequency.MONTHLY]: 'monthly',
  [CfFrequency.YEARLY]: 'yearly'
}

export class RRuleFrequencyValue {
  value: CfFrequency;
  label: string;
  constructor(frequency: CfFrequency) {
    this.value = frequency;
    this.label = FrequencyDisplay[frequency];
  }
}

export enum DayPosition {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
  FIFTH = 'FIFTH',
  LAST = 'LAST'
}

export const DayPositionDisplay = {
  [DayPosition.FIRST]: {label: 'first', value: 1},
  [DayPosition.SECOND]: {label: 'second', value: 2},
  [DayPosition.THIRD]: {label: 'third', value: 3},
  [DayPosition.FOURTH]: {label: 'fourth', value: 4},
  [DayPosition.FIFTH]: {label: 'fifth', value: 5},
  [DayPosition.LAST]: {label: 'last', value: -1}
}

export class RRuleBySetDayPositionValue {
  value: number;
  label: string;
  constructor(dayPosition: DayPosition) {
    this.value = DayPositionDisplay[dayPosition].value;
    this.label = DayPositionDisplay[dayPosition].label;
  }
}

export enum Month {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER'
}

export const MonthDisplay = {
  [Month.JANUARY]: {label: 'january', value: 1},
  [Month.FEBRUARY]: {label: 'february', value: 2},
  [Month.MARCH]: {label: 'march', value: 3},
  [Month.APRIL]: {label: 'april', value: 4},
  [Month.MAY]: {label: 'may', value: 5},
  [Month.JUNE]: {label: 'june', value: 6},
  [Month.JULY]: {label: 'july', value: 7},
  [Month.AUGUST]: {label: 'august', value: 8},
  [Month.SEPTEMBER]: {label: 'september', value: 9},
  [Month.OCTOBER]: {label: 'october', value: 10},
  [Month.NOVEMBER]: {label: 'november', value: 11},
  [Month.DECEMBER]: {label: 'december', value: 12}
}

export class MonthValue {
  value: number;
  label: string;
  constructor(month: Month) {
    this.value = MonthDisplay[month].value;
    this.label = MonthDisplay[month].label;
  }
}


export interface CfTaskStep {
  cumulativeTime: number;
  cumulativeDeadTime: number;
  startDate: number | null;
  endDate: number | null;
  stepStatus: TaskStepEnum;
  step: number;
  stepOrder: number;
}

export class TaskPriorityIcon {
  icon: string;
  rawIcon: string;
  value: string;
  color: string;
  constructor(priority: Priority) {
    this.rawIcon = PriorityRawIconValues[priority];
    this.icon = PriorityIconValues[priority];
    this.value = priority;
    this.color = PriorityColors[priority];
  }
}

export interface CfTaskMetadata {
  key: string;
  value: string;
}

export interface CfTaskAssignee {
  assigneeId: string;
  assigneeImageUrl: string | null;
  assigneeFullName: string;
  assigneeFirstName: string;
  assigneeLastName: string;
  assigneeEmail: string;
}

export interface CfRecurrence {
  id: string;
  interval: number;
  isInfinite: boolean;
  rule: string;
  timezoneId: string | null;
  until: number | null;
  frequency: string;
  dateUpd: number;
  dateStart: number;
  dateAdd: number;
  count: number | null;
  byWeekDay: number[];
  bySetPosition: number[];
  byMonth: string[];
  byMonthDay: number[];
}

export interface CfTask {
  id: string;
  identifier: string;
  name: string;
  description: string;
  recipient: CfRecipient | undefined | null;
  recipientId: string;
  address: CfAddress | undefined | null;
  addressId: string;
  suite: CfSuite | undefined | null;
  suiteId: string;
  projectId: string;
  status: TaskStatus;
  dateAdd: number;
  dateUpd: number;
  type: TaskType;
  reporter: CfUser | undefined | null;
  createdBy: CfUser | undefined | null;
  createdById: string;
  modifiedBy: CfUser | undefined | null;
  modifiedById: string;
  assignees: CfTaskAssignee[];
  assignee: CfUser | undefined | null;
  assigneeId: string;
  steps: CfTaskStep[];
  interventions: CfIntervention[];
  priority: Priority;
  recurrence: CfRecurrence;
  recurrenceId: string;
  enabled: boolean;
  isRecurring: boolean;
  metadata: CfTaskMetadata[];
  dates: CfTaskDate;
  tags: string[];
  attachments: CfAttachment[];
  attachmentIds: string[];
  comments: CfComment[];
  channelId: string;
  channel: CfChannel;
}
