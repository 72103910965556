import {Injectable} from "@angular/core";
import {InterventionsState} from "./interventions.reducer";
import {select, Store} from "@ngrx/store";
import {loadInterventions} from "./interventions.actions";
import {getInterventions, getInterventionsLoading} from "./interventions.selectors";
import {Observable} from "rxjs";
import {CfIntervention} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class InterventionsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getInterventionsLoading));
  public interventions$: Observable<CfIntervention[] | null>
    = this.store.pipe(select(getInterventions));

  constructor(
    private store: Store<InterventionsState>
  ) { }

  public loadInterventions(): void {
    this.store.dispatch(loadInterventions());
  }
}
