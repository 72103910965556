import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfDashboardTemplate, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class TemplateApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public list() {
    return this.httpClient.get<ResponseState<CfDashboardTemplate[]>>(
      `${this._appConfig.localDataUrl}/templates.json`
    );
  }

}
