import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {HttpClient} from "@angular/common/http";
import {CfSuite, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({providedIn: 'root'})
export class SuiteApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) {}

  public get(unitId: string) {
    return this.httpClient.get<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/${unitId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfSuite[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites`
    );
  }

  public create(suite: CfSuite) {
    return this.httpClient.post<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites`, suite
    );
  }

  public update(suiteId: string, suite: CfSuite) {
    return this.httpClient.put<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/${suiteId}`, suite
    );
  }

  public delete(suiteId: string) {
    return this.httpClient.delete<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/${suiteId}`
    );
  }

  public deleteBulk(suiteIds: string[]) {
    return this.httpClient.delete<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/bulk/${suiteIds}`
    );
  }

  public enable(suiteId: string) {
    return this.httpClient.get<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/${suiteId}/enable`
    );
  }

  public enableBulk(suiteIds: string[]) {
    return this.httpClient.post<ResponseState<CfSuite[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/bulk/enable`, suiteIds
    );
  }

  public disable(suiteId: string) {
    return this.httpClient.get<ResponseState<CfSuite>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/${suiteId}/disable`
    );
  }

  public disableBulk(suiteIds: string[]) {
    return this.httpClient.post<ResponseState<CfSuite[]>>(
      `${this._appConfig.generalConfigV1Url}manage/addresses/suites/bulk/disable`, suiteIds
    );
  }

}
