import {Component, Input} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'as-modal-action-information',
  templateUrl: './modal-action-information.component.html',
  styleUrls: ['./modal-action-information.component.scss'],
})
export class ModalActionInformationComponent {
  @Input() public title!: string;
  @Input() public content!: string;

  constructor(
    private nzModalRef: NzModalRef
  ) { }

  public closeModal(result?: boolean): void {
    this.nzModalRef.close(result);
  }

  public cancel(): void {
    this.closeModal();
  }

  public submit(): void {
    this.nzModalRef.close(true);
  }

}
