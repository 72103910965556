import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DeviceInfoService {
  public platform: string;
  public operatingSystem: string;
  public osVersion: string;

  constructor() {
    this.platform = window.navigator.platform;
    this.operatingSystem = this.getOperatingSystem(window.navigator.userAgent);
    this.osVersion = this.getOSVersion(window.navigator.userAgent);
  }

  public getOperatingSystem(userAgent: string): string {
    if (userAgent.indexOf("Win") !== -1) return "Windows";
    if (userAgent.indexOf("Mac") !== -1) return "MacOS";
    if (userAgent.indexOf("X11") !== -1) return "UNIX";
    if (userAgent.indexOf("Linux") !== -1) return "Linux";
    if (userAgent.indexOf("Android") !== -1) return "Android";
    if (userAgent.indexOf("like Mac") !== -1) return "iOS";
    return "Unknown";
  }

  public getOSVersion(userAgent: string): string {
    // Might not cover all cases or versions accurately
    const version = userAgent.match(/(Windows NT|Mac OS X|Android|CPU (iPhone )?OS) (\d+(_|\.)?\d+)/);
    return version ? version[3].replace('_', '.') : "Unknown";
  }
}
