<div class="dropdown">
  <div>
    <as-button
      class="group"
      nz-dropdown
      nzTrigger="click"
      [size]="size"
      [icon]="icon"
      [shape]="shape"
      [disabled]="disabled"
      [nzDisabled]="disabled"
      [iconOnly]="iconOnly"
      [nzDropdownMenu]="menu"
      [overrideClass]="overrideClass"
      (nzVisibleChange)="clicked = !clicked"
      [variant]="variant">
      <ng-content></ng-content>
      <span
        *ngIf="count"
        class="dropdown-count">
        {{ count }}
      </span>
      <span
        *ngIf="!iconOnly"
        class="dropdown-arrow -mr-1 ml-1">
        <svg-icon
          [size]="size"
          [class.clicked]="clicked"
          key="mini/chevron-down"
          [ngClass]="[arrowColor]">
        </svg-icon>
      </span>
    </as-button>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ng-container *ngIf="selectedItem; else classicTpl">
    <ul nz-menu class="dropdown-menu py-1" role="list">
      <ng-container *ngIf="items && items.length else noDataTpl">
        <ng-container *ngFor="let item of items">
          <a nz-menu-item
             nz-tooltip
             nzTooltipPlacement="right"
             *asHasPermission="[item.scope]"
             [nzTooltipTitle]="item.tooltipTpl"
             [class.active]="item.value === selectedItem.role"
             (click)="item.handler({ item, selectedItem })"
             class="dropdown-item">
            {{ item.label | translate }}
          </a>
        </ng-container>
        <ng-container *ngIf="suffixItems">
          <div class="divider"></div>
          <ng-container *ngFor="let suffix of suffixItems">
            <a nz-menu-item
               (click)="suffix.handler({ suffix, selectedItem })"
               class="dropdown-item">
              {{ suffix.label | translate }}
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
  <ng-template #classicTpl>
    <ul nz-menu class="dropdown-menu py-1">
      <ng-container *ngIf="items && items.length else noDataTpl">
        <ng-container *ngFor="let item of items">
          <a nz-menu-item
             class="dropdown-item"
             *asHasPermission="[item.scope]"
             (click)="item.handler(null)"
          >
            {{ item.label | translate }}
          </a>
        </ng-container>
      </ng-container>
      <ng-template #noDataTpl>
        <as-empty type="simple" title="empty.dropdowns.options"></as-empty>
      </ng-template>
    </ul>
  </ng-template>
  <ng-template #noDataTpl>
    <as-empty type="simple" title="empty.dropdowns.options"></as-empty>
  </ng-template>
</nz-dropdown-menu>
