<ng-container *ngIf="authService.isAuthenticated$ | async">
  <ng-container *ngIf="(appStore.isLoading$ | async) === false; else spinnerTpl">
    <div class="min-h-full">
      <as-nav-bar-aside-secondary
        [items]="items"
        [expanded$]="themeService.expanded$"
        (manualToggle)="manualToggle()">
      </as-nav-bar-aside-secondary>
      <div
        class="main-frame flex flex-col min-h-screen"
        [ngClass]="(themeService.expanded$ | async)
        ? 'md:pl-64'
        : 'md:pl-16'"
      >
        <as-navigation
          [logoArea]="false"
          [hasCreationButton]="true"
          [hasBreadcrumbs]="true"
          class="navigation">
        </as-navigation>
        <div class="flex flex-col flex-1 bg-white dark:bg-gray-800">
          <as-main-view></as-main-view>
        </div>
        <as-footer-desktop class="xl:order-last"></as-footer-desktop>
      </div>
    </div>
    <as-cookie></as-cookie>
  </ng-container>
</ng-container>

<ng-template #spinnerTpl>
  <as-loader></as-loader>
</ng-template>
