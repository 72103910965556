<div>
  <div class="flex items-center">
    <div class="modal-title">
      {{ 'modals.user_status.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeModal()">
    </as-button>
  </div>

  <form>
    <div class="modal-container">

      <div class="pb-6" *ngIf="statusSelected && statusSelected.status">
        <p class="text-slate-400 dark:text-gray-200 font-sans">{{ 'modals.user_status.current_status' | translate }}</p>
        <div class="flex items-center text-slate-900 dark:text-white">
          <span class="mr-2">{{ currentStatus[statusSelected.status]?.emoji }}</span>
          <span>{{ currentStatus[statusSelected.status]?.text | translate }}</span>
          <div class="flex-auto"></div>
          <as-button
            size="sm"
            variant="plain"
            icon="outline/x-mark"
            (click)="removeStatus()"
          >
          </as-button>
        </div>
      </div>
      <p class="text-slate-400 dark:text-gray-200 font-sans">{{ 'modals.user_status.available_statuses' | translate }}</p>
      <div class="py-1" role="none">
        <a
          *ngFor="let status of statuses"
          (click)="onSetUserStatus(status)"
          [ngClass]="status.active ? 'active' : ''"
          routerLinkActive="active"
          class="submenu-item allowed"
          role="button"
        >
          <span class="mr-2">{{ status.emoji }}</span>
          <span>{{ status.value | translate }}</span>
        </a>
      </div>
    </div>

    <div class="flex items-center justify-between mt-6">
      <as-button
        type="reset"
        variant="default"
        (click)="closeModal()"
      >
        {{ 'buttons.cancel' | translate }}
      </as-button>
      <as-button
        type="submit"
        variant="solid"
        color="info"
        (click)="submit()"
      >
        {{ 'buttons.save' | translate }}
      </as-button>
    </div>

  </form>
</div>
