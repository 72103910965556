import {Component, Input} from '@angular/core';
import {CfTask, Priority, PriorityValues, TaskPriorityIcon} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";
import {TaskUtil} from "@app-web-central/web/shared/utils";

@Component({
  selector: 'as-task-drawer-priority',
  templateUrl: './task-drawer-priority.component.html',
  styleUrls: ['./task-drawer-priority.component.scss'],
})
export class TaskDrawerPriorityComponent {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  public priorities = PriorityValues;

  public handlePriority(priority: Priority): TaskPriorityIcon {
    return TaskUtil.getTaskPriorityIcon(priority);
  }

  public handleRemovePriority(): void {
    this.control.setValue(null);
  }

  public handleChangePriority(newPriority: Priority): void {
    this.control.setValue(newPriority);
  }
}
