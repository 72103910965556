import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

export type IconSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

@Component({
  selector: 'as-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() control!: FormControl;
  @Input() containerClassName = '';
  @Input() icon!: string;
  @Input() iconSize!: IconSize;
  @Input() placeholder = '';
  @Input() enableClearButton!: boolean;

  get iconContainerWidth(): IconSize {
    return this.iconSize;
  }

  get isShowClearButton(): boolean {
    return this.enableClearButton && this.control?.value;
  }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl('');
  }

  clear() {
    this.control.patchValue('');
  }

}
