import {ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {extModules} from "./build-specifics";
import {CookieService} from "ngx-cookie-service";
import * as Sentry from "@sentry/angular";
import {en_US, fr_FR, NZ_I18N} from "ng-zorro-antd/i18n";
import {appQuantumRoutes} from "./app-quantum-routes";
import {LayoutNoLeftbarModule} from "@app-web-central/web/shell/ui/layout-no-leftbar";
import {WebSharedModule} from "./web-shared.module";
import {NzNotificationModule} from "ng-zorro-antd/notification";


/** Languages / i18n **/
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';

registerLocaleData(en);
registerLocaleData(fr);

@NgModule({
  imports: [
    ...extModules,
    CommonModule,
    WebSharedModule,
    NzNotificationModule,
    LayoutNoLeftbarModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appQuantumRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient]
      },
      isolate: true
    }),
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        dialogOptions: {lang: 'fr'}
      })
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          case 'fr':
            return fr_FR;
          default:
            return en_US;
        }
      },
      deps: [LOCALE_ID]
    }
  ]
})
export class AppQuantumModule {

}
