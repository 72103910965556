<nz-empty
  class="empty-container"
  nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
  [nzNotFoundContent]="contentTpl">
  <ng-template #contentTpl>
      <span class="pt-8 text-center font-sans tracking-tight text-gray-900 dark:text-white">
        {{ 'empty.data_empty' | translate }}
      </span>
  </ng-template>
</nz-empty>
