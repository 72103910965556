import {Component, Input} from '@angular/core';

@Component({
  selector: 'as-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() content!: string | number;
  @Input() maxCount = 99;
  @Input() innerClass!: string;
  @Input() badgeStyle!: string;

  get contentValue() {
    return typeof this.content === 'number'
      && this.content > this.maxCount
        ? `${this.maxCount}+`
        : this.content;
  }

  get isNumber() {
    return typeof this.content === 'number';
  }

  get toString() {
    return typeof this.content === 'string'
      ? this.content
      : this.content.toString(10);
  }

}
