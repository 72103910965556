import {Inject, Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {CookieExpires} from "@app-web-central/web/shared/data-access/models";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";

export const OcfCookieSession = 'ocf-session';
export const OcfCookieSessionId = 'ocf-session-id';
export const OcfCookieAccessToken = 'access_token';
export const OcfCookieAgreement = 'ocf-agreement';

export const Cookies = [
  { key: OcfCookieSession, secure: false, sameSite: 'Lax' },
  { key: OcfCookieSessionId, secure: false, sameSite: 'Lax' },
  { key: OcfCookieAccessToken, secure: true, sameSite: 'Strict' },
  { key: OcfCookieAgreement, secure: false, sameSite: 'Lax' },
]

@Injectable({ providedIn: 'root' })
export class OcfCookieService {
  constructor(
    private _cookieService: CookieService,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  /**
   * Get cookie data by key name.
   *
   * @param key the cookie key name { string }.
   */
  getItem(key: string) {
    try {
      return this._cookieService.get(key);
    } catch (e) {
      return null;
    }
  }

  /**
   * Set cookie data by key name.
   *
   * @param key the cookie key name { string }.
   * @param value the data to set { any }.
   */
  setItem(key: string, value: any): void {
    try {
      this._cookieService.set(key, value, CookieExpires);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Delete cookie data by key name.
   *
   * @param key the cookie key name { string }.
   * @param secure specify if the cookie is HttpOnly { boolean }.
   */
  deleteItem(key: string, secure: boolean) {
    try {
      if (secure) {
        this._cookieService.delete(key, "/", this._appConfig.domain, secure, 'Strict');
      } else {
        this._cookieService.delete(key, "/", this._appConfig.domain, false, 'Lax');
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Delete all cookies from current path & domain.
   *
   */
  deleteAll() {
    try {
      this._cookieService.deleteAll( "/", this._appConfig.domain, false, 'Lax');
      this._cookieService.deleteAll("/", this._appConfig.domain, true, 'Strict');
    } catch (e) {
      console.error(e);
    }
  }
}
