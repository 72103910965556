import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'as-top-notification',
  templateUrl: './top-notification.component.html',
  styleUrls: ['./top-notification.component.scss'],
})
export class TopNotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
