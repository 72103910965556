import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {loadTemplates, loadTemplatesError, loadTemplatesSuccess} from "./templates.actions";
import {TemplateApi} from "@app-web-central/web/shared/data-access/stouds-api";

@Injectable({ providedIn: 'root' })
export class TemplatesEffects {
  loadTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTemplates),
      switchMap(() => this._templateApi.list()
        .pipe(
          map(response => loadTemplatesSuccess({ templates: response.payload })),
          catchError((error) => [loadTemplatesError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _templateApi: TemplateApi
  ) { }
}
