import {Injectable} from "@angular/core";
import {CfDeviceInformation, CfUser, ResponseState} from "@app-web-central/web/shared/data-access/models";
import {UserApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {AuthService} from "@auth0/auth0-angular";
import {AuthStore} from "@app-web-central/web/auth/data-access";
import {Observable} from "rxjs";
import {I18nUtil} from "@app-web-central/web/shared/utils";
import {TranslateService} from "@ngx-translate/core";
import {NzI18nService} from "ng-zorro-antd/i18n";
import {DeviceInfoService} from "@app-web-central/web/shared/services/device-info";

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public user$: Observable<CfUser | null>
    = this.authStore.user$;

  constructor(
    private userApi: UserApi,
    private authStore: AuthStore,
    private authService: AuthService,
    private translate: TranslateService,
    private nzI18nService: NzI18nService,
    private deviceService: DeviceInfoService
  ) { }

  public getMe(): void {
    this.authService.user$
      .subscribe((user) => {
        if (user && user.email) {
          this.userApi.getByUsername(user.email)
            .subscribe((response: ResponseState<CfUser>) => {
              this.authStore.setUser$(response.payload);
              this.loginVerified(response.payload);
              this.translate.use(response.payload.settings.preferredLanguage.code);
              this.nzI18nService.setLocale(I18nUtil.getLocaleLanguage(
                response.payload.settings.preferredLanguage.code
              ));
            });
        }
      })
  }

  public updateUser(user: CfUser): void {
    this.authStore.setUser$(user);
  }

  public loginVerified(user: CfUser): void {
    const deviceInfo: CfDeviceInformation = ({
      action: "user.login_verified",
      osVersion: this.deviceService.osVersion,
      platform: this.deviceService.platform,
      operatingSystem: this.deviceService.operatingSystem
    });
    this.userApi.login(user.id, deviceInfo).subscribe();
  }

  public logout(user: CfUser): void {
    const deviceInfo: CfDeviceInformation = ({
      action: "user.logout",
      osVersion: this.deviceService.osVersion,
      platform: this.deviceService.platform,
      operatingSystem: this.deviceService.operatingSystem
    });
    this.userApi.logout(user.id, deviceInfo).subscribe();
    this.authService.logout();
  }

}
