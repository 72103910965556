import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StackedListComponent} from './stacked-list.component';
import {TranslateModule} from "@ngx-translate/core";
import {ButtonModule} from "@app-web-central/web/shared/ui/button";

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule
  ],
  declarations: [
    StackedListComponent
  ],
  exports: [
    StackedListComponent
  ],
})
export class StackedListModule {
}
