import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "@app-web-central/web/notification/data-access";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CfUser, FeedbackOption, FeedbackOptionDisplay} from '@app-web-central/web/shared/data-access/models';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AuthStore} from "@app-web-central/web/auth/data-access";

@UntilDestroy()
@Component({
  selector: 'as-modal-feedback',
  templateUrl: './modal-feedback.component.html',
  styleUrls: ['./modal-feedback.component.scss'],
})
export class ModalFeedbackComponent implements OnInit {
  public feedbackForm!: FormGroup;
  public isConfirmLoading = false;
  public feedbackOptions!: FeedbackOptionValueTitle[];
  private currentLang: string
    = this.translateService.currentLang;


  constructor(
    private modalRef: NzModalRef,
    private authStore: AuthStore,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private nzNotificationService: NzNotificationService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm();
    this.initFeedbackOpts();
  }

  private initForm(): void {
    this.feedbackForm = this.formBuilder.group({
      service: ['BILLING', [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      account: [null, [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
      acknowledge: [false, [Validators.required]]
    });
  }

  private updateForm(): void {
    this.authStore.user$
      .pipe(untilDestroyed(this))
      .subscribe((user: CfUser | null) => {
        if (user) {
          this.feedbackForm.patchValue({
            email: user.username,
            account: user.tenant
          });
        }
      });
  }

  private initFeedbackOpts(): void {
    this.feedbackOptions = [
      new FeedbackOptionValueTitle(FeedbackOption.BILLING, this.currentLang),
      new FeedbackOptionValueTitle(FeedbackOption.ACCOUNT, this.currentLang),
      new FeedbackOptionValueTitle(FeedbackOption.PLATFORM, this.currentLang),
      new FeedbackOptionValueTitle(FeedbackOption.MOBILE, this.currentLang),
      new FeedbackOptionValueTitle(FeedbackOption.STORAGE, this.currentLang)
    ];
  }

  public submit(): void {
    this.isConfirmLoading = true;

    if (this.feedbackForm.invalid) {
      return;
    }

    const formValue = this.feedbackForm.getRawValue();
    this.notificationService.feedback(formValue).pipe(untilDestroyed(this)).subscribe((response) => {
      this.nzNotificationService.success('Success', response.payload, {});
    }, error => {
      this.nzNotificationService.error('Error', error.error.error, {});
    }, () => {
      this.isConfirmLoading = false;
      this.modalRef.destroy();
    });
  }

  public closeModal(): void {
    this.modalRef.close();
  }

}

class FeedbackOptionValueTitle {
  value: FeedbackOption;
  label: string;
  constructor(feedbackOption: FeedbackOption, lang: string) {
    this.value = feedbackOption;
    this.label = FeedbackOptionDisplay[lang][feedbackOption];
  }
}
