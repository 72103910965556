import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {CfCountry, CfLanguage} from "@app-web-central/web/shared/data-access/models";
import {ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class ConfigApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public fetchCountries = () => this.httpClient.get<ResponseState<CfCountry[]>>(
    `${this._appConfig.generalConfigV1Url}manage/countries`
  );

  public fetchLanguages = () => this.httpClient.get<ResponseState<CfLanguage[]>>(
    `${this._appConfig.generalConfigV1Url}manage/languages`
  );

}
