import {Component, Input} from '@angular/core';

@Component({
  selector: 'as-empty-simple',
  templateUrl: './empty-simple.component.html',
  styleUrls: ['./empty-simple.component.scss'],
})
export class EmptySimpleComponent {
  @Input() title!: string;
}
