<div class="form-group mt-3"
     [ngClass]="[className, inputLayout]">
  <div class="pb-1">
    <label *ngIf="label" class="form-label mb-2">
      {{ label | translate }}
    </label>
    <p *ngIf="subLabel" class="text-sm max-w-xs">
      {{ subLabel | translate }}
    </p>
  </div>
  <div class="input-wrapper">
    <div *ngIf="suffixIcon" class="input-suffix-start">
      <svg-icon [key]="suffixIcon"></svg-icon>
    </div>
    <input
      *ngIf="!textarea"
      class="input"
      [readonly]="readonly"
      [ngClass]="[align, size, textSize]"
      [class.input-invalid]="control.invalid && submitted"
      [class.input-group]="inputGroup"
      [class.!pl-10]="!!suffixIcon"
      [type]="type"
      [formControl]="control"
      [autocomplete]="autocomplete ? 'on' : 'off'"
      [placeholder]="placeholder" />
    <textarea
      rows="3"
      class="input input-textarea"
      *ngIf="textarea"
      [readonly]="readonly"
      [ngClass]="[textSize]"
      [class.input-invalid]="control.invalid && submitted"
      [formControl]="control"
      [placeholder]="placeholder" >
    </textarea>
    <div *ngIf="control.invalid && submitted" class="input-error">
      <svg-icon
        key="solid/exclamation-circle"
        class="text-red-500 !flex !items-center">
      </svg-icon>
    </div>
    <ng-content></ng-content>
  </div>
</div>
