<div class="relative flex">
  <as-button
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="menu"
    [className]="'secondary shadow-sm'"
    [disabled]="!!task.dates?.validationDate"
    [nzDisabled]="!!task.dates?.validationDate"
  >
    <div class="flex items-center" *ngIf="control.value">
      <ng-container *ngIf="handlePriority(control.value) as priority">
        <svg-icon
          size="sm"
          [key]="priority.icon"
          [color]="priority.color"
        >
        </svg-icon>
      </ng-container>
      <span class="mx-2">
        {{ 'task_detail.priorities.' + control.value.toLowerCase() | translate }}
      </span>
      <svg-icon
        size="sm"
        class="ml-3"
        key="outline/x-mark"
        (click)="handleRemovePriority()"
      >
      </svg-icon>
    </div>
    <div class="flex items-center text-gray-400" *ngIf="!control.value">
      {{ 'task_detail.add_priority' | translate }}
    </div>
  </as-button>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="dropdown-menu">
      <li nz-menu-item
          class="dropdown-item"
          *ngFor="let priority of priorities"
          (click)="handleChangePriority(priority.value)">
        <div class="flex items-center">
          <svg-icon
            size="sm"
            [key]="priority.icon"
            [color]="priority.color"
            class="!flex !items-center">
          </svg-icon>
          <span class="ml-2 mr-1-5">
            {{ priority.text.toLowerCase() | translate }}
          </span>
        </div>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
