import {CfUser} from "./user";

export enum ProjectTemplate {
  KANBAN = 'KANBAN',
  CALENDAR = 'CALENDAR',
  EMPTY = 'EMPTY',
}

export enum ProjectMemberRoleEnum {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  READONLY = 'Readonly',
}

export const ProjectTemplateDisplayValues = {
  [ProjectTemplate.KANBAN]: 'projects.templates.kanban',
  [ProjectTemplate.CALENDAR]: 'projects.templates.calendar',
  [ProjectTemplate.EMPTY]: 'projects.templates.empty',
};

export class ProjectTemplateValue {
  label: string;
  value: ProjectTemplate;
  constructor(template: ProjectTemplate) {
    this.label = ProjectTemplateDisplayValues[template];
    this.value = template;
  }
}

export interface CfProjectMemberExtended extends CfUser {
  projectId: string;
  members: string;
  role: ProjectMemberRoleEnum;
  invitor: string;
  organization: string;
}

export interface CfProjectMember {
  projectId: string;
  members: string;
  role: ProjectMemberRoleEnum;
}

export interface CfProject {
  id: string;
  name: string;
  description: string;
  color: string;
  key: string;
  imageUrl: string;
  template: ProjectTemplate;
  dateAdd: number;
  dateUpd: number;
  pinned: boolean;
  archived: boolean;
  projectLead: CfUser | undefined;
  projectLeadId: string;
  defaultAssignee: CfUser | undefined;
  defaultAssigneeId: string;
  members: CfProjectMember[];
  pendingInvitations: CfProjectPendingInvitation[];
}

export interface CfProjectPendingInvitation {
  projectId: string;
  validationCode: string;
  email: string;
  dateAdd: number;
}

export interface CfUIProjectPendingInvitation {
  email: string;
  dateAdd: number;
}

export interface CfUIProjectName {
  value: string;
  key: string;
  description: string;
  color: string;
  pinned: boolean;
}

export interface CfUIProject {
  name: CfUIProjectName;
  members: CfUser[];
  dateUpd: number;
  data: CfProject;
}

export interface CfUIProjectUserAccess {
  fullName: string | undefined;
  email: string | undefined;
  role: string;
  imageUrl: string | undefined;
}
