export enum NotificationReferenceEnum {
  EVENT = "EVENT",
  PROJECT = "PROJECT",
  ACCOUNT = "ACCOUNT"
}

export const NotificationsDisplayIcons = {
  [NotificationReferenceEnum.EVENT]: 'mini/megaphone',
  [NotificationReferenceEnum.PROJECT]: 'mini/clipboard-document',
  [NotificationReferenceEnum.ACCOUNT]: 'mini/user-circle',
}
