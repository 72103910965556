import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {ApiConfig, APP_CONFIG} from "@app-web-central/web/shared/api-config";
import {CfRole, CfScope, ResponseState} from "@app-web-central/web/shared/data-access/models";

@Injectable({ providedIn: 'root' })
export class RoleApi {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private _appConfig: ApiConfig,
  ) { }

  public get(roleId: string) {
    return this.httpClient.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`
    );
  }

  public list() {
    return this.httpClient.get<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles`
    );
  }

  public scopes() {
    return this.httpClient.get<ResponseState<CfScope[]>>(
      `${this._appConfig.usersV1Url}manage/roles/scopes`
    );
  }

  public create(role: CfRole) {
    return this.httpClient.post<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles`, role
    );
  }

  public update(roleId: string, role: CfRole) {
    return this.httpClient.put<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`, role
    );
  }

  public delete(roleId: string) {
    return this.httpClient.delete<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}`
    );
  }

  public deleteBulk(roleIds: string[]) {
    return this.httpClient.delete<ResponseState<string>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/${roleIds}`
    );
  }

  public enable(roleId: string) {
    return this.httpClient.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}/enable`
    );
  }

  public enableBulk(roleIds: string[]) {
    return this.httpClient.post<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/enable`, roleIds
    );
  }

  public disable(roleId: string) {
    return this.httpClient.get<ResponseState<CfRole>>(
      `${this._appConfig.usersV1Url}manage/roles/${roleId}/disable`
    );
  }

  public disableBulk(roleIds: string[]) {
    return this.httpClient.post<ResponseState<CfRole[]>>(
      `${this._appConfig.usersV1Url}manage/roles/bulk/disable`, roleIds
    );
  }
}
