import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {loadHubs, loadHubsError, loadHubsSuccess} from "./hubs.actions";
import {HubApi} from "../../../../../shared/data-access/stouds-api/src/lib/hub-api";

@Injectable({ providedIn: 'root' })
export class HubsEffects {
  loadHubs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHubs),
      switchMap(() => this._hubApi.list()
        .pipe(
          map(response => loadHubsSuccess({ hubs: response.payload })),
          catchError((error) => [loadHubsError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _hubApi: HubApi
  ) { }
}
