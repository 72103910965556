import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

export type InputMode = 'vertical' | 'horizontal';
export type InputSize = 'input-field' | 'input-field-sm'
export type TextInputSize = 'input-sm' | 'input-md' | 'input-lg'

@Component({
  selector: 'as-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent {
  @Input() control!: FormControl;
  @Input() submitted!: boolean;
  @Input() type = 'text';
  @Input() size: InputSize = 'input-field';
  @Input() mode: InputMode = 'vertical';
  @Input() label!: string;
  @Input() subLabel!: string;
  @Input() className: string = '';
  @Input() placeholder!: string;
  @Input() autocomplete = false;
  @Input() textarea = false;
  @Input() textAlign!: string;
  @Input() suffixIcon!: string;
  @Input() inputGroup = false;
  @Input() readonly = false;
  @Input() textSize: TextInputSize = 'input-md';

  get align() {
    return this.textAlign
      ? this.textAlign
      : '';
  }

  get inputLayout() {
    return this.mode === 'vertical'
      ? 'vertical'
      : 'horizontal';
  }
}
