<div>
  <div class="drawer-header">
    <div class="drawer-title">
      {{ 'drawers.help.title' | translate }}
    </div>
    <div class="flex-auto"></div>
    <as-button
      icon="outline/x-mark"
      size="sm"
      type="reset"
      variant="plain"
      [iconOnly]="true"
      (click)="closeDrawer()">
    </as-button>
  </div>

  <form>
    <div class="drawer-content">

      <div *ngFor="let item of items">
        <a *ngIf="item.handler"
           (click)="item.handler(item.url)"
           routerLinkActive="active"
           class="nav-link allowed"
        >
          <div class="flex items-center">
            <svg-icon class="mr-4" key="{{ item.icon }}" [size]="'md'"></svg-icon>
            <div class="pt-px text-15">{{ item.label | translate }}</div>
          </div>
          <svg-icon *ngIf="item.external" key="external-link" [size]="'sm'"></svg-icon>
        </a>
      </div>

    </div>
  </form>
</div>
