import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {environment} from "../environments/environment";
import {I18nUtil} from "@app-web-central/web/shared/utils";
import {NzI18nService} from "ng-zorro-antd/i18n";
import {TranslateService} from "@ngx-translate/core";
import {CookieAgreementService} from "@app-web-central/web/shared/services/cookie-agreement";

@Component({
  selector: 'web-central-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private nzI18n: NzI18nService,
    private translateService: TranslateService,
    private googleAnalytics: GoogleAnalyticsService,
    private agreementService: CookieAgreementService
  ) { }

  ngOnInit(): void {
    this.initLanguage();
    this.agreementService.checkAgreement();
    if (environment.production) {
      this.initGoogleAnalytics();
    }
  }

  private initGoogleAnalytics(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((navigationEndEvent) => {
        this.googleAnalytics.sendPageView((navigationEndEvent as NavigationEnd).urlAfterRedirects);
      });
  }

  private initLanguage(): void {
    const lang: string = this.translateService.getBrowserLang() as string;
    this.translateService.use(lang);
    this.nzI18n.setLocale(I18nUtil.getLocaleLanguage(lang));
    // Set html lang attribute for content in scss.
    document.documentElement.setAttribute('lang', this.translateService.currentLang);
  }

}
