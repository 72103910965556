import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs";
import {UserApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {loadUsersConfig, loadUsersConfigError, loadUsersConfigSuccess} from "./users-config.actions";

@Injectable({ providedIn: 'root' })
export class UsersConfigEffects {
  loadUsersConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUsersConfig),
      switchMap(() => this.userApi.listUsersConfig()
        .pipe(
          map(response => loadUsersConfigSuccess({ usersConfigurations: response.payload })),
          catchError((error) => [loadUsersConfigError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userApi: UserApi
  ) { }
}
