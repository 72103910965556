import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CfAttachment, CfTask, CfUser} from "@app-web-central/web/shared/data-access/models";
import {FormControl} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {Browser} from "@capacitor/browser";
import {FileUtil} from "@app-web-central/web/shared/utils";
import {FileApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {shareReplay} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {saveAs} from "file-saver";
import {HttpResponse} from "@angular/common/http";
import {SwiperOptions} from "swiper";

@UntilDestroy()
@Component({
  selector: 'as-task-drawer-documents',
  templateUrl: './task-drawer-documents.component.html',
  styleUrls: ['./task-drawer-documents.component.scss'],
})
export class TaskDrawerDocumentsComponent implements OnInit {
  @Input() task!: CfTask;
  @Input() control!: FormControl;
  @Input() folder!: string;
  @Input() session!: CfUser;
  @Input() set filesDropped(files: File[]) {
    this._initUpload(files);
  }
  files!: CfAttachment[];
  config: SwiperOptions = {
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: false,
    scrollbar: { draggable: true },
  };

  constructor(
    private _fileApi: FileApi,
    public sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.task
      && this.task.attachments
      && this.task.attachments.length
    ) {
      this.files = this.task.attachments;
    } else {
      this.files = [];
    }
  }

  onDeleteFile(file: CfAttachment, index: number) {
    if (!file) {
      return;
    }
    this.files.splice(index,1);
    this._fileApi.delete(file.filename)
      .pipe(shareReplay(), untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          this.updateControl();
        }
      });
  }

  onDownloadFile(file: CfAttachment) {
    if (!file) {
      return;
    }
    const fileData = FileUtil.getFileData(file);
    this._fileApi.download(fileData).pipe(shareReplay(), untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          const bytes = atob(response.payload);
          const blob = FileUtil.getBytesToBlob(bytes, fileData.extension);
          saveAs(blob, fileData.title);
        }
      });
  }

  onViewFile(file: CfAttachment) {
    Browser.open({ url: file.fileUrl });
  }

  /**
   * Init the upload function depending on the files dropped on change lifecycle.
   *
   * @param filesDropped the list of files dropped as { File[] }.
   */
  private _initUpload(filesDropped: File[]): void {
    if (filesDropped && filesDropped.length > 0) {
      filesDropped.forEach((file, index) => {
        this._upload(index, file);
      });
    }
  }

  /**
   * @private method to upload the file to the server side.
   * This method should observe the httpEvent to get the progress of the loading data.
   *
   * @param idx the index of the current file we are loading.
   * @param file the file we are loading.
   */
  private _upload(idx: number, file: File): void {
    const params = FileUtil.getFileParams(this.session.id, this.folder, this.task.id, this.session.tenant);
    this._fileApi.upload(FileUtil.setFormData(file, params))
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response instanceof HttpResponse) {
          const fileReceived = {...response?.body?.payload} as CfAttachment;
          this.files.push(fileReceived);
          this.updateControl();
        }
      }, (error) => {
        console.error(error);
      },() => {
        // this._notification.create(
        //   'success',
        //   this._translate.instant('attachments.notifications.success_title'),
        //   this._translate.instant('attachments.notifications.success_content')
        // );
      });
  }

  /**
   * Update the form control values on uploaded files.
   *
   */
  private updateControl(): void {
    this.control.setValue(this.files);
    this.cdRef.detectChanges();
  }

}
