<nz-select
  class="w-full"
  nzPlaceHolder="{{ 'add_task.task_address_placeholder' | translate }}"
  [formControl]="control"
  nzShowSearch
>
  <nz-option
    nzCustomContent
    *ngFor="let address of addresses"
    [nzLabel]="address.address1"
    [nzValue]="address">
    {{ address.address1 }}
  </nz-option>
</nz-select>
