import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {FileApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {
  loadFolders,
  loadFoldersError,
  loadFoldersSuccess
} from "./folders.actions";

@Injectable({ providedIn: 'root' })
export class FoldersEffects {
  loadFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFolders),
      switchMap(() => this._fileApi.list()
        .pipe(
          map((response) => loadFoldersSuccess({ folders: response.payload })),
          catchError((error) => of(loadFoldersError(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _fileApi: FileApi
  ) { }
}
