import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {RecipientApi} from "@app-web-central/web/shared/data-access/stouds-api";
import {catchError, map, switchMap} from "rxjs";
import {loadRecipients, loadRecipientsError, loadRecipientsSuccess} from "./recipients.actions";

@Injectable({ providedIn: 'root' })
export class RecipientsEffects {
  loadRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecipients),
      switchMap(() => this._recipientApi.list()
        .pipe(
          map(response => loadRecipientsSuccess({ recipients: response.payload })),
          catchError((error) => [loadRecipientsError(error)])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _recipientApi: RecipientApi
  ) { }
}
