import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {FoldersState} from "./folders.reducer";
import {loadFolders} from "./folders.actions";
import {Observable} from "rxjs";
import {getFoldersLoading} from "./folders.selectors";

@Injectable({ providedIn: 'root' })
export class FoldersFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getFoldersLoading));

  constructor(
    private store: Store<FoldersState>,
  ) { }

  public loadFolders(): void {
    this.store.dispatch(loadFolders());
  }

}
